import { BusinessUnitNameTypes, BusinessUnitResponse, DistributorProps } from 'pages/pos/Distributors/interfaces';

export const buTipMessage: GenericObject<BusinessUnitNameTypes> = {
	'Neo Química':
		'Para comprar produtos de Neo Química, os distribuidores selecionados servirão apenas de apoio na comparação de preços. O pedido será finalizado no distribuidor de sua escolha no momento da compra.',
	Marcas:
		'Caso algum produto esteja indisponível no distribuidor selecionado no momento da compra, o enviaremos automaticamente para o distribuidor seguinte, respeitando a ordem de prioridade aqui definida.',
};

export const handleAddDistributor = (
	prevState: BusinessUnitResponse[],
	available: BusinessUnitResponse,
	BUId: string,
	distributorId: string,
	indexPosition: number | undefined,
	temp: BusinessUnitResponse['distributors'],
) => {
	const BUs = prevState.reduce(
		(acc, BU) => ({ ...acc, [BU?.businessUnitId]: BU }),
		{} as GenericObject<string, BusinessUnitResponse>,
	);

	const newDistributor = available.distributors?.find((distributor) => distributor.id === distributorId)!;
	const currentDistributors = BUs[BUId]?.distributors.length
		? BUs[BUId].distributors
		: ([null] as any as DistributorProps[]);

	const distributorsWithNewDistributor = currentDistributors.reduce<DistributorProps[]>(
		(acc, curr, index, { length }) => {
			if (curr === null) return [{ ...newDistributor, order: 1 }];

			const isOnLastPosition = index + 1 === length;

			if (indexPosition === undefined) {
				if (isOnLastPosition) return [...acc, curr, { ...newDistributor, order: length + 1 }];
				return [...acc, curr];
			}

			const newDistributorPosition = indexPosition + 1;
			const currentPosition = index + 1;

			const isOnNewDistributorPosition = currentPosition === newDistributorPosition;
			const hasPassedNewDistributor = currentPosition > newDistributorPosition;
			const addedAtLastPosition = length === indexPosition;

			if (isOnNewDistributorPosition) {
				return [
					...acc,
					{ ...newDistributor, order: newDistributorPosition },
					{ ...curr, order: newDistributorPosition + 1 },
				];
			}

			if (hasPassedNewDistributor) return [...acc, { ...curr, order: currentPosition + 1 }];

			if (addedAtLastPosition && isOnLastPosition) {
				return [...acc, curr, { ...newDistributor, order: newDistributorPosition }];
			}

			return [...acc, curr];
		},
		[],
	);

	temp.push(...distributorsWithNewDistributor);

	const addedCard = {
		...BUs,
		[BUId]: {
			...BUs[BUId],
			distributors: distributorsWithNewDistributor,
		},
	};

	return Object.values(addedCard);
};

export const handleRemoveDistributor = (
	prevState: BusinessUnitResponse[],
	BUId: string,
	distributorId: string,
): BusinessUnitResponse[] => {
	return prevState.map((BU) => {
		if (BU.businessUnitId !== BUId) return BU;

		return {
			...BU,
			distributors: [...BU.distributors.filter((distributor) => distributor.id !== distributorId)],
		};
	});
};

export const handleRePositionDistributor = (
	prevState: BusinessUnitResponse[],
	BUId: string,
): BusinessUnitResponse[] => {
	return prevState.map((BU) => {
		if (BU.businessUnitId !== BUId) return BU;

		return {
			...BU,
			distributors: [...BU.distributors.map((distributor, index) => ({ ...distributor, order: index + 1 }))],
		};
	});
};

export const handleReorderDistributor = (
	prevState: BusinessUnitResponse[],
	BUId: string,
	from: number,
	to: number,
	temp: BusinessUnitResponse['distributors'],
) => {
	const BUs = prevState.reduce(
		(acc, BU) => ({ ...acc, [BU.businessUnitId]: BU }),
		{} as GenericObject<string, BusinessUnitResponse>,
	);

	const currentDistributors = BUs[BUId].distributors.length
		? BUs[BUId].distributors
		: ([null] as any as DistributorProps[]);

	const reordered = currentDistributors.changePosition<DistributorProps>(from, to);

	const reOrderedDistributors = reordered.map((distributor, idx) => ({
		...distributor,
		order: idx + 1,
	}));

	temp.push(...reOrderedDistributors);

	const addedCard = {
		...BUs,
		[BUId]: {
			...BUs[BUId],
			distributors: reOrderedDistributors,
		},
	};

	return Object.values(addedCard);
};
