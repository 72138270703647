import * as Progress from '@radix-ui/react-progress';
import { Flex, getThemeShadow } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import styled, { css } from 'styled-components';
import { currencyRound } from 'shared/currency';
import { IndicatorStylesProps, TargetProps } from './interfaces';

export const Container = styled(Flex)<{ contained: boolean }>`
	width: 100%;

	overflow: hidden;

	${({ contained }) =>
		contained &&
		css`
			border-radius: 8px;
			border: 1px solid var(--border-active);

			box-shadow: ${getThemeShadow('1')};
		`}

	a:last-child {
		display: none;
	}
`;

export const ProgressContainer = styled(Flex)<{ contained: boolean }>`
	max-width: 100%;
	overflow: auto hidden;

	padding-bottom: 24px;
	padding-right: 24px;

	@media screen {
		@media (min-width: 576px) {
			overflow: hidden;
		}
	}

	${({ contained }) =>
		contained &&
		css`
			margin-bottom: 16px;
			padding-left: 16px;
		`}
`;

export const Root = styled(Progress.Root)`
	overflow: hidden;

	background-color: var(--border-primary);
	margin-top: 4px;

	height: 8px;
	min-width: 50px;

	&:first-child {
		border-radius: 9999px;
	}

	@media screen {
		@media (min-width: 576px) {
			width: 100%;
		}
	}
`;

export const Indicator = styled(Progress.Indicator)<IndicatorStylesProps>`
	background-color: var(--surface-accent-dark);
	border-radius: 0 9999px 9999px 0;

	width: ${({ percentage }) => percentage || 0}%;
	max-width: 100%;
	height: 100%;

	transition: width 300ms ease-in-out;

	${({ percentage }) =>
		percentage > 100 &&
		css`
			background-color: var(--semantic-success-base);

			transition: none;
		`}
`;

export const TargetContainer = styled.div<TargetProps>`
	position: relative;

	background-color: var(--border-primary);
	border-radius: 50%;
	margin: 0 -4px;

	height: 16px;
	min-width: 16px;

	transition: background-color 300ms ease-in-out;

	&::before {
		position: absolute;
		top: 29px;
		left: 50%;

		content: '${({ discount }) => currencyRound(discount).toLocaleString('pt-br')}%';
		transform: translate(-50%, -50%);
		font-weight: 600;
		font-size: ${theme.fontSizes[14]};
	}

	${({ completed }) =>
		completed &&
		css`
			background-color: var(--semantic-success-base);
		`}
`;
