import { Button, Flex, Typography } from 'front-commons/ds';
import features from 'config/featureFlags';
import useFeatures from 'stores/features';
import { FeaturesReducer } from 'stores/features/interfaces';

export default function FeatureContent() {
	const { featuresStore, handleSetFeatures } = useFeatures();

	const handleABTestClick = (value: string) => {
		handleSetFeatures('AB_TEST', value);
	};

	return (
		<Flex direction="column" gap="8px" padding="0 8px">
			{Object.entries(featuresStore).map(([key]) => {
				if (key === 'AB_TEST') return null;
				const prop = key as keyof FeaturesReducer;
				const isAltered = featuresStore[prop] !== features[prop];

				return (
					<Flex key={key} alignItems="center" gap="8px">
						<Button minWidth="110px" onClick={() => handleSetFeatures(prop, !featuresStore[prop])}>
							{featuresStore[prop] ? 'Ativado' : 'Desativado'}
						</Button>
						<Flex
							as={Typography}
							variant={isAltered ? 'Paragraph/Semibold' : 'Paragraph/Regular'}
							style={{ color: isAltered ? 'orange' : '' }}
						>
							{key}
						</Flex>
					</Flex>
				);
			})}
			<Flex key="AB_TEST" direction="column" gap="8px">
				<Typography>AB_TEST</Typography>
				<Flex key="AB_TEST" alignItems="center" gap="8px">
					{['A', 'B', 'C'].map((value) => (
						<Button
							key={value}
							minWidth="30px"
							onClick={() => handleABTestClick(value)}
							style={{ background: featuresStore.AB_TEST === value ? 'orange' : undefined }}
						>
							{value}
						</Button>
					))}
				</Flex>
			</Flex>
		</Flex>
	);
}
