import { Flex, getThemeAnimation, getThemeMedia } from "front-commons/ds";
import styled from "styled-components";

export const Container = styled(Flex)`
	transition: background 300ms ease;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			&:hover {
				background-color: var(--surface-accent-dark);
			}
		}
	}

	${getThemeAnimation().chevron.rotate('i[data-testid*="expand"]', 'data-state', { initial: 'closed', final: 'open' }, '-180').Parent};

	* {
		color: var(--text-invert);
		user-select: none;
	}
`;