import { FindBasketProductsResponse } from 'services/basket/interfaces';
import { DistributorsProps, ProductParams } from 'services/products/interfaces';

const tryInitDataLayer = () => {
	// @ts-ignore
	if (!window.dataLayer) {
		// @ts-ignore
		window.dataLayer = window.dataLayer || [];
	}
};
// CORRECT
export const pageViewGTM = (event: Record<string, string>) => {
	// @ts-ignore
	window.dataLayer = window.dataLayer || [];
	// @ts-ignore
	window.dataLayer.push(event);
};

// CORRECT
export const viewItemListGTM = (products: any) => {
	// @ts-ignore
	window.dataLayer = window.dataLayer || [];

	const items = Array.isArray(products)
		? products.map(
			({
				ean13,
				productType,
				description,
				brandName,
				categoryName,
				distributors,
				businessUnitName,
				hyperaCode,
			}: any) => ({
				item_id: productType === 'SIMPLE' ? ean13 : hyperaCode,
				item_name: description,
				item_brand: brandName,
				item_variant: businessUnitName,
				item_category: categoryName,
				price: distributors.find((distributor: any) => distributor.status !== 'UNAVAILABLE')?.finalPrice || 0,
				affiliation:
					distributors.find((distributor: any) => distributor.status !== 'UNAVAILABLE')?.distributorName || '',
				quantity: 1,
			}),
		)
		: [];

	const itemsList = {
		items,
	};

	// @ts-ignore
	window.dataLayer.push({ ecommerce: null });
	// @ts-ignore
	window.dataLayer.push({ event: 'view_item_list', ecommerce: itemsList });
	return itemsList;
};

export const formatProducts = (products: any, additionalItems?: boolean) => {
	return products.flat().reduce((acc: any, product: any) => {
		if (product.productType === 'COMBO') {
			product.productsInCombo.forEach((p: any) => {
				const GAChildProduct: Record<string, unknown> = {
					item_id: p.ean13,
					item_name: p.description,
					item_variant: product.businessUnitName || '',
					price: p.unitPricePromotion,
					quantity: p.quantity,
					discount: p.unitDiscount,
					affiliation: product.affiliation,
					promotion_id: product.hyperaCode || '',
					promotion_name: product.description,
					...(additionalItems ? { minimum_value: product.minimum_value } : {}),
				};

				if (p.suggestionGroupName) GAChildProduct.item_list_name = p.suggestionGroupName

				acc.push(GAChildProduct);
			});

			return acc;
		}

		const GAProduct: Record<string, unknown> = {
			item_id: product.ean13,
			item_name: product.description,
			item_variant: product.businessUnitName,
			price: product.unitPricePromotion,
			quantity: product.quantity,
			discount: product.unitDiscount,
			affiliation: product.affiliation,
			...(additionalItems ? { minimum_value: product.minimum_value } : {}),
		}

		if (product.suggestionGroupName) GAProduct.item_list_name = product.suggestionGroupName

		acc.push(GAProduct);

		return acc;
	}, []);
};

// CORRECT
export const updateCartGTM = (type: string, value: number, products: any, format?: boolean) => {
	// @ts-ignore
	window.dataLayer = window.dataLayer || [];

	let items = [];

	if (format) {
		items = Array.isArray(products) ? formatProducts(products, false) : [];
	} else {
		items = Array.isArray(products)
			? products.map(
				({
					hyperaCode,
					ean13,
					description,
					brandName,
					price,
					categoryName,
					quantity,
					discount,
					affiliation,
					combo,
					productType,
					businessUnitName,
					promotion_name,
					promotion_id,
					suggestionGroupName
				}: any) => ({
					item_id: combo || productType === 'COMBO' ? hyperaCode : ean13,
					item_name: description,
					item_brand: brandName || '',
					item_variant: businessUnitName || '',
					item_category: categoryName || '',
					price,
					quantity,
					discount,
					affiliation,
					promotion_name: promotion_name || '',
					promotion_id: promotion_id || hyperaCode || '',
					...(suggestionGroupName ? { item_list_name: suggestionGroupName } : {}),
				}),
			)
			: [];
	}

	const eventData = {
		items,
	};

	// @ts-ignore
	window.dataLayer.push({ ecommerce: null });
	// @ts-ignore
	window.dataLayer.push({ event: type, value: Number(value.toFixed(2)), currency: 'BRL', ecommerce: eventData });
	return eventData;
};

// FIXED
export const addPaymentInfoGTM = (value: number, products: any, payment_type: any) => {
	// @ts-ignore
	window.dataLayer = window.dataLayer || [];

	const formattedProducts = formatProducts(products, false);

	const items = Array.isArray(products) ? formattedProducts : [];

	const eventData = {
		items,
	};

	// @ts-ignore
	window.dataLayer.push({ ecommerce: null });
	// @ts-ignore
	window.dataLayer.push({
		event: 'add_payment_info',
		currency: 'BRL',
		value: value.toFixed(2),
		payment_type,
		ecommerce: eventData,
	});
	return eventData;
};

export const viewCartGTM = (value: number, products: any) => {
	// @ts-ignore
	window.dataLayer = window.dataLayer || [];

	const formattedProducts = formatProducts(products, false);

	const items = Array.isArray(products) ? formattedProducts : [];

	const eventData = {
		items,
	};

	// @ts-ignore
	window.dataLayer.push({ ecommerce: null });
	// @ts-ignore
	window.dataLayer.push({ event: 'view_cart', currency: 'BRL', value: value.toFixed(2), ecommerce: eventData });
	return eventData;
};

export const beginCheckoutGTM = (value: number, products: any) => {
	// @ts-ignore
	window.dataLayer = window.dataLayer || [];

	const formattedProducts = formatProducts(products, false);

	const items = Array.isArray(products) ? formattedProducts : [];

	const eventData = {
		items,
	};

	// @ts-ignore
	window.dataLayer.push({ ecommerce: null });
	// @ts-ignore
	window.dataLayer.push({ event: 'begin_checkout', currency: 'BRL', value: value.toFixed(2), ecommerce: eventData });
	return eventData;
};

export const purchaseGTM = (
	value: number,
	totalTaxSubstitution: number,
	products: any,
	id: any,
	distributors: any,
	dataPharmacy: any,
) => {
	// @ts-ignore
	window.dataLayer = window.dataLayer || [];

	const formattedProducts = formatProducts(products, true);

	const items = Array.isArray(products) ? formattedProducts : [];

	const eventData = {
		items,
	};

	// @ts-ignore
	window.dataLayer.push({ ecommerce: null });
	// @ts-ignore
	window.dataLayer.push({
		event: 'purchase',
		currency: 'BRL',
		tax: totalTaxSubstitution,
		value: value.toFixed(2),
		ecommerce: eventData,
		store_cnpj: dataPharmacy.cnpj,
		store_name: dataPharmacy.name,
		transaction_id: id,
		providers: distributors,
	});
	return eventData;
};

export const loginGTM = (type: string) => {
	// @ts-ignore
	window.dataLayer = window.dataLayer || [];

	// @ts-ignore
	window.dataLayer.push({ event: 'login', method: type });
};

export const searchEventGTM = (search_term: string) => {
	tryInitDataLayer();
	// @ts-ignore
	window.dataLayer.push({ event: 'search', search_term: search_term.toLowerCase() });
};

export const sendFaqMessageGTM = (reason: string) => {
	tryInitDataLayer();
	// @ts-ignore
	window.dataLayer.push({ event: 'send_faq_message', reason });
};

export const signUpGTM = () => {
	tryInitDataLayer();
	// @ts-ignore
	window.dataLayer.push({ event: 'sign_up', method: 'generic' });
};

export const selectContentGTM = (content_type: string, content_id: string) => {
	tryInitDataLayer();
	// @ts-ignore
	window.dataLayer.push({ event: 'select_content', content_type, content_id });
};

export const updateLoopingProviderGTM = (
	type: 'remove_looping_provider' | 'add_looping_provider' | 'massive_looping_provider',
	{
		distributorName,
		minimumValue,
		businessUnitName,
		index,
	}: Pick<DistributorsProps, 'distributorName'> & {
		index?: number;
		minimumValue?: number;
		businessUnitName?: string;
	},
) => {
	tryInitDataLayer();

	// @ts-ignore
	window.dataLayer.push({
		event: type,
		index,
		provider_name: distributorName,
		minimum_value: minimumValue,
		looping_type: businessUnitName,
	});
};

export const selectProviderGTM = ({
	distributorName,
	index,
	status,
	id: productId,
}: Pick<DistributorsProps, 'distributorName' | 'status'> &
	Pick<ProductParams, 'id'> & {
		index?: number;
	}) => {
	tryInitDataLayer();

	// @ts-ignore
	window.dataLayer.push({
		event: 'select_provider',
		provider_name: distributorName,
		status_stock: status,
		index,
		item_id: productId,
	});
};

export const updateProviderGTM = (products: any, basketData?: FindBasketProductsResponse) => {
	tryInitDataLayer();

	const items = Array.isArray(products)
		? products.map(
			({
				id,
				ean13,
				productType,
				description,
				brandName,
				price,
				quantity,
				discount,
				affiliation,
				businessUnitName,
			}: any) => {
				const item_id =
					productType === 'COMBO'
						? basketData?.baskets
							.find((basket) =>
								basket.products.find((product) => product.productType === 'COMBO' && product.id === id),
							)
							?.products.find((product) => product.productType === 'COMBO' && product.id === id)?.hyperaCode
						: ean13;

				return {
					item_id,
					item_variant: businessUnitName,
					item_name: description,
					item_brand: brandName,
					price,
					quantity,
					discount,
					affiliation,
				};
			},
		)
		: [];

	const eventData = {
		items,
	};

	// @ts-ignore
	window.dataLayer.push({ ecommerce: null });
	// @ts-ignore
	window.dataLayer.push({ event: 'update_provider', ecommerce: eventData });
};
