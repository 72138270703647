import { Typography } from 'front-commons/ds';
import DistributorSelectStatus from './DistributorSelectStatus';
import DistributorStatus from './DistributorStatus';
import { getStatusData } from './helpers';
import { DistributorOptionProps } from './interfaces';
import {
	BoxBottomSelector,
	BoxLeftSelector,
	DistributorLabel,
	DistributorOptionContainer,
	DistributorPrice,
} from './styles';

export default function DistributorOption({
	data,
	isPromotionDrawer,
	businessUnitName,
	isSelected,
}: DistributorOptionProps) {
	const isMarcas = businessUnitName === 'Marcas';
	const finalPriceFounded = data.finalPrice || data.unitPricePromotion;
	const finalPriceWithTax = (finalPriceFounded || 0) + (data.taxSubstitution || 0);
	const finalPriceByBussinessUnit = isMarcas ? finalPriceFounded : finalPriceWithTax;

	const getDistributorColor = () => {
		if (isSelected) return '--text-link';

		if (data.finalPrice || isPromotionDrawer || data?.status !== 'UNAVAILABLE') return '--text-primary';

		return '--text-disable';
	};

	return (
		<DistributorOptionContainer>
			<DistributorLabel>
				<BoxLeftSelector>
					<DistributorStatus hiddenLabel status={getStatusData(data.status, data.stock)} />
					<Typography variant="ParagraphSmall/Regular" color={getDistributorColor()}>
						{data.distributorName}
					</Typography>
				</BoxLeftSelector>

				<DistributorPrice className="price-details">
					{finalPriceByBussinessUnit && (
						<Typography variant="Paragraph/Semibold" color="--neutral-black">
							{finalPriceByBussinessUnit.toCurrency()}
						</Typography>
					)}

					{!finalPriceByBussinessUnit && !isPromotionDrawer && <Typography color="--text-disable">-</Typography>}
				</DistributorPrice>
			</DistributorLabel>
			{isMarcas && <DistributorSelectStatus status={data.status} stock={data.stock! || 0} />}

			{!isMarcas && (
				<BoxBottomSelector>
					<DistributorSelectStatus status={data.status} stock={data.stock! || 0} />

					{finalPriceFounded && Number(data.taxSubstitution) > 0 && (
						<DistributorPrice className="price-details">
							<Typography variant="ParagraphSmall/Regular" color="--text-disable">
								{finalPriceFounded.toCurrency()} + {(data.taxSubstitution || 0).toCurrency()}
							</Typography>
						</DistributorPrice>
					)}
				</BoxBottomSelector>
			)}
		</DistributorOptionContainer>
	);
}
