import { Dispatch } from '@reduxjs/toolkit';
import { setCloseDialog, setOpenDialog } from './actions';
import { DialogException } from './helpers';
import type { HandleOpenDialogProps } from './interfaces';

export const handleCloseDialog = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(setCloseDialog());
	};
};

export const handleOpenDialog = ({ ignoreAutoClose, throwCancel, ...dialog }: HandleOpenDialogProps): any => {
	return (dispatch: Dispatch) =>
		new Promise((resolve, reject) => {
			dispatch(
				setOpenDialog({
					...dialog,
					footer: {
						...dialog?.footer,
						primaryButton: {
							...dialog.footer?.primaryButton,
							async onClick() {
								await dialog.footer?.primaryButton?.onClick?.();

								if (!ignoreAutoClose) dispatch(handleCloseDialog());
								resolve(null);
							},
						},
					},
					onClose() {
						dialog.onClose?.();

						if (!ignoreAutoClose) dispatch(handleCloseDialog());

						if (throwCancel) return reject(new DialogException('Dialog was canceled'));

						return resolve(null);
					},
				}),
			);
		});
};
