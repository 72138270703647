import { Radio, Typography } from 'front-commons/ds';
import { PosListProps } from '../../interfaces';
import HeaderSelectorSkeletonsRender from '../Skeleton';

export default function PosList({
	posList,
	loading,
	disabled,
	posNotFound,
	hasOnlyOnePos,
	selectedPosId,
	handleChangePos,
}: PosListProps) {
	if (hasOnlyOnePos) return <Typography>Não há outras farmácias para escolher.</Typography>;

	if (posNotFound) return <Typography color="--semantic-error-text">Farmácia não encontrada</Typography>;

	return (
		<HeaderSelectorSkeletonsRender posList={loading}>
			<Radio
				data={posList.map(({ cnpj, pointOfSaleId, tradeName }) => {
					const label = `${CNPJ(cnpj).mask} ${tradeName.toUpperCase()}`;
					const isSelected = pointOfSaleId === selectedPosId;
					return {
						label: (
							<Typography textEllipsis={1} title={label} cursor={isSelected ? 'not-allowed' : 'pointer'}>
								{label}
							</Typography>
						),
						value: pointOfSaleId,
						disabled: isSelected,
					};
				})}
				direction="column"
				outerGap="16px"
				name="posList"
				onChange={({ currentTarget: { value } }) => handleChangePos(value)}
				value={selectedPosId}
				innerGap="8px"
				disabled={disabled}
			/>
		</HeaderSelectorSkeletonsRender>
	);
}
