import { getThemeMedia, getThemeSpace } from 'front-commons/ds';
import styled, { css } from 'styled-components';

export const PromotionsDrawerContentContainer = styled.div<{ withoutPromotions: boolean }>`
	overflow: hidden auto;
	max-height: calc(100vh - 84px - 24px);
	padding: ${getThemeSpace('8')};
	margin: ${getThemeSpace('8')};

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			max-height: calc(100vh - 108px - 24px);
			padding: 0 ${getThemeSpace('32')};
			margin: ${getThemeSpace('16')} ${getThemeSpace('24')};
		}
	}

	${({ withoutPromotions }) =>
		withoutPromotions &&
		css`
			height: 100%;

			> div {
				height: 100%;
			}
		`}
`;
