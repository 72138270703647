import { CustomerReducer } from 'stores/customer/interfaces';
import versions from '../../version'

export const sendCustomIntegrations = () => {
  return {
    name: "customFrontIntegrations",
    processEvent(event: any, hint: any, client: any) {
      event.extra = {
        ...event.extra,
        front_version: versions.frontVersion || "not-set",
        user_tab_session: window.sessionStorage.getItem('@PHC:userTabSession') || "not-set"
      };
      return event;
    },
  };
}

export const getUserFromLocalStorage = () => {
  return JSON.parse(JSON.parse(window.localStorage.getItem('persist:@PHC') || '{}')?.customerReducer || '{}') as CustomerReducer
}