export const getNotificationMessage = (altered: number, total: number, buName: string) => {
	const isAll = altered === total;
	const isPlural = altered > 1 || isAll;
	const handlePlural = (plural: string, singular = '') => (isPlural ? plural : singular);

	const totalText = `${altered} distribuidor${handlePlural('es')} de ${total} produtos.`;
	const notAllPronoun = handlePlural('Os', 'O');
	const allPronoun = isAll ? 'Todos' : notAllPronoun;

	return `${allPronoun} distribuidor${handlePlural('es')} de ${buName} fo${handlePlural(
		'ram',
		'i',
	)} alterado${handlePlural('s')} com sucesso.\n${totalText}`;
};
