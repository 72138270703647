import { Select } from 'front-commons/ds';
import { SelectProps } from 'front-commons/ds/components/Select/interfaces';
import { useWhenMounted } from 'front-commons/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DistributorsProps } from 'services/products/interfaces';
import { selectProviderGTM } from 'shared/gtm';
import DistributorStatus from './DistributorStatus';
import { getDistributorsOptions, getStatusData, orderByLowerPriceAndAvailable, orderBySelectedFirst } from './helpers';
import { DistributorSelectorProps } from './interfaces';
import { DistributorsSelectorContainer } from './styles';

export default function DistributorsSelector({
	data,
	onChange,
	initialValue,
	id,
	disabled,
	showFooter = true,
	size,
	label,
	isPromotionDrawer,
	orderByLowerPrice,
	businessUnitName,
	permitEmptyValue,
	watchInitialValue,
	waiting,
	recoveryLastDistributor,
	productId,
}: DistributorSelectorProps) {
	const [value, setValue] = useState<DistributorsProps | null>(initialValue || null);
	const [loading, setLoading] = useState(true);
	const [state, setState] = useState({ id: id || '', opened: false });

	const distributorData = useMemo(() => {
		const clonedData = [...data];
		return clonedData.sort(orderByLowerPriceAndAvailable);
	}, [data, value]);

	const [options, setOptions] = useState(
		getDistributorsOptions({ distributorsData: data, state, businessUnitName, value }),
	);

	const handleOpen = (opened: boolean) => {
		setState((prevState) => ({ ...prevState, opened }));
	};

	const handleChange = useCallback(
		(selected: string, isFirstDistributor?: boolean) => {
			const selectedParsed = JSON.parse(selected);
			setValue(selectedParsed);
			onChange(selectedParsed, isFirstDistributor);
		},
		[onChange],
	);

	const handleSelectOption: SelectProps['onChange'] = ({ currentTarget }) => {
		handleChange(currentTarget.value);

		if (!productId) return;

		try {
			const selectedParsed = JSON.parse(currentTarget.value);
			const currentOptionIndex = options.findIndex((option) => option.value === currentTarget.value);

			selectProviderGTM({
				distributorName: selectedParsed.distributorName,
				status: selectedParsed.status,
				index: currentOptionIndex,
				id: productId,
			});
		} catch {
			console.warn('GTM não enviado');
		}
	};

	useEffect(() => {
		if (!permitEmptyValue) {
			setLoading(true);
			const firstDistributorAvailable =
				initialValue || data.find((distributor) => distributor.status !== 'UNAVAILABLE');

			handleChange(JSON.stringify(firstDistributorAvailable || data[0] || {}), true);
		}
		setLoading(false);
	}, [data, permitEmptyValue]);

	useEffect(() => {
		setOptions(
			getDistributorsOptions({
				distributorsData: (orderByLowerPrice ? distributorData : data).sort(orderBySelectedFirst(value)) || [],
				state,
				businessUnitName,
				initialValue,
				isPromotionDrawer,
				value,
			}),
		);
	}, [orderByLowerPrice, distributorData, value]);

	useWhenMounted(() => {
		if (watchInitialValue) {
			setValue(initialValue || null);
		}
	}, [watchInitialValue, initialValue]);

	useWhenMounted(() => {
		if (recoveryLastDistributor) {
			setValue(recoveryLastDistributor);
		}
	}, [recoveryLastDistributor]);

	return (
		<DistributorsSelectorContainer>
			<Select
				label={label}
				name="distributorsSelect"
				placeholder="Selecione um distribuidor"
				onChange={handleSelectOption}
				options={options || []}
				value={value ? JSON.stringify(value) : ''}
				orderOptionsBy="NORMAL"
				waiting={loading || waiting}
				onOpenChange={handleOpen}
				disabled={disabled}
				size={size}
				permitClearSelected={false}
				helpText={
					showFooter && value ? <DistributorStatus status={getStatusData(value.status, value.stock)} /> : undefined
				}
			/>
		</DistributorsSelectorContainer>
	);
}
