import { Flex } from 'front-commons/ds';
import { memo } from 'react';
import DistributorCardSkeleton from 'containers/Cards/DistributorCard/Skeleton';

function DistributorsDragAndDropSkeleton() {
	const cards = Array.from({ length: Number.getRandomInt(1, 5) }, (_, index) => index);

	return (
		<Flex direction="column" width="100%" alignItems="center">
			{cards.map((card) => (
				<DistributorCardSkeleton key={card} />
			))}
		</Flex>
	);
}

export default memo(DistributorsDragAndDropSkeleton);
