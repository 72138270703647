import { Flex } from "front-commons/ds";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
background-color: var(--fuchsia-50);

p:first-child {
	color: var(--fuchsia-800);
	display: flex;
	align-items: center;
	gap: 4px;
}
`;