import { handleSetLoading, handleSetToken } from './middleware';

export enum CustomerAccess {
	BASIC = 'SIMULATED_BASIC',
	ACTION = 'SIMULATED_ACTION',
}

export enum CustomerRole {
	SUPERVISOR = 'SUPERVISOR',
	CONSULTANT = 'CONSULTANT',
}

export type AccessType = CustomerAccess.BASIC | CustomerAccess.ACTION;
export type HyperaRole = CustomerRole.SUPERVISOR | CustomerRole.CONSULTANT;

export interface CustomerParams {
	id: string;
	name: string;
	email: string;
	cpf: string;
	phones: string[];
	enabled: boolean;
	contactPreferences: string[];
	accessType: AccessType | null;
	hyperaRole: HyperaRole | null;
	serasaScore: number | null;
	serasaBlockedUntil: string | null;
	hyperaRegistration: string | null;
	sectorId: number | null;
	phoneNumber: string | null;
	phoneNumberConfirmed: boolean;
	twoFAExpiration?: number;
}

export type CustomerProvider = 'PARCEIRO_HYPERA' | 'ZECA_COPILOTO';

export interface CustomerReducer {
	loading: boolean;
	data: CustomerParams;
	token: string | null;
	userIsLoggingOut: boolean;
	provider: CustomerProvider | null;
	firstAccess: string[];
}

export interface HandleGetCustomerDataReturn {
	payload: {
		customer: CustomerReducer['data'];
	};
}

export type PermissionIds =
	| 'BASKET_EDIT'
	| 'DISTRIBUTOR_EDIT'
	| 'BASKET_FINALIZE'
	| 'USER_DATA_EDIT'
	| 'ACCESS_MANAGE_EDIT'
	| 'LIST_POS';

export interface PermissionGateProps {
	children: JSX.Element;
	id: PermissionIds;
}

export type HandleSetLoadingType = typeof handleSetLoading;
export type HandleGetCustomerDataType = VoidFunction;
export type HandleSetTokenType = typeof handleSetToken;
