import { notify } from 'front-commons/ds';
import { useWhenMounted } from 'front-commons/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PromotionDetailsDrawer from 'containers/Contents/PromotionDetailsDrawer';
import { manageBasketProductAmount, validateBasketProductAmount } from 'services/basket';
import { DistributorsProps, DistributorStatus } from 'services/products/interfaces';
import { CustomError } from 'shared/errors';
import { updateCartGTM } from 'shared/gtm';
import { getDialogProps, getUnavailableProductsDialogContent } from 'shared/promotionDrawer';
import useBasket from 'stores/basket';
import usePromotionDetails from 'stores/catalog/usePromotionDetails';
import useDialog from 'stores/dialog';
import useDrawer from 'stores/drawer';
import { ContentsPromotionDetailsDrawerProps, DialogState, ShowUnavailableProductsDialogParams } from './interfaces';

export default function ContentsPromotionDetailsDrawer({
	promotionId = '',
	posId = '',
	sourceProductId,
	orderDistributorByPrice,
	initialLoading,
	showReturnButton,
}: ContentsPromotionDetailsDrawerProps) {
	const {
		data,
		summary,
		loading,
		selectedDistributor,
		productsInComboPrices,
		notFoundProducts,

		closePromotionDrawer,
		getPromotionDetails,
		setSelectedDistributor,
		getProductInComboValues,
		getSearchedProducts,
		handleSortItems,
		updateSummary,
		getFlexQuantityInBasket,
		getProductValidity,
	} = usePromotionDetails();

	const {
		handleOpenBasket,
		handleGetBasketData,
		getPromotionInBasket,
		handleRemoveFromBasket,
		getProductsInBasketByType,

		basketStore: { basket: basketData, loading: loadingBasket },
	} = useBasket();

	const { handleCloseAllDrawers, isDrawerOpen } = useDrawer();
	const { handleOpenDialog } = useDialog();

	const updatedProductsInCombo = summary?.map(
		({ productId: id, quantity, distributors, ean13, description, brandName, categoryName }) => ({
			productId: id,
			quantity,
			distributors,
			ean13,
			description,
			brandName,
			categoryName,
		}),
	);

	const currentBasketDistributor = data?.distributors?.find(({ distributorId }) => {
		return (
			distributorId ===
			basketData?.baskets?.find(({ products }) => products.find(({ id }) => id === promotionId))?.distributorId
		);
	});

	const [flexAddLoading, setLoading] = useState(false);

	const [distributorWaiting, setDistributorWaiting] = useState(false);
	const [recoveryLastDistributor, setRecoveryLastDistributor] = useState<DistributorsProps | undefined>();

	const currentQuantityInBasket = useMemo(
		() => basketData?.baskets?.flatMap(({ products }) => products).find(({ id }) => id === promotionId)?.quantity,
		[basketData, data, promotionId],
	);

	const { brute, discount, liquid, tax } = getProductInComboValues?.() || {};

	const isOpen = isDrawerOpen('promotion-details-drawer');

	const handleShowUnavailableProductsDialog = async ({
		distributor,
		productsUnavailable,
		isAllUpdatedProductsUnavailable,
	}: ShowUnavailableProductsDialogParams) => {
		const dialogContent = getUnavailableProductsDialogContent({
			distributor,
			productsUnavailable,
			isAllUpdatedProductsUnavailable,
		});

		await handleOpenDialog({
			throwCancel: true,
			heading: {
				title: 'Produtos indisponíveis',
				iconOptions: { name: 'brightness_alert', color: '--semantic-warning-base', size: '24px' },
				showCloseButton: true,
			},
			content: dialogContent,
			footer: {
				primaryButton: {
					label: 'Prosseguir',
				},
				secondaryButton: {
					label: 'Manter carrinho',
				},
			},
		});
	};

	const handleSetSelectedDistributor = (distributor: DistributorsProps) => {
		if (window.location.pathname.includes('/revisar-pedido/produtos-indisponiveis')) return;
		setSelectedDistributor(distributor);
	};

	const handleOpenSideBasket = () => {
		closePromotionDrawer();
		handleCloseAllDrawers();

		handleOpenBasket();
	};

	const handleFetchDistributorChange = async (distributor: string) => {
		try {
			if (!posId) return;

			const requestData = {
				quantity: currentQuantityInBasket || 1,
				productId: promotionId,
				distributorId: distributor,
				productsInCombo: updatedProductsInCombo.filter((product) =>
					data.productsInCombo
						?.filter((item) => item.distributorsPrice.find((i) => i.distributorId === distributor)?.valid === true)
						.map((i) => i.productId)
						.includes(product.productId),
				),
				suggestionGroupId: data.suggestionGroupId,
				suggestionGroupName: data.suggestionGroupName,
			};

			await manageBasketProductAmount(posId, requestData);
			notify.positive({ description: 'Distribuidor alterado com sucesso.' });
			handleGetBasketData({ loading: 'refetch' });
		} catch (error) {
			notify.negative({ description: 'Não foi possivel alterar o distribuidor do produto.' });

			if (currentBasketDistributor)
				handleSetSelectedDistributor({
					distributorId: currentBasketDistributor.distributorId,
					distributorName: currentBasketDistributor.distributorName,
					order: currentBasketDistributor.order,
					status: currentBasketDistributor.status as DistributorStatus,
				});
		}
	};

	const getDistributorInfo = (info: 'finalPrice' | 'price' | 'distributorName', fallback: string | number) => {
		const atualDistributor =
			selectedDistributor || data.distributors.find((distributor: any) => distributor.status !== 'UNAVAILABLE');
		return atualDistributor?.[info] || fallback;
	};

	const GTMCartEvent = (type: string) => {
		const affiliation = getDistributorInfo('distributorName', '') as string;

		const newArray = summary
			.filter((item) => item.quantity > 0)
			.map((i) => ({
				id: i.ean13,
				description: i.description,
				price: (i.price - (i.discount / 100) * i.price).toFixed(2),
				quantity: i.quantity,
				discount: (i.price * i.discount) / 100,
				brandName: i.brandName,
				categoryName: i.categoryName,
				affiliation,
				businessUnitName: data.businessUnitName,
				promotion_name: data.description,
				promotion_id: data.hyperaCode,
				combo: true,
			}));

		updateCartGTM(type, liquid, newArray);
	};

	const handleRemovePromotion = useCallback(async () => {
		try {
			await handleRemoveFromBasket(promotionId);

			if (!data.fixedAmount) {
				summary.forEach((item) => {
					updateSummary({
						...item,
						quantity: 0,
					});
				});
			}
		} finally {
			GTMCartEvent('remove_from_cart');
		}
	}, [promotionId, posId, summary]);

	const handleValidateDistributorSelected = useCallback(
		async (distributor: DistributorsProps) => {
			const productsUnavailable = updatedProductsInCombo.filter(({ distributors, quantity }) => {
				const distributorFound = distributors.find(({ distributorId }) => distributorId === distributor.distributorId);
				return quantity === 0 && !distributorFound?.valid;
			});

			if (!productsUnavailable.length) return 'UPDATING';

			const isAllUpdatedProductsUnavailable =
				productsUnavailable.length === updatedProductsInCombo.filter((p) => p.quantity).length;

			try {
				setDistributorWaiting(true);

				await handleShowUnavailableProductsDialog({
					distributor,
					productsUnavailable,
					isAllUpdatedProductsUnavailable,
				});

				productsUnavailable.forEach(({ ...product }) => {
					updateSummary({
						...product,
						quantity: 0,
						price: 0,
						discount: 0,
						tax: 0,
					});
				});

				if (isAllUpdatedProductsUnavailable) {
					if (!getPromotionInBasket(promotionId)) return '';

					return 'REMOVING';
				}

				return 'UPDATING';
			} catch (err) {
				setRecoveryLastDistributor(selectedDistributor);
				throw new CustomError('cancel distributor');
			} finally {
				setDistributorWaiting(false);
			}
		},
		[updatedProductsInCombo, selectedDistributor, handleRemovePromotion, getPromotionInBasket, promotionId],
	);

	const handleChangeDistributor = useCallback(
		async (distributor: DistributorsProps, isAutoDistributorSelected?: boolean) => {
			try {
				let validateResponse;

				if (!isAutoDistributorSelected && currentQuantityInBasket) {
					validateResponse = await handleValidateDistributorSelected(distributor);
				}

				const isRemoving = validateResponse === 'REMOVING';
				const isUpdating = validateResponse === 'UPDATING';
				const shouldUpdateBasket =
					isUpdating &&
					currentQuantityInBasket &&
					currentBasketDistributor?.distributorId !== distributor.distributorId;

				if (isRemoving) {
					handleRemovePromotion();
				}

				if (shouldUpdateBasket) {
					handleFetchDistributorChange(distributor.distributorId);
				}

				handleSetSelectedDistributor(distributor);
			} finally {
				setRecoveryLastDistributor(undefined);
			}
		},
		[currentQuantityInBasket, currentBasketDistributor, summary, handleRemovePromotion],
	);

	const handleDialogClose = () => {
		getPromotionDetails({ promotionId, loadingType: 'full' });
	};

	const handleSetDialogState = useCallback((state: DialogState) => {
		handleOpenDialog({
			...getDialogProps(state, {
				handleOpenSideBasket,
				handleRemovePromotion,
				handleDialogClose,
			}),
		});
	}, []);

	const handleFlexAdd = async () => {
		try {
			if (!posId) return;
			setLoading(true);

			const requestData = {
				quantity: 1,
				productId: promotionId,
				distributorId: selectedDistributor.distributorId,
				productsInCombo: updatedProductsInCombo,
				suggestionGroupId: data.suggestionGroupId,
				suggestionGroupName: data.suggestionGroupName,
			};

			const hasConflict = await validateBasketProductAmount(posId, requestData);

			if (hasConflict) {
				handleSetDialogState('conflict');
				return;
			}

			await manageBasketProductAmount(posId, requestData);

			GTMCartEvent('add_to_cart');

			notify.positive({ description: `Promoção ${currentQuantityInBasket ? 'atualizada' : 'adicionada'} com sucesso` });

			handleGetBasketData({ loading: 'refetch' });
		} finally {
			setLoading(false);
		}
	};

	const handleAddToBasketClick = () => {
		if (liquid) return handleFlexAdd();
		return handleSetDialogState('no_value');
	};

	useEffect(() => {
		if (promotionId && isOpen) {
			getPromotionDetails({ promotionId, loadingType: initialLoading, posId });
		}
	}, [promotionId, isOpen]);

	return (
		<PromotionDetailsDrawer
			data={data}
			basketData={basketData}
			promotionId={promotionId}
			recoveryLastDistributor={recoveryLastDistributor}
			orderDistributorByPrice={orderDistributorByPrice}
			distributorWaiting={distributorWaiting}
			summary={summary}
			loadingPromotion={loading}
			loadingBasket={loadingBasket}
			productsInComboPrices={productsInComboPrices || []}
			brute={brute}
			liquid={liquid}
			discount={discount}
			tax={tax}
			selectedDistributor={selectedDistributor}
			updatedProductsInCombo={updatedProductsInCombo}
			flexAddLoading={flexAddLoading}
			notFoundProducts={notFoundProducts}
			sourceProductId={sourceProductId}
			showReturnButton={showReturnButton}
			dispatchGTMBasketEvent={GTMCartEvent}
			updateSummary={updateSummary}
			getFlexQuantityInBasket={getFlexQuantityInBasket}
			getProductValidity={getProductValidity}
			handleChangeDistributor={handleChangeDistributor}
			handleRemovePromotion={handleRemovePromotion}
			handleAddToBasketClick={handleAddToBasketClick}
			getSearchedProducts={getSearchedProducts}
			handleSortItems={handleSortItems}
			getProductsInBasketByType={getProductsInBasketByType}
		/>
	);
}
