/* eslint-disable prefer-rest-params */
/* eslint-disable prettier/prettier */
/* eslint-disable func-names */
import { InsiderRouteState } from './interfaces';

export const fallbackInsiderObject = () => {
  window.insider_object = window.insider_object || {};
};

export const insiderRouteState = { [InsiderRouteState.SHOULD_CONFIRM]: true };

export const ajaxListenerConfirmation = function (callback: any) {
  // eslint-disable-next-line strict, lines-around-directive
  'use strict';

  const oldOpen = XMLHttpRequest.prototype.open;

  XMLHttpRequest.prototype.open = function (method, url) {
    this.addEventListener('readystatechange', function () {
      if (
        this.responseType !== 'arraybuffer' &&
        this.responseType !== 'blob' &&
        this.readyState === 4 &&
        this.status === 200 &&
        typeof callback === 'function'
      ) {
        callback(url, this.response, method);
      }
    });

    // @ts-ignore
    oldOpen.apply(this, arguments);
  };
};