import { AxiosError } from 'axios';
import { createAPIConnection } from 'services';
import { requestHandler } from 'services/helpers';
import { getStore } from 'stores/helpers';
import {
	BasketProductsSummaryResponse,
	BasketProductsUnavailableResponse,
	BusinessUnitProps,
	PromotionOptionParams,
	FinalizeOrderResponse,
	FindBasketProductsResponse,
	FindPaymentMethodsResponse,
	ManageBasketProductAmountParams,
	ManageBasketProductAmountResponse,
	ProductsToReplaceRequest,
	ValidateBasketProductAmountResponse,
} from './interfaces';

const api = createAPIConnection('/basket', {
	onRequest(config) {
		const provider = getStore().customerReducer?.provider;

		if (provider === 'ZECA_COPILOTO') Object.assign(config?.headers || {}, { 'Partner-origin': 'zecacopiloto' });
	},
});

export const initializeBasket = async (posId: string, count = 0) => {
	try {
		await api.get(`/loadBasket/${posId}`);
	} catch (error) {
		if (count < 3) {
			await Promise.fake({ delay: 2000 });
			await initializeBasket(posId, count + 1);
		}

		console.log(error);
	}
};

export const getBasketData = async ({ posId, buId }: { posId: string; buId?: string }) =>
	requestHandler<FindBasketProductsResponse>(api.get(`/${posId}`, { params: { buId } }));

export const manageBasketProductAmount = async (posId: string, data: ManageBasketProductAmountParams) => {
	return requestHandler<ManageBasketProductAmountResponse>(api.post(`/addProduct/${posId}`, data));
};

export const validateBasketProductAmount = async (posId: string, data: ManageBasketProductAmountParams) => {
	return requestHandler<ValidateBasketProductAmountResponse>(api.post(`/addProduct/${posId}/validate`, data));
};

export const removeProduct = async (posId: string, productId: string) => {
	return requestHandler(api.post(`/removeProduct/${posId}`, { productId }));
};

export const getBasketCheckoutData = async ({ posId, buId }: { posId: string; buId?: string }) => {
	return requestHandler<FindBasketProductsResponse>(api.get(`/checkout/${posId}`, { params: { buId } }));
};

export const getPaymentMethods = async () => {
	return requestHandler<FindPaymentMethodsResponse[]>(api.get(`/payment/method`));
};

export const clearSelectedBaskets = (posId: string, basketIds: string[]) => {
	return requestHandler(api.post(`/removeBasket/${posId}`, { basketIds }));
};

export const finalizeOrder = async (
	posId: string,
	paymentFormId: string,
	basketIds: string[],
	observation: string,
	originMethod?: string,
) => {
	return requestHandler<FinalizeOrderResponse>(
		api.post(`/closeOrder/${posId}`, { paymentFormId, basketIds, observation, originMethod }),
		{
			throwData: true,
		},
	);
};

export const getStatuses = async () => {
	return requestHandler<PromotionOptionParams[]>(api.get('/status'));
};

export const getChannels = async () => {
	return requestHandler<PromotionOptionParams[]>(api.get('/salesChannel'));
};

export const getBasketProductsSummary = async (posId: string, basketId: string) => {
	return requestHandler<BasketProductsSummaryResponse[]>(api.get(`/${basketId}/pos/${posId}/products/summary`));
};

export const getProductsUnavailable = async (posId: string) => {
	return requestHandler<BasketProductsUnavailableResponse>(api.get(`/${posId}/validate`));
};

export const replaceProductsUnavailable = async (posId: string, products: BusinessUnitProps) => {
	return requestHandler(api.post(`/${posId}/replace-products-distributor`, products));
};

export const replaceProducts = async (posId: string, products: ProductsToReplaceRequest[]) =>
	requestHandler(api.post(`/${posId}/replace-products`, products));

export const getBasketSuggestion = async (posId: string, navigate: Function) => {
	try {
		const response = await api.get(`/basket-suggestion/${posId}`);
		if (response.data.code === 200) {
			navigate('/otimizacao-de-pedido', { state: { data: response.data?.content, suggestionFrom: 'basket' } });
			return response.data;
		}
		return { status: response?.data?.code };
	} catch (error) {
		if (error instanceof AxiosError) {
			throw error?.response?.data?.code;
		}
		throw error;
	}
};

export const acceptBasketSuggestion = async (posId: string, agnosticBasketVersionId: number) => {
	try {
		const response = await api.post(`/basket-suggestion/${posId}/accept`, {
			agnosticBasketVersionId,
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw error?.response?.data?.code;
		}
		throw error;
	}
};
