import { Button, Flex, Skeleton, Tag, Typography } from 'front-commons/ds';
import { useMemo } from 'react';
import DistributorStatus from 'containers/Distributors/DistributorsSelector/DistributorStatus';
import { getStatusData } from 'containers/Distributors/DistributorsSelector/helpers';
import TagProductDiscount from 'containers/Tags/TagProductDiscount';
import { getTypePromotion } from 'shared/product';
import { getRemainingDaysText } from 'shared/promotionDrawer';
import useBasket from 'stores/basket';
import useDrawer from 'stores/drawer';
import useOrderImport from 'stores/orderImport';
import { PromotionDrawerCardProps } from './interfaces';
import {
	BoxImage,
	BoxPrice,
	BoxPriceInfo,
	PromotionCardProductsImageWrapper,
	PromotionCardProductsThumbnails,
	InfoDistributor,
	Separator,
	WrapperPromotionCard,
} from './styles';
import TagCampaignLimiter from 'containers/Tags/TagCampaignLimiter';

export default function PromotionDrawerCard({
	data,
	posId,
	sourceProductId,
	useCatalogContext,
	currentDistributor,
	orderImportProductId,
	handleRefetchItemList,
}: PromotionDrawerCardProps) {
	const {
		basketStore: { basket: basketData },
	} = useBasket();
	const { handleOpenDetailsDrawer } = useOrderImport();
	const { handleOpenDrawer } = useDrawer();

	const {
		id: promotionId,
		description,
		progressiveDiscount,
		progressiveDiscountProductMix,
		productsInCombo,
		distributors,
		moreAbout,
		endDate,
		maxDiscount,
		fixedAmount,
		businessUnitName,
		hyperaCode,
		availableLimit,
		limitedByQuantity,
	} = data;

	const firstDistributorAvailable = useMemo(() => {
		const distributorAvailable = distributors.find((distributor) => distributor.status !== 'UNAVAILABLE');
		return distributorAvailable;
	}, []);

	const hasAddedInBasket = useMemo(() => {
		if (orderImportProductId) {
			return false;
		}
		const allProductsInBasket = basketData?.baskets?.flatMap((basket) => basket.products);
		const productFoundInBasket = allProductsInBasket?.some((product) => product.id === promotionId);
		return Boolean(productFoundInBasket);
	}, [basketData?.baskets]);

	const singleProduct = productsInCombo?.length === 1 ? productsInCombo[0] : false;

	const getStaticProductUrl = (url: string) => `${import.meta.env.VITE_STATIC_STORAGE_BASE}${url}`;

	const handleShowPromotionDetailsDrawer = () => {
		if (useCatalogContext) {
			handleOpenDrawer?.('promotion-details-drawer', {
				promotionId,
				posId,
				sourceProductId,
				showReturnButton: true,
				initialLoading: 'opening',
			});
			return;
		}

		handleOpenDetailsDrawer({
			posId,
			promotionId,
			sourceProductId,
			showReturnButton: true,
			importDistributor: currentDistributor,
			orderImportProductId,

			handleRefetchItemList,
		});
	};

	return (
		<WrapperPromotionCard hasAddedInBasket={hasAddedInBasket}>
			<Flex direction="column" gap="8px">
				<Tag
					// @ts-ignore
					bgColor={limitedByQuantity ? '--fuchsia-800' : '--semantic-promo-text'}
					label={getTypePromotion({
						longName: true,
						progressiveDiscount,
						progressiveDiscountProductMix,
					})}
					labelOptions={{ variant: 'Caption/Regular', color: '--text-invert' }}
				/>

				{limitedByQuantity && <TagCampaignLimiter availableLimit={availableLimit} alignment="flex-start" />}

				<Flex direction="column" gap="4px">
					<Typography variant="Subtitle" color="--text-primary">
						{description.toPascalCase(true)}
					</Typography>

					<Typography variant="ParagraphSmall/Regular" color="--text-disable">
						{hyperaCode}
					</Typography>
				</Flex>

				{(singleProduct || businessUnitName === 'Neo Química') && firstDistributorAvailable && (
					<InfoDistributor>
						<DistributorStatus hiddenLabel status={getStatusData(firstDistributorAvailable.status)} />

						<Typography>{firstDistributorAvailable.distributorName}</Typography>
					</InfoDistributor>
				)}

				<Flex direction="column" gap="0px">
					<Typography>{moreAbout}</Typography>

					<Typography variant={{ small: 'ParagraphSmall/Semibold', medium: 'Paragraph/Semibold' }}>
						{getRemainingDaysText(endDate)}
					</Typography>
				</Flex>

				{singleProduct && (
					<Typography variant="Paragraph/Semibold" as="span" display={{ medium: 'none' }}>
						EAN: <Typography as="span">{singleProduct.productInfo.ean13}</Typography>
					</Typography>
				)}

				<Flex justifyContent="space-between" alignItems="center">
					<Flex gap="8px">
						{singleProduct ? (
							<BoxImage>
								<img
									src={getStaticProductUrl(singleProduct.productInfo.imageURL)}
									alt={`Imagem que representa o produto ${singleProduct.productInfo.description}`}
								/>
							</BoxImage>
						) : (
							<PromotionCardProductsThumbnails thumbsLength={productsInCombo?.length || 1}>
								{productsInCombo.map((product, i) => (
									<PromotionCardProductsImageWrapper key={i}>
										<img
											src={getStaticProductUrl(product.productInfo.imageURL)}
											alt={`Imagem que representa o produto ${product.productInfo.description}`}
										/>
									</PromotionCardProductsImageWrapper>
								))}
							</PromotionCardProductsThumbnails>
						)}

						{!!singleProduct && (
							<Flex direction="column" gap="8px">
								<Typography variant="Paragraph/Semibold" as="span" display={{ small: 'none', medium: 'block' }}>
									EAN: <Typography as="span">{singleProduct.productInfo.ean13}</Typography>
								</Typography>
							</Flex>
						)}
					</Flex>

					{!!firstDistributorAvailable && (
						<div>
							{!fixedAmount || !!progressiveDiscount || !!progressiveDiscountProductMix ? (
								<Typography variant={{ small: 'Paragraph/Semibold', medium: 'Subtitle' }}>
									Até {String(maxDiscount)?.includes('.') ? String(maxDiscount).replace('.', ',') : maxDiscount}% de
									desconto
								</Typography>
							) : (
								<BoxPrice>
									<BoxPriceInfo>
										{!!firstDistributorAvailable?.price && (
											<>
												<Typography variant={{ small: 'ParagraphSmall/Regular', medium: 'Paragraph/Regular' }}>
													De {firstDistributorAvailable.price.toCurrency()}
												</Typography>

												<TagProductDiscount discount={maxDiscount} />
											</>
										)}
									</BoxPriceInfo>
									<BoxPriceInfo className="final_price">
										<Typography variant={{ small: 'Paragraph/Semibold', medium: 'Subtitle' }}>
											Por {(firstDistributorAvailable?.finalPrice || 0).toCurrency()}
										</Typography>
									</BoxPriceInfo>

									<Typography variant="ParagraphSmall/Regular">
										{firstDistributorAvailable?.taxSubstitution ? (
											<>
												<strong>+ {firstDistributorAvailable.taxSubstitution.toCurrency()}</strong> imposto
											</>
										) : (
											'+ impostos'
										)}
									</Typography>
								</BoxPrice>
							)}
						</div>
					)}
				</Flex>
			</Flex>

			<Flex justifyContent={hasAddedInBasket ? 'space-between' : 'flex-end'}>
				{!firstDistributorAvailable && (
					<Flex direction="column" gap="8px">
						<Typography variant={{ small: 'Paragraph/Semibold', medium: 'Subtitle' }}>
							Até {String(maxDiscount)?.includes('.') ? String(maxDiscount).replace('.', ',') : maxDiscount}% de
							desconto
						</Typography>
						<Typography>Os produtos deste combo não estão disponíveis na sua seleção de distribuidores.</Typography>
					</Flex>
				)}
				{hasAddedInBasket && (
					<Typography color="--text-link" variant="ParagraphSmall/Semibold">
						Promoção adicionada ao carrinho
					</Typography>
				)}

				<Button
					removeBlurAfterClick
					onClick={handleShowPromotionDetailsDrawer}
					disabled={limitedByQuantity && (availableLimit === 0 || availableLimit == null)}
				>
					Ver promoção
				</Button>
			</Flex>
			{!hasAddedInBasket && <Separator className="separator" />}
		</WrapperPromotionCard>
	);
}

PromotionDrawerCard.Skeleton = () => {
	return (
		<WrapperPromotionCard hasAddedInBasket={false}>
			<Flex direction="column" gap="8px">
				<Skeleton height="18px" width="80px" />

				<Skeleton height={{ small: '20.8px', medium: '33.8px' }} width="80%" />

				<Flex alignItems="center" gap="8px">
					<Skeleton height="10px" width="10px" type="circle" />

					<Skeleton height="20.8px" width="50px" />
				</Flex>

				<Flex direction="column" gap="0px">
					<Skeleton height={{ small: '18.19px', medium: '20.8px' }} width="200px" />
				</Flex>

				<Typography display={{ medium: 'none' }} variant="Paragraph/Semibold" as="span">
					EAN: <Skeleton height="20.8px" width="120px" />
				</Typography>

				<Flex justifyContent="space-between" alignItems="center">
					<Flex gap="8px">
						<PromotionCardProductsThumbnails thumbsLength={1}>
							{Array.generate(3).map((_, index) => (
								<PromotionCardProductsImageWrapper key={index}>
									<Skeleton height={{ small: '68px', medium: '80px' }} width={{ small: '68px', medium: '80px' }} />
								</PromotionCardProductsImageWrapper>
							))}
						</PromotionCardProductsThumbnails>
					</Flex>

					<Skeleton height={{ small: '20.8px', medium: '33.8px' }} width="150px" />
				</Flex>
			</Flex>

			<Flex justifyContent="flex-end">
				<Skeleton height="40px" width="140px" />
			</Flex>
			<Separator className="separator" />
		</WrapperPromotionCard>
	);
};
