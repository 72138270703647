import { Tag } from 'front-commons/ds';
import { TagProductDiscountProps } from './interfaces';

export default function TagProductDiscount({ discount, bgColor, labelOptions, iconOptions }: TagProductDiscountProps) {
	return (
		<Tag
			label={`${discount?.getDiscountFormat()}%`}
			bgColor={bgColor || '--semantic-promo-text'}
			iconOptions={iconOptions || { name: 'arrow_downward', color: '--neutral-white', size: '12px' }}
			labelOptions={labelOptions || { color: '--neutral-white', variant: 'ParagraphSmall/Regular' }}
		/>
	);
}
