import { Typography, Flex } from 'front-commons/ds';
import { Draggable, DroppableProvided } from 'react-beautiful-dnd';
import DistributorCard from 'containers/Cards/DistributorCard';
import ColumnsCTA from './CTA';
import DummyCards from './DummyCards';
import { DistributorsSelectedProps } from './interfaces';
import { SelectedCardsWrapper } from './styles';

export default function SelectedColumn({
	buId,
	isPed,
	posList,
	CustomCTA,
	pharmacyId,
	hideMinValue,
	dropProvided,
	callToActionProps,
	selectedDistributors,
	onRemove,
	onReorder,
}: DistributorsSelectedProps & { dropProvided: DroppableProvided }) {
	return (
		<Flex direction="column" gap="32px" minWidth={{ medium: 'calc(50% - 32px)' }} order={{ small: 1, medium: 2 }}>
			<Flex justifyContent="center" textAlign="center" display={{ small: 'none', medium: 'flex' }}>
				<Typography variant="Paragraph/Semibold">Distribuidores selecionados</Typography>
			</Flex>

			<Flex
				direction="column"
				data-testid="selected-distributors"
				ref={dropProvided.innerRef}
				{...dropProvided.droppableProps}
				style={{ position: 'relative' }}
			>
				<SelectedCardsWrapper isPed={isPed}>
					{selectedDistributors?.distributors?.map((props, index) => (
						<Draggable key={props.id} draggableId={props.id} index={index}>
							{(dragProvided, dragSnapshot) => {
								return (
									<DistributorCard
										isPed={isPed}
										active={{
											...props,
											index,
											order: index,
											pharmacyId,
											dragSnapshot,
											dragProvided,
											hideMinValue,
											selectedLength: selectedDistributors.distributors.length,
											onRemove,
											onReorder,
										}}
									/>
								);
							}}
						</Draggable>
					))}
				</SelectedCardsWrapper>
				<Flex
					width="100%"
					direction="column"
					gap="24px"
					display={{ small: 'none', medium: 'flex' }}
					style={{ position: 'absolute', zIndex: 0 }}
				>
					<DummyCards
						type="PLACEHOLDER"
						selectedLength={selectedDistributors?.distributors.length}
						length={isPed ? 1 : 5}
					/>
				</Flex>
				{!CustomCTA && (
					<Flex display={{ small: 'none', medium: 'flex' }}>
						<ColumnsCTA
							{...callToActionProps}
							selectedDistributors={selectedDistributors}
							posList={posList}
							buId={buId}
						/>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
}
