import { Flex, Skeleton, Typography } from 'front-commons/ds';
import { CSSProperties } from 'react';
import useDrawer from 'stores/drawer';

export default function DistributorChangeDrawerHeader() {
	const { drawerStore } = useDrawer();
	const items = drawerStore.dynamicTitle.find(({ drawerId }) => drawerId === 'distributor-change-drawer')!
		.title as number;

	const style: CSSProperties = { transform: 'translateY(-2px)' };

	return (
		<Flex alignItems="flex-end" gap="8px">
			<Typography as="h2" color="--semantic-info-text" variant="Paragraph/Semibold" style={{ fontSize: '20px' }}>
				Alterar distribuidor
			</Typography>

			<Flex style={style}>
				{items ? (
					<Typography variant="ParagraphSmall/Regular" color="--text-primary">
						{items} ite{items > 1 ? 'ns' : 'm'}
					</Typography>
				) : (
					<Skeleton height="14px" width="70px" />
				)}
			</Flex>
		</Flex>
	);
}
