import { Button, Flex, Icon, Typography, notify } from 'front-commons/ds';
import { useEffect, useRef, useState } from 'react';
import * as workerTimers from 'worker-timers';
import { UNDO_TIME } from 'containers/Drawers/PromotionSuggestion/helpers';
import { text } from './helpers';
import { UndoSuggestionCardProps } from './interfaces';
import { Container } from './styles';

export default function UndoSuggestionCard({
	canBeAnchored,
	loading,
	isMany,
	larger,
	onUndo,
	onTimeout,
}: UndoSuggestionCardProps) {
	const texts = text[isMany ? 'many' : 'single'];

	const ctaRef = useRef<HTMLDivElement>(null);
	const [isVisible, setIsVisible] = useState(true);

	const handleUndo = () => {
		onUndo();
		if (loading === undefined) setIsVisible(false);
	};

	const handleFocusOnPromotion = (top: number) => {
		window.scrollTo({ top, behavior: 'smooth' });
	};

	useEffect(() => {
		let timer: number;

		if (isVisible) {
			timer = workerTimers.setTimeout(() => {
				setIsVisible(false);
				onTimeout?.();
			}, UNDO_TIME);
		}

		return () => workerTimers.clearTimeout(timer);
	}, []);

	useEffect(() => {
		if (ctaRef.current && canBeAnchored) {
			const anchorPosition = ctaRef.current?.getBoundingClientRect().y;

			const onClick = () => handleFocusOnPromotion(anchorPosition - 275);

			notify.info({
				description: (
					<Flex gap="8px" alignItems="center">
						<Typography as="span">Substituição realizada, distribuidor alterado.</Typography>
						<Button variant="text" onClick={onClick}>
							<Typography color="--text-link">Ver produto</Typography>
						</Button>
					</Flex>
				),
			});
		}
	}, [ctaRef]);

	return (
		<Container
			ref={ctaRef}
			justifyContent="space-between"
			alignItems={{ small: 'flex-start', medium: 'center' }}
			backgroundColor="--semantic-success-weak"
			padding={{ small: '8px', medium: '4px 8px' }}
			width="100%"
			minHeight={larger ? '36px' : '28px'}
			display={isVisible ? 'flex' : 'none'}
			direction={{ small: 'column', medium: 'row' }}
			gap="8px"
			data-loading={loading}
		>
			<Flex gap="8px" alignItems="center">
				<Icon name="check" size="16px" />
				<Typography variant="ParagraphSmall/Regular">{loading ? 'Desfazendo substituição' : texts.feedback}</Typography>
			</Flex>

			<Flex margin={{ small: '0 0 0 24px', medium: loading ? '0 16px 0 0' : '0' }}>
				<Button variant="none" onClick={handleUndo} loading={loading} loadingProps={{ size: '16' }}>
					<Typography style={{ textDecoration: 'underline' }} color="--text-link" variant="ParagraphSmall/Regular">
						{texts.cta}
					</Typography>
				</Button>
			</Flex>
		</Container>
	);
}
