import { Flex } from 'front-commons/ds';
import styled from 'styled-components';

export const ProductImage = styled.figure`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 78px;
	height: 78px;

	img {
		max-width: 78px;
		max-height: 78px;
	}
`;

export const WithoutPromotionsContainer = styled(Flex)`
	background: var(--surface-primary);
	height: 100%;
	width: 100%;

	> p {
		text-align: center;
	}
`;
