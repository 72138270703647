function Redux(filter?: string | string[]) {
	const objectString = JSON.parse(localStorage.getItem('persist:@PHC') || '{}');

	const uniqueFilter = (() => {
		if (Array.isArray(filter) && filter.length === 1) return filter[0];
		if (typeof filter === 'string') return filter;
		return false;
	})();

	const parsedValues = Object.entries(objectString).reduce((acc, [key, value]) => {
		if (filter && !filter.includes(key)) return acc;

		acc[key] = JSON.parse(value as string || '{}');

		return acc;
	}, {} as Record<string, any>);

	return uniqueFilter ? parsedValues[uniqueFilter] : parsedValues;
}

Redux.set = (reducer: string, payload: Record<string, unknown>) => {
	const newData = {
		...Redux(),
		[reducer]: {
			...Redux(reducer),
			...payload
		},
	};

	const stringifiedData = Object.entries(newData).reduce((acc, [key, value]) => {
		acc[key] = JSON.stringify(value);
		return acc;
	}, {} as Record<string, string>);

	localStorage.setItem('persist:@PHC', JSON.stringify(stringifiedData));

	window.location.reload();
}

Redux.replaceToken = (token = 'INVALID_TOKEN') => {
	Redux.set('customerReducer', { token });
	return Redux('customerReducer').token;
}

Redux.baskets = () => {
	return Redux('basketReducer').basket.baskets;
}

window.Redux = Redux;