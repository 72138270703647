import MissingDistributor from 'containers/Dialogs/MissingDistributor';
import { Typography } from 'front-commons/ds';
import { Link } from 'react-router-dom';

export const distributorsMissingDialog = {
	heading: {
		title: 'Você precisa escolher seus distribuidores',
		showCloseButton: true,
	},
	content: {
		description: '',
		children: <MissingDistributor />,
	},
	footer: {
		primaryButton: {
			label: 'Selecionar distribuidores',
		},
	},
};

export const sellerViewErrorNotification: React.FC = () => (
	<div>
		<Typography>
			Esta farmácia não está liberada para compra.{' '}
			<Link to="/perguntas-frequentes">
				<Typography
					style={{ textDecoration: 'underline' }}
					color="--semantic-info-text"
					variant="ParagraphSmall/Semibold"
				>
					Entre em contato conosco.
				</Typography>
			</Link>
		</Typography>
	</div>
);
