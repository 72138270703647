import * as Progress from '@radix-ui/react-progress';
import { getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';
import { AccordionProgressProps } from './interfaces';

export const ProgressContainer = styled.div<Pick<AccordionProgressProps, 'fullWidth'>>`
	display: flex;
	flex-direction: column;
	gap: ${getThemeSpace('4')};

	${({ fullWidth }) => fullWidth && 'width: 100%;'}
`;

export const ProgressContent = styled(Progress.Progress)<Pick<AccordionProgressProps, 'fullWidth'>>`
	position: relative;

	${({ fullWidth }) => !fullWidth && 'max-width: 228px;'}
	width: 100%;
	height: 4px;

	overflow: hidden;
	transform: translateZ(0);

	background: var(--border-primary);

	border-radius: 99999px;
`;

export const ProgressIndicator = styled(Progress.Indicator)`
	width: 100%;
	height: 100%;

	transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);

	background-color: var(--surface-accent-dark);

	border-radius: 5px;

	&[data-state='complete'] {
		background-color: var(--semantic-success-base);
	}
`;

export const ProgressText = styled.div`
	display: flex;

	align-items: center;
	gap: ${getThemeSpace('8')};

	& > div svg {
		border: 1.5px solid var(--semantic-success-base);
		border-radius: 50%;
	}
`;
