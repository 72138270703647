import { PolicyReducer } from './interfaces';

const initialState: PolicyReducer = {
	currentPolicySelected: 'ph',
	cookiesPolicies: {},
};

export default function policyReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_CURRENT_POLICY_SELECTED': {
			return { ...state, currentPolicySelected: payload.currentPolicySelected };
		}

		case 'SET_COOKIES_POLICIES': {
			return { ...state, cookiesPolicies: payload.cookiesPolicies };
		}

		default:
			return state;
	}
}
