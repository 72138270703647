import { Flex } from 'front-commons/ds';
import { LinkButton, ConflictDescription } from './styles';
import { ConflictMessageProps } from './interfaces';

export default function ConflictMessage({ conflictType, onOpenConflictedDrawer }: ConflictMessageProps) {
	return (
		<Flex backgroundColor="--surface-accent-light" padding="4px 16px" margin="-8px 0 0 0" width="100%">
			<ConflictDescription variant="Caption/Regular" align={conflictType === 'IN_BASKET' ? 'center' : 'left'}>
				{conflictType === 'IN_BASKET' && 'Este produto já está no seu carrinho. Remova-o para comprá-lo na promoção.'}

				{conflictType === 'IN_PROMOTION' && (
					<>
						Este produto já está no seu carrinho em uma{' '}
						<LinkButton removeBlurAfterClick variant="text" onClick={onOpenConflictedDrawer}>
							promoção.
						</LinkButton>{' '}
						Para comprá-lo com esta condição comercial, você deve removê-lo do carrinho.
					</>
				)}
			</ConflictDescription>
		</Flex>
	);
}
