import { DialogProps } from 'front-commons/ds/components/Dialog/interfaces';

export const setOpenDialog = (dialog: DialogProps) => ({
	type: 'SET_OPEN_DIALOG',
	payload: { dialog },
});

export const setCloseDialog = () => ({
	type: 'SET_CLOSE_DIALOG',
});
