import { GlassButtonContainer, Typography, notify } from 'front-commons/ds';
import { GlassButtonContainerProps } from 'front-commons/ds/components/GlassButtonContainer/interfaces';
import { useFetch } from 'front-commons/hooks';
import { useEffect, useState } from 'react';
import { BusinessUnitResponseEnum } from 'pages/pos/Distributors/interfaces';
import { getMassiveProcessingStatus, saveMassiveDistributors } from 'services/pos';
import { CTAProps } from './interfaces';

export default function CTA({
	type,
	activeTab,
	BUsToRender,
	selectedPos,
	ignoreOption,
	anotherBUInfos,
	setActiveTab,
	handleCloseDrawer,
	onFinalizeAttributing,
	setHasDistributorChanged,
}: CTAProps) {
	const [processing, setProcessing] = useState<false | 'error' | 'success' | 'processing'>(false);
	const { selected, businessUnitName } = BUsToRender[activeTab];
	const selectedDistributors = selected?.[BusinessUnitResponseEnum.Distributors];

	const handleChangeToNextBU = (skippingStep = false) => {
		setHasDistributorChanged?.(true);

		if (type === 'MASSIVE' && !skippingStep) {
			notify.positive({
				description: (
					<Typography>
						Suas alterações de <strong>{businessUnitName}</strong> foram salvas.
					</Typography>
				),
			});
		}

		if (anotherBUInfos) {
			setActiveTab(anotherBUInfos.id);
			document.getElementById('buTitle')?.scrollIntoView();
			return;
		}

		if (!skippingStep && type === 'MASSIVE') onFinalizeAttributing?.(selectedPos.map(({ posId }) => posId));
		handleCloseDrawer();
	};

	const { fetch: getProcessingStatus } = useFetch({
		fetchFunction: getMassiveProcessingStatus,
		recursive: true,
		recursiveTime: 1000,

		recursiveTill(response) {
			return response?.status === 'PROCESSED';
		},

		onRecursiveStops() {
			setProcessing('success');
		},

		onBeforeFetch() {
			setProcessing('processing');
		},

		onError() {
			notify.negative({ description: 'Erro ao buscar o status do processamento.' });
			setProcessing('error');
		},
	});

	const { fetch: fetchSaveDistributors, loading } = useFetch({
		fetchFunction: saveMassiveDistributors,
		onSuccess(response) {
			if (!response) return;

			getProcessingStatus({ loopingProcessingId: response.loopingProcessingId });
			setHasDistributorChanged?.(true)
		},

		onError() {
			notify.negative({ description: 'Erro ao salvar os distribuidores.' });
		},
	});

	const handleSaveCurrentSettingsToBU = () => {
		fetchSaveDistributors({
			businessUnitId: activeTab,
			pointOfSaleIdList: selectedPos.map(({ posId }) => posId),
			distributorList: selectedDistributors?.map((distributor, index) => ({
				distributorId: distributor.id,
				order: index + 1,
			})),
		});
	};

	const handleGetGlassButtonContainerProps = () => {
		const props: Partial<GlassButtonContainerProps> = {};

		if (ignoreOption && type === 'MASSIVE')
			props.leftButton = {
				children: anotherBUInfos ? 'Ignorar' : `Ignorar ${businessUnitName} e finalizar`,
				onClick: () => handleChangeToNextBU(true),
			};

		if (anotherBUInfos) {
			props.rightButton =
				type === 'SIMPLE'
					? { children: 'Próximo', onClick: () => handleChangeToNextBU() }
					: {
							children: `Salvar e avançar para ${anotherBUInfos.name}`,
							loading: loading || processing === 'processing',
							onClick: handleSaveCurrentSettingsToBU,
					  };
		}

		if (!anotherBUInfos) {
			props.rightButton =
				type === 'SIMPLE'
					? { children: 'Finalizar', onClick: () => handleChangeToNextBU() }
					: {
							children: 'Salvar e finalizar',
							loading: loading || processing === 'processing',
							onClick: handleSaveCurrentSettingsToBU,
					  };
		}

		return props;
	};

	useEffect(() => {
		if (processing === 'success') handleChangeToNextBU();
	}, [processing]);

	return <GlassButtonContainer {...handleGetGlassButtonContainerProps()} />;
}
