import { Button, Grid, Typography, notify } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import { useMediaQuery } from 'front-commons/hooks';
import { useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useNavigate } from 'react-router-dom';
import { getBasketSuggestion } from 'services/basket';
import useBasket from 'stores/basket';
import useCustomer from 'stores/customer';
import useDialog from 'stores/dialog';
import useDrawer from 'stores/drawer';
import usePos from 'stores/pos';
import BasketAccordion from './BasketAccordion';
import { BasketSkeleton } from './BasketSkeleton';
import EmptyBasket from './EmptyBasket';
import { BottomContainer, ContainerProducts, WrapperBasket } from './styles';
import TotalBasketPrice from './TotalBasketPrice';

export function SideBasket() {
	const { posStore } = usePos();
	const [loading, setLoading] = useState(false);
	const pos = posStore.selectedPos;
	const isMobile = useMediaQuery(`(min-width: ${theme.breakpoints.small})`);
	const dynamicHeight = use100vh();

	const {
		handleGetBasketData,

		basketCheckerHandler,

		basketStore: { basket: basketData, loading: loadingBasket },
	} = useBasket();

	const { handleOpenDialog } = useDialog();
	const navigate = useNavigate();
	const { handleCloseDrawer } = useDrawer();
	const { handleHasPermission } = useCustomer();

	const handleCloseBasketDrawer = () => {
		handleCloseDrawer('basket-drawer');
	};

	const getSuggestionStatus = async () => {
		setLoading(true);
		try {
			const response = await getBasketSuggestion(posStore.selectedPos.id, navigate);
			if ([200].includes(response.code)) {
				handleCloseBasketDrawer();
			}
			if ([204].includes(response.status)) {
				handleCloseBasketDrawer();
				navigate(`/revisar-pedido`);
			}
		} catch (err) {
			if (err === 409) {
				handleCloseBasketDrawer();
				navigate(`/revisar-pedido`);
			} else {
				notify.negative({ description: 'Ocorreu um erro ao revisar seu pedido.' });
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleGetBasketData({ loading: 'full' });
	}, [pos]);

	if (loadingBasket === 'full' || basketData === undefined)
		return (
			<>
				<ContainerProducts>
					{Array.from({ length: 3 }, () => '').map((_, index) => (
						<BasketSkeleton.Top key={index} />
					))}
				</ContainerProducts>

				<BasketSkeleton.Bottom />
			</>
		);

	if (!basketData.totalItems) return <EmptyBasket onClick={handleCloseBasketDrawer} />;

	return (
		<WrapperBasket safetyHeight={isMobile ? dynamicHeight : null}>
			<ContainerProducts>
				{basketData?.baskets?.map((basket, index) => (
					<BasketAccordion
						key={index}
						basket={basket}
						isChecked={basketCheckerHandler.isChecked(basket.id)}
						selectedPos={pos}
						totalPrice={basketData?.finalTotalPrice}
					/>
				))}
			</ContainerProducts>

			<BottomContainer>
				<TotalBasketPrice product={basketData} />

				<Typography variant="ParagraphSmall/Regular">
					O preço pode sofrer alteração por impostos praticados pelos distribuidores
				</Typography>

				<Grid columns="1fr 1fr" gap="16px">
					<Button
						isFullWidth
						onClick={() =>
							handleOpenDialog({
								heading: { title: 'Limpar carrinho', showCloseButton: true },
								content: { description: 'Deseja realmente limpar o carrinho?' },
								footer: {
									primaryButton: { label: 'Não' },
									secondaryButton: {
										label: 'Sim',
										onClick: () => basketCheckerHandler.handleClearSelectedBaskets(true),
									},
								},
							})
						}
						variant="secondary"
						hasPermission={handleHasPermission('BASKET_EDIT')}
					>
						Limpar carrinho
					</Button>
					<Button onClick={getSuggestionStatus} disabled={loading} isFullWidth>
						Revisar pedido
					</Button>
				</Grid>
			</BottomContainer>
		</WrapperBasket>
	);
}
