import { Flex, getThemeMedia } from 'front-commons/ds';
import styled, { css } from 'styled-components';

export const AvailableDropContainer = styled(Flex)<{ animate: boolean }>`
	margin-top: 0px;

	transition: all 300ms ease-in-out;

	@media screen {
		@media (max-width: ${getThemeMedia('medium')}) {
			${({ animate }) =>
				animate &&
				css`
					margin-top: 88px;
				`}
		}
	}
`;

export const SelectedCardsWrapper = styled.div<{ isPed: boolean }>`
	display: flex;
	flex-direction: column;
	position: relative;

	z-index: 2;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			min-height: ${({ isPed }) => (isPed ? '140px' : '456px')};
		}
	}

	[data-testid='distributor-card-selected'] {
		margin: 8px 0;

		@media screen {
			@media (min-width: ${getThemeMedia('medium')}) {
				margin: 12px 0;
			}
		}
	}

	[data-testid='distributor-card-selected']:first-child {
		margin-top: 0;
	}

	[data-testid='distributor-card-selected']:last-child {
		margin-bottom: 0;
	}
`;
