import {
	getThemeFont,
	getThemeFontSize,
	getThemeFontWeight,
	getThemeMedia,
	getThemeShadow,
	getThemeSpace,
} from 'front-commons/ds';
import styled, { css } from 'styled-components';

export const WrapperPromotionCard = styled.div<{ hasAddedInBasket: boolean }>`
	display: flex;
	flex-direction: column;
	gap: ${getThemeSpace('32')};
	background: ${({ hasAddedInBasket }) => (hasAddedInBasket ? 'var(--surface-accent-light)' : 'transparent')};
	margin: 0 -${getThemeSpace('24')} ${getThemeSpace('16')};
	padding: ${getThemeSpace('16')} ${getThemeSpace('24')};

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			gap: ${getThemeSpace('24')};
			margin: 0 -${getThemeSpace('32')};
			padding: ${getThemeSpace('16')} ${getThemeSpace('32')};
		}
	}
`;

export const InfoDistributor = styled.div`
	display: flex;
	gap: ${getThemeSpace('16')};
`;

export const BoxImage = styled.figure`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 80px;
	height: 80px;

	img {
		max-width: 80px;
		max-height: 80px;
	}
`;

export const PromotionCardProductsThumbnails = styled.div<{ thumbsLength?: number }>`
	display: flex;
	gap: ${getThemeSpace('4')};
	position: relative;

	margin: ${getThemeSpace('16')} 0;

	& > div:not(div:nth-child(1), div:nth-child(2)) {
		display: none;
	}

	${({ thumbsLength }) =>
		thumbsLength &&
		thumbsLength > 3 &&
		css`
			&::before {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 50%;
				left: calc(((80px + 4px) * 2) - 24px);
				transform: translateY(-50%);

				z-index: 1;

				border-radius: 40px;
				background: var(--surface-accent-dark);

				width: 35px;
				height: 35px;

				color: var(--text-invert);
				font: ${getThemeFontWeight('bold')} ${getThemeFontSize(thumbsLength >= 100 ? '14' : '16')}
					${getThemeFont('sourceSansPro')};
				content: '+ ${thumbsLength - 2}';
				white-space: nowrap;
			}
		`}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			& > div {
				&:nth-child(3) {
					display: flex !important;
				}

				&:not(div:nth-child(1), div:nth-child(2), div:nth-child(3)) {
					display: none;
				}
			}

			${({ thumbsLength }) =>
		thumbsLength &&
		thumbsLength > 3 &&
		css`
					&::before {
						left: auto;
						right: -16px;

						width: 40px;
						height: 40px;

						font: ${getThemeFontWeight('bold')} ${getThemeFontSize(thumbsLength >= 100 ? '16' : '18')}
							${getThemeFont('sourceSansPro')};

						content: '+ ${thumbsLength - 3}';
					}
				`}
		}
	}
`;

export const PromotionCardProductsImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 8px;
	box-shadow: ${getThemeShadow('1')};

	width: 80px;
	height: 80px;

	img {
		max-width: 80px;
		max-height: 80px;
	}
`;

export const BoxPrice = styled.div`
	display: flex;
	flex-direction: column;

	.final_price {
		flex-wrap: wrap;
	}
`;

export const BoxPriceInfo = styled.div`
	display: flex;
	align-items: center;
	gap: ${getThemeSpace('4')};

	white-space: nowrap;
`;

export const Separator = styled.div`
	height: 2px;
	width: 100%;
	background-color: var(--text-primary);
`;
