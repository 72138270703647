import type { DialogReducer } from './interfaces';

const initialState: DialogReducer = {
	dialog: undefined,
};

export default function dialogReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_OPEN_DIALOG': {
			return { ...state, dialog: payload.dialog };
		}

		case 'SET_CLOSE_DIALOG': {
			return { ...state, dialog: undefined };
		}

		default:
			return state;
	}
}
