import { PolicyReducer } from './interfaces';

export const setCurrentPolicySelected = (currentPolicySelected: PolicyReducer['currentPolicySelected']) => ({
	type: 'SET_CURRENT_POLICY_SELECTED',
	payload: { currentPolicySelected },
});

export const setCookiesPolicies = (cookiesPolicies: Required<PolicyReducer['cookiesPolicies']>) => ({
	type: 'SET_COOKIES_POLICIES',
	payload: { cookiesPolicies },
});
