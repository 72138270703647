import { Button, Flex, Grid, Typography } from 'front-commons/ds';
import { onEnvironment } from 'shared/environment';
import useDialog from 'stores/dialog';
import useFeatures from 'stores/features';
import FeatureContent from './FeatureContent';
import { FooterLinksProps } from './interfaces';
import { ItemList } from './styles';

export default function FooterLinks({ customBgColor, props: items }: FooterLinksProps) {
	const { handleOpenDialog } = useDialog();

	const { handleResetFeatures } = useFeatures();

	const handleClick = () => {
		handleOpenDialog({
			heading: {
				title: 'Feature Flags',
				showCloseButton: true,
			},
			content: {
				description: (
					<Flex direction="column" gap="8px">
						<Typography>
							Atenção: As alterações realizadas abaixo são experimentais, pois acontecem apenas no seu navegador.
							Comportamentos relacionados com o backend estarão passiveis de erros. Chaves em negrito laranja foram
							alteradas.
						</Typography>
						<Flex style={{ color: 'orange' }} as={Typography} variant="Paragraph/Semibold">
							Chaves em negrito laranja foram alteradas.
						</Flex>
					</Flex>
				),
				children: <FeatureContent />,
			},
			footer: {
				primaryButton: {
					label: 'Feito',
				},
				secondaryButton: {
					label: 'Resetar padrões',
					onClick: handleResetFeatures,
				},
			},
		});
	};

	return (
		<>
			{items.map((item, index) => (
				<Grid key={index} gap="16px">
					<Typography variant="Headline/H2 Bold" color="--text-invert" as="span">
						{item.title}
					</Typography>
					<Grid as="ul" gap="16px">
						{item.content.map((itemData, i) => (
							<ItemList key={i}>
								<a
									id={itemData?.gtm?.id}
									href={itemData.link}
									target={itemData.isTarget ? '_blank' : '_self'}
									rel="noreferrer"
								>
									<Typography color="--text-invert" as="span">
										{itemData.text}
									</Typography>
								</a>
							</ItemList>
						))}
					</Grid>
				</Grid>
			))}
			{onEnvironment(['development', 'homolog']) && (
				<Flex position="absolute" left="0" bottom="0" style={{ overflow: 'hidden' }}>
					<Button
						onClick={handleClick}
						variant="text"
						size="small"
						style={{ cursor: 'help', color: customBgColor || '#055187' }}
					>
						<span>Feature menu</span>
					</Button>
				</Flex>
			)}
		</>
	);
}
