import { Dispatch } from '@reduxjs/toolkit';
import {
	setCloseAllDrawers,
	setCloseDrawer,
	setDynamicSubtitle,
	setDynamicTitle,
	setOpenDrawer,
	setPrepareCloseDrawer,
	setUpdateDrawerProp,
} from './actions';
import { DrawersId, RegisteredDrawerParams } from './interfaces';
import { getStore } from 'stores/helpers';

export const handleSetCloseDrawer = (drawerId: DrawersId): any => {
	return async (dispatch: Dispatch) => {
		dispatch(setPrepareCloseDrawer(drawerId));
		await Promise.fake({ delay: 400 }); // To animate before remove from the DOM
		dispatch(setCloseDrawer({ id: drawerId }));
	};
};

export const handleSetCloseAllDrawers = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(setCloseAllDrawers());
	};
};

export const handleSetOpenDrawer = <T>(drawerId: DrawersId, data?: T): any => {
	return (dispatch: Dispatch) => {
		const shouldSetOnGoBack = getStore().drawerReducer.drawers.length > 1;
		const onGoBack = shouldSetOnGoBack ? () => dispatch(handleSetCloseDrawer(drawerId)) : undefined;

		dispatch(setOpenDrawer<T>(drawerId, { data, onGoBack }));
	};
};

export const handleSetPrepareCloseDrawer = (drawerId: DrawersId): any => {
	return (dispatch: Dispatch) => {
		dispatch(setPrepareCloseDrawer(drawerId));
	};
};

export const handleSetDynamicTitle = (drawerId: DrawersId, title: React.ReactNode): any => {
	return (dispatch: Dispatch) => {
		dispatch(setDynamicTitle(drawerId, title));
	};
};

export const handleSetDynamicSubtitle = (drawerId: DrawersId, text: string): any => {
	return (dispatch: Dispatch) => {
		dispatch(setDynamicSubtitle(drawerId, text));
	};
};

export const handleSetUpdateDrawerProp = (
	drawerId: DrawersId,
	props: Partial<Omit<RegisteredDrawerParams, 'id'>>,
): any => {
	return (dispatch: Dispatch) => {
		dispatch(setUpdateDrawerProp(drawerId, props));
	};
};
