import { Alert, Button, Flex, Grid, Input, Typography, notify } from 'front-commons/ds';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DistributorsConflicts from 'containers/Dialogs/DistributorsConflicts';
import DistributorsDragAndDrop from 'containers/DragAndDrop/DistributorsDragAndDrop';
import { ColumnsProps, DistributorsCallToActionProps } from 'containers/DragAndDrop/DistributorsDragAndDrop/interfaces';
import { BusinessUnitResponse, BusinessUnitResponseEnum } from 'pages/pos/Distributors/interfaces';
import { deleteDistributors, orderOrAddDistributors } from 'services/pos';
import { updateLoopingProviderGTM } from 'shared/gtm';
import useDialog from 'stores/dialog';
import usePos from 'stores/pos';
import {
	buTipMessage,
	handleAddDistributor,
	handleRemoveDistributor,
	handleReorderDistributor,
	handleRePositionDistributor,
} from './helpers';
import { CardsRenderProps } from './interfaces';

export default function DistributorsTabs({
	BUId,
	type = 'SIMPLE',
	posList,
	selected,
	isDrawer,
	available,
	CustomCTA,
	conflicts,
	pharmacyId,
	anotherBUInfos,
	lastDistributorConfigSaved,
	setSelected,
	setActiveTab,
	handleLoading,
	onAfterSimpleAdd,
	onAfterSimpleRemove,
	validateDistributors,
	setLastDistributorConfigSaved,
	handleRefetchSelectedDistributors,
}: CardsRenderProps) {
	const navigate = useNavigate();
	const { handleSelectPos, posStore, handleUpdateDistributor } = usePos();
	const { handleOpenDialog } = useDialog();

	const [filter, setFilter] = useState('');

	const isPED = !!available[BusinessUnitResponseEnum.IsPED];

	const handleFilter = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(value);
	};

	const handleAdd = useCallback(
		(distributorId: string, indexPosition?: number) => {
			const temp: BusinessUnitResponse['distributors'] = [];
			const currentDistributor = available.distributors.find((distributor) => distributor.id === distributorId);

			setSelected((prevState) => handleAddDistributor(prevState, available, BUId, distributorId, indexPosition, temp));

			if (type === 'MASSIVE') return;

			setTimeout(async () => {
				try {
					await orderOrAddDistributors({
						id: pharmacyId,
						data: {
							businessUnitId: BUId,
							distributors: temp.map(({ order, id: distId }) => ({ distributorId: distId, order })),
						},
					});

					onAfterSimpleAdd?.();

					updateLoopingProviderGTM('add_looping_provider', {
						distributorName: currentDistributor?.summarizedCorporateName || '',
						minimumValue: currentDistributor?.minimumValue || 0,
						businessUnitName: available.businessUnitName,
						index: indexPosition,
					});
				} catch (error) {
					notify.negative({ description: (error as any).data.errorDescription });
					handleRefetchSelectedDistributors();
				}
			}, 400);
		},
		[type, BUId],
	);

	const handleRemove = useCallback(
		(id: string) => {
			setSelected((prevState) => handleRemoveDistributor(prevState, BUId, id));

			if (type === 'MASSIVE') return;

			const currentDistributorIndex = selected?.distributors.findIndex((distributor) => distributor.id === id);

			setTimeout(async () => {
				try {
					await deleteDistributors({
						id: pharmacyId,
						distributors: {
							businessUnitId: BUId,
							distributorId: id,
						},
					});

					onAfterSimpleRemove?.(selected);

					if (typeof currentDistributorIndex === 'number') {
						updateLoopingProviderGTM('remove_looping_provider', {
							distributorName: selected?.distributors[currentDistributorIndex]?.summarizedCorporateName || '',
							minimumValue: selected?.distributors[currentDistributorIndex]?.minimumValue || 0,
							businessUnitName: available.businessUnitName,
							index: currentDistributorIndex,
						});
					}

					setSelected((prevState) => handleRePositionDistributor(prevState, BUId));
				} catch (error) {
					notify.negative({ description: (error as any).data.errorDescription });
					handleRefetchSelectedDistributors();
				}
			}, 400);
		},
		[type, BUId, selected],
	);

	const handleRedirectToCatalogWithOpenedSideCart = () => {
		const haveToChangePos = posStore.selectedPos.id !== pharmacyId;

		if (haveToChangePos) {
			handleSelectPos(pharmacyId, true, true);
			return;
		}

		navigate('/', { state: { openSideBasket: true } });
	};

	const handleOpenRemoveModal = async (distributorId: string) => {
		if (type === 'MASSIVE') {
			handleRemove(distributorId);
			return;
		}

		try {
			await handleOpenDialog({
				heading: {
					title: 'Deseja continuar?',
				},
				content: {
					description:
						'Atenção! Caso tenha algum pedido em seu carrinho do(s) distribuidor(es) alterado(s), o mesmo será excluído. Deseja continuar?',
				},
				footer: {
					primaryButton: {
						label: 'Ver carrinho',
						onClick: handleRedirectToCatalogWithOpenedSideCart,
					},
					secondaryButton: {
						label: 'Confirmar',
						onClick: () => {
							if (distributorId) handleRemove(distributorId);
						},
					},
				},
			});
		} catch {
			console.log('não alterado');
		}
	};

	const handleReorder = useCallback(
		async (from: number, to: number) => {
			const temp: BusinessUnitResponse['distributors'] = [];

			setSelected((prevState) => handleReorderDistributor(prevState, BUId, from, to, temp));

			if (type === 'MASSIVE') return;

			setTimeout(async () => {
				try {
					await orderOrAddDistributors({
						id: pharmacyId,
						data: {
							businessUnitId: BUId,
							distributors: temp.map(({ order, id: distributorId }) => ({ distributorId, order })),
						},
					});
				} catch (error) {
					notify.negative({ description: (error as any).data.errorDescription });
					handleRefetchSelectedDistributors();
				}
			}, 400);
		},
		[BUId, handleRefetchSelectedDistributors, pharmacyId, setSelected, type],
	);

	const handleClickMakeOrder = () => {
		const isValidDistributors = validateDistributors();

		if (isValidDistributors) {
			handleSelectPos(pharmacyId, true);
			return;
		}

		handleOpenDialog({
			heading: {
				title: 'Você precisa escolher seus distribuidores',
				showCloseButton: true,
			},
			content: {
				description:
					'Para consultar os preços dos produtos você precisará selecionar ao menos um distribuidor para todas as divisões às quais você tem acesso. Ao sair dessa página, as ações feitas serão salvas e você poderá retornar para a etapa de seleção de distribuidores.',
			},
			footer: {
				primaryButton: {
					label: 'Salvar e sair da página',
					onClick: () => navigate('/minhas-farmacias'),
				},
			},
		});
	};

	const handleOpenConflicts = () => {
		handleOpenDialog({
			heading: {
				title: 'PDVs que o looping não será aplicado',
				showCloseButton: true,
			},
			content: {
				description: <DistributorsConflicts buName={available.businessUnitName} conflicts={conflicts} />,
			},
			footer: {
				primaryButton: {
					label: 'Ok, fechar',
				},
			},
		});
	};

	const hasSelectedDistributorsChanged =
		JSON.stringify(lastDistributorConfigSaved?.[BUId] || []) !==
		JSON.stringify(selected?.[BusinessUnitResponseEnum.Distributors]);

	const callToActionProps: DistributorsCallToActionProps = {
		type,
		canBuy: !!selected?.distributors.length && !!available.distributors.length,
		currentBUName: available.businessUnitName,
		anotherBUInfos,
		loadingPharmacy: posStore.loading,
		hasSelectedDistributorsChanged,
		setActiveTab,
		handleClickMakeOrder,
		setLastDistributorConfigSaved,
	};

	const columnsProps: ColumnsProps = {
		isDrawer,
		availableProps: {
			isPed: isPED,
			filter,
			selected,
			available,
			CustomCTA,
			pharmacyId,
			callToActionProps,
			hideMinValue: type === 'MASSIVE',
			handleAdd,
		},
		selectedProps: {
			buId: available[BusinessUnitResponseEnum.BusinessUnitId],
			isPed: isPED,
			posList,
			CustomCTA,
			pharmacyId,
			hideMinValue: type === 'MASSIVE',
			businessUnitName: available.businessUnitName,
			callToActionProps,
			selectedDistributors: selected,
			onRemove: handleOpenRemoveModal,
			onReorder: handleReorder,
			handleLoading,
		},
		onAdd: handleAdd,
		onReorder: handleReorder,
		onRemove: isDrawer ? handleRemove : handleOpenRemoveModal,
	};

	useEffect(() => {
		if (pharmacyId === posStore.selectedPos.id && selected) handleUpdateDistributor(selected);
	}, [selected]);

	return (
		<Flex direction="column" gap="48px">
			<Flex direction="column" alignItems={type === 'MASSIVE' ? 'start' : 'center'} gap="16px">
				{isDrawer && <Flex alignSelf="start" as={Typography} variant="Headline/H2 Bold" id="buTitle">
					{available.businessUnitName}
				</Flex>}

				<Flex alignSelf="start" as={Typography}>
					{isPED
						? 'Agora você pode comprar pelo Parceiro Hypera aproveitando as ofertas do seu distribuidor exclusivo!'
						: buTipMessage[available.businessUnitName]}
				</Flex>

				{!!Object.keys(conflicts || {}).length && !!available.distributors.length && (
					<Alert type="info">
						<Flex alignItems="center">
							<Typography>
								<strong>A configuração do looping de marcas não será aplicada para alguns PDVs da sua seleção.</strong>{' '}
								Confira ao lado por que isso aconteceu.
							</Typography>

							<Button variant="secondary" onClick={handleOpenConflicts}>
								Visualizar conflitos
							</Button>
						</Flex>
					</Alert>
				)}

				{hasSelectedDistributorsChanged && type === 'MASSIVE' && !isDrawer && (
					<Alert type="warn">As alterações realizadas no seu looping ainda não foram salvas.</Alert>
				)}

				{!available.distributors.length && type === 'MASSIVE' && (
					<Alert type="warn">
						<strong>Não foram encontrados distribuidores que atendam ao filtro selecionado.</strong> Por favor revise a
						seleção de UF e Farmácias.
					</Alert>
				)}

				{!isPED && type === 'SIMPLE' && (
					<Grid maxWidth="537px">
						<Input
							name="search"
							onChange={handleFilter}
							value={filter}
							type="search"
							placeholder="Buscar distribuidor"
							disabled={handleLoading().get(['DISTRIBUTORS'])}
						/>
					</Grid>
				)}
			</Flex>
			{(!!available.distributors.length || type === 'SIMPLE') && <DistributorsDragAndDrop {...columnsProps} />}
		</Flex>
	);
}
