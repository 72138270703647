import { Typography } from 'front-commons/ds';
import { SimulatedContainer } from './styles';

export default function SimulatedBanner() {
	return (
		<SimulatedContainer data-testid="simulated-banner">
			<Typography color="--text-invert">
				<img
					src="https://hyperaadminprod.blob.core.windows.net/site-images/silumated-user_locker.svg"
					alt="Ícone de cadeado"
				/>
				<div>
					<strong>Ambiente simulado:</strong> essa é apenas uma cópia
					<Typography display={{ small: 'none', medium: 'inline' }} color="--text-invert" as="span">
						da navegação da farmácia
					</Typography>
					.
				</div>
			</Typography>
		</SimulatedContainer>
	);
}
