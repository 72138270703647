import { getStore } from 'stores/helpers';
import { FindPharmaciesResponse } from './interfaces';
import { findPharmacy } from '.';

export const getSimulatedPosListManage = async (): Promise<FindPharmaciesResponse[]> => {
	const posList = getStore().posReducer.myPos;

	const {
		cnpj = '',
		fidelityStatus = '' as any,
		pointOfSaleId = '',
		tradeName = '',
	} = posList.find((pos) => pos.isSelected) || {};

	const posOption = {
		pointOfSaleId,
		tradeName,
		cnpj,
		corporateName: tradeName,
		fidelityStatus,
		pointOfSaleAddress: {} as any,
	};

	const posDetail = (await findPharmacy({ cnpj: cnpj || '' }).then((res) => res)) || {
		corporateName: '',
		pointOfSaleAddress: {} as any,
	};

	const { corporateName, pointOfSaleAddress } = posDetail;

	return [
		{
			...posOption,
			...(posDetail ? { corporateName, pointOfSaleAddress } : {}),
		},
	];
};
