import { Table } from 'front-commons/ds';
import styled from 'styled-components';

export const UnavailablePromotionProductsTableContainer = styled(Table)`
	span {
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 120px;
	}
`;
