import { ErrorPageProps } from './interface';
import Error from '.';

export default function Error500({ isGlobalBoundary }: Pick<ErrorPageProps, 'isGlobalBoundary'>) {
	return (
		<Error
			showReload
			errorType="500"
			isGlobalBoundary={isGlobalBoundary}
			subtitle="Isto não deveria acontecer..."
			description={
				<>
					Pedimos desculpas pela falha interna. Tente novamente daqui alguns minutos.
					<br />
					Se o problema persistir e você precisar de ajuda em casos urgentes, por favor, entre em contato nos seguintes
					números:
				</>
			}
		/>
	);
}
