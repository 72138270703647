import styled from 'styled-components';

export const SimulatedContainer = styled.div`
	display: flex;
	align-items: center;

	background-color: var(--surface-accent-dark);
	padding: 0 16px;

	height: 30px;

	img {
		height: 20px;
	}

	p {
		display: flex;
		gap: 8px;

		margin: 0 auto;

		max-width: 1216px;
		width: 100%;

		white-space: nowrap;

		.icon {
			position: relative;
			z-index: 2;

			color: transparent;
			text-shadow: 0 0 var(--border-active), -1px 0px var(--border-primary), 1px 0px var(--border-primary),
				0 1px var(--border-primary), 0 -1px var(--border-primary);
		}
	}
`;
