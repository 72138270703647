import { Summary } from 'contexts/Promotion/interfaces';
import { DiscountRange } from 'services/products/interfaces';
import { getSummaryBrutePrice, getSummaryQuantities } from 'shared/promotionDrawer';
import { GetCurrentRangeProps } from './interfaces';

export const getInitialQuantity = (
	fixedAmount: boolean,
	minimumQuantity: number | undefined,
	flexBasketQuantity: number | undefined,
	productQuantity: number,
	hasValidDistributors: boolean,
	actualQuantity?: number,
) => {
	if (!hasValidDistributors) return 0;
	if (fixedAmount) return minimumQuantity || 1;
	if (actualQuantity === 0 || !actualQuantity) return flexBasketQuantity || productQuantity;
	if (actualQuantity !== flexBasketQuantity) return actualQuantity || 0;
	return flexBasketQuantity || productQuantity;
};

const getRangeValues = (rangesRef: DiscountRange[], target: number) => {
	const rateDiscountDefault = {
		discount: 0,
		taxSubstitution: 0,
	};

	const rateDiscountData = rangesRef.find(({ from, to }) => {
		if (to) return target >= from && target <= to;

		return target >= from;
	});

	return rateDiscountData || rateDiscountDefault;
};

export const getCurrentRange = ({
	ranges,
	updatedProductQuantity,
	totalPrice,
	mixedRanges,
	productPrices,
	rangeByQuantity,
	allProductQuantities,
	quantityInBasket,
	progressiveDiscount,
	hasRangeProduct,
	progressiveDiscountProductMix,
}: GetCurrentRangeProps) => {
	if (ranges?.length === 1 && !mixedRanges?.length) {
		const firstRange = ranges[0];

		return {
			discount: firstRange.discount,
			taxSubstitution: firstRange.taxSubstitution,
		};
	}

	if (progressiveDiscountProductMix && hasRangeProduct && ranges)
		return getRangeValues(ranges, rangeByQuantity ? allProductQuantities : productPrices);

	if (mixedRanges?.length) {
		if (!rangeByQuantity) return getRangeValues(mixedRanges, productPrices);

		return getRangeValues(mixedRanges, progressiveDiscount ? quantityInBasket : allProductQuantities);
	}

	return getRangeValues(ranges || [], rangeByQuantity ? updatedProductQuantity : totalPrice);
};

export const getCurrentRangeProgressive = (
	rangeByQuantity: boolean,
	progressiveDiscount: boolean,
	summary: Summary[],
	quantity: number,
	price: number,
	currentQuantityInBasket?: number,
) => {
	if (progressiveDiscount) {
		if (rangeByQuantity) {
			return quantity;
		}

		return price * quantity;
	}

	return rangeByQuantity
		? getSummaryQuantities(summary) * (currentQuantityInBasket || 1)
		: getSummaryBrutePrice(summary) * (currentQuantityInBasket || 1);
};

export const getQuantityByDiscount = (range: DiscountRange, rangeByQuantity: boolean, price: number) => {
	if (rangeByQuantity) {
		return range.from;
	}

	return Math.ceil(range.from / price);
};
