import { notify } from 'front-commons/ds';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LoadingType } from 'containers/Drawers/PromotionDetailDrawerContent/interfaces';
import { getPromotionInfo } from 'services/products';
import { DistributorsProps, GetPromotionInfoResponse } from 'services/products/interfaces';
import { getDiscountPrice } from 'shared/promotionDrawer';
import useBasket from 'stores/basket';
import useDrawer from 'stores/drawer';
import usePos from 'stores/pos';
import { PromotionContextProps, ShowDrawerByPromotionIdParams, Summary } from './interfaces';

const PromotionContext = createContext({} as PromotionContextProps);

export default function usePromotionContext() {
	return useContext(PromotionContext);
}

export function PromotionProvider({ children }: { children: React.ReactNode }) {
	const { posStore } = usePos();
	const pos = posStore.selectedPos;
	const {
		basketStore: { basket: basketData },
	} = useBasket();
	const { handleOpenDrawer } = useDrawer();

	const [data, setData] = useState({} as GetPromotionInfoResponse);
	const [loading, setLoading] = useState<LoadingType>('full');
	const [summary, setSummary] = useState<Summary[]>([]);
	const [isOpenPromotionDetails, setIsPromotionDetails] = useState(false);
	const [selectedDistributor, setSelectedDistributor] = useState({} as DistributorsProps);

	const productsInComboPrices = useMemo(
		() =>
			data?.productsInCombo?.map(({ productId, distributorsPrice }) => ({
				productId,
				price:
					distributorsPrice.find(({ distributorId }) => distributorId === selectedDistributor?.distributorId)?.price ||
					0,
				valid:
					distributorsPrice.find(({ distributorId }) => distributorId === selectedDistributor?.distributorId)?.valid ||
					true,
			})) || [],
		[data, selectedDistributor],
	);

	const getProductValidity = useCallback(
		(productId: string) => {
			const valid = data?.productsInCombo
				?.find((p) => p.productId === productId)
				?.distributorsPrice.find((i) => i.distributorId === selectedDistributor?.distributorId)?.valid;

			return valid;
		},
		[data, selectedDistributor],
	);

	const closePromotionDrawer = () => {
		setIsPromotionDetails(false);
		setTimeout(() => {
			setSelectedDistributor({} as DistributorsProps);
			setData({} as GetPromotionInfoResponse);
			setSummary([]);
		}, 500);
	};

	const updateSummary = useCallback(
		(productSummary: Summary) => {
			setSummary((prevState) => [
				...(prevState.filter?.(({ productId }) => productId !== productSummary.productId) || []),
				productSummary,
			]);
		},
		[setSummary],
	);

	const showDrawerByUnavailablePromotionId = useCallback(
		async ({ promotionId, posId, handleRefetchItemList }: ShowDrawerByPromotionIdParams) => {
			const pharmacyId = posId || pos?.id;

			if (!pharmacyId) return;

			try {
				handleOpenDrawer('unavailable-promotion-details-drawer', {
					promotionId,
					onClose: () => {
						closePromotionDrawer();
					},
					posId: pharmacyId,
					handleRefetchItemList,
				});

				setLoading('full');

				const promotionInfo = await getPromotionInfo(pharmacyId, promotionId);

				setData(promotionInfo);
			} catch (error) {
				closePromotionDrawer();
				notify.negative({ description: 'Promoção não encontrada' });
			} finally {
				setLoading('');
			}
		},
		[pos],
	);

	const getProductInPromotionValues = useCallback(
		(quantityImportPromotion?: number) =>
			summary.reduce(
				({ brute, discount, liquid, tax }, curr) => {
					const quantityInBasket =
						quantityImportPromotion ||
						basketData?.baskets?.flatMap(({ products }) => products).find(({ id }) => id === data?.id)?.quantity ||
						1;

					const totalBrute = curr.quantity * curr.price * quantityInBasket;
					const totalDiscount = curr.quantity * getDiscountPrice(curr.discount, curr.price) * quantityInBasket; // not rounded
					const totalTax = curr.price ? curr.quantity * curr.tax * quantityInBasket : 0;

					return {
						discount: totalBrute - totalDiscount + discount,
						tax: totalTax + tax,
						brute: totalBrute + brute,
						liquid: totalDiscount + totalTax + liquid,
					};
				},
				{
					discount: 0,
					tax: 0,
					brute: 0,
					liquid: 0,
				},
			),
		[summary, basketData],
	);

	const getFlexQuantityInBasket = useCallback(
		(productId: string, promotionId: string) => {
			const allPromotionsInBasket = basketData?.baskets
				?.flatMap(({ products }) => products)
				.filter((product) => product.productType === 'COMBO');

			const foundedPromotion = allPromotionsInBasket?.find((promotion) => promotion.id === promotionId);
			const foundedProduct = foundedPromotion?.productsInCombo?.find((product) => product.productId === productId);

			return foundedProduct?.quantity;
		},
		[basketData],
	);

	useEffect(() => {
		if (
			!Object.keys(selectedDistributor || {}).length &&
			window.location.pathname.includes('/importacao-pedido/customizacao-de-pedido')
		) {
			setSelectedDistributor(data.distributors?.[0] as DistributorsProps);
		}
	}, [data.id, data.distributors]);

	useEffect(() => {
		setSummary([]);
	}, [isOpenPromotionDetails]);

	return (
		<PromotionContext.Provider
			value={{
				data,
				loading,
				summary,
				updateSummary,
				getProductInPromotionValues,
				closePromotionDrawer,
				selectedDistributor,
				showDrawerByUnavailablePromotionId,
				productsInComboPrices,
				isOpenPromotionDetails,
				setSelectedDistributor,
				getFlexQuantityInBasket,
				getProductValidity,
			}}
		>
			{children}
		</PromotionContext.Provider>
	);
}
