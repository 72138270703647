import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { notify } from 'front-commons/ds';
import { handleSetLogout } from 'stores/customer/middleware';
import { getStore } from 'stores/helpers';
import { store } from 'stores/store';
import { CreateAPIConnectionCbProps, RequestHandlerOptionsParams, ResponseParams } from './interfaces';
import versions from '../version';

const getGAHeaders = () => {
	if (!JSON.parse(import.meta.env.VITE_GA_HEADERS_SEND || 'false')) return null;

	try {
		// @ts-expect-error
		const getCookie = (regex: RegExp) => document?.cookie.match(regex)[1].split('.');

		const [[ga_session_id], [ga_client_id]] = [
			getCookie(/_ga_\w+=GS\d\.\d\.(.+?)(?:;|$)/),
			getCookie(/_ga=GA\d\.\d\.(.+?)(?:;|$)/),
		];

		return { ga_session_id, ga_client_id };
	} catch {
		return null;
	}
};

export const handleRequest = (
	config: InternalAxiosRequestConfig<any>,
	callback?: CreateAPIConnectionCbProps['onRequest'],
): any => {
	const gaHeaders = getGAHeaders();
	const Authorization = getStore().customerReducer.token;

	Object.assign(config?.headers || {}, { 'X-Front-Version': versions.frontVersion || 'not-set' });
	if (Authorization) Object.assign(config?.headers || {}, { Authorization });
	if (gaHeaders) Object.assign(config?.headers || {}, { ...gaHeaders });

	const callbackConfig = callback?.(config) || {};

	return { ...config, ...callbackConfig };
};

export const handleReject = ({ response }: any) => {
	const handleUnauthorizeUser = ({ alert = false } = {}) => {
		if (alert) {
			notify.negative({ description: 'Sessão expirada! Por favor, faça login novamente para continuar.' });
		}
		store.dispatch(handleSetLogout());
	};

	if (response.status >= 400 && response.status < 500) {
		if (response.status === 401 || response.data.error === 'Denied') handleUnauthorizeUser();
		if (
			response.status === 403 &&
			response.data.error === 'Acesso negado.' &&
			getStore().customerReducer.data.accessType
		) {
			notify.negative({ description: 'Esse é um usuário simulado. Você não pode realizar essa ação.' });
		}

		throw new AxiosError('axios error', 'error', {} as any, {}, response);
	}

	if (response.status === 500 && response.data.error === 'Denied') handleUnauthorizeUser({ alert: true });

	notify.negative({ description: 'Ocorreu um erro com a requisição.' });
	throw new AxiosError('axios error', 'error', {} as any, {}, response);
};

export async function requestHandler<T = void>(
	request: Promise<AxiosResponse<ResponseParams<T>, any>>,
	options?: RequestHandlerOptionsParams,
) {
	const { throwData } = options || {};

	try {
		const response = await request;
		return response?.data?.content;
	} catch (error) {
		if (error instanceof AxiosError) {
			if (throwData) {
				throw (error as any)?.response.data;
			}

			throw (error as any)?.response?.data?.status;
		}

		throw error;
	}
}
