import * as RadixAccordion from '@radix-ui/react-accordion';
import { Flex, Icon, Typography } from 'front-commons/ds';
import ValuesSummary from 'containers/Summaries/ValuesSummary';
import { getValuesSummary } from './helpers';
import { SummaryAccordionProps } from './interfaces';
import { SummaryContent, SummaryRoot, Trigger, ValuesContainer } from './styles';

export default function SummaryAccordion({ summaryHandlers }: SummaryAccordionProps) {
	return (
		<Flex direction="column" gap="0px">
			<SummaryRoot type="multiple">
				<RadixAccordion.Item value="summary">
					<RadixAccordion.Header asChild>
						<Trigger asChild>
							<Flex
								justifyContent="space-between"
								alignItems="center"
								style={{ cursor: 'pointer' }}
								margin="0 0 20px 0"
							>
								<Typography variant="ParagraphSmall/Semibold">Resumo</Typography>

								<Icon name="expand_less" />
							</Flex>
						</Trigger>
					</RadixAccordion.Header>
					<SummaryContent>
						<ValuesContainer>
							<ValuesSummary
								{...getValuesSummary(summaryHandlers.get())}
								hideValue={{ total: true, totalLiquid: true }}
							/>
						</ValuesContainer>
					</SummaryContent>
				</RadixAccordion.Item>
			</SummaryRoot>

			<Flex justifyContent="space-between">
				<Typography>Total</Typography>
				<Typography variant="Paragraph/Semibold">
					{getValuesSummary(summaryHandlers.get()).liquid?.toCurrency()}
				</Typography>
			</Flex>
		</Flex>
	);
}
