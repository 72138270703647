import { notify } from 'front-commons/ds';
import { CatalogMenuItemsParams } from 'stores/catalog/interfaces';

const notifier = (description: string) => notify.warn({ description });

export const getPropertiesByItem = (href: string, status: CatalogMenuItemsParams['status']) => {
	if (status === 'AVAILABLE') {
		return {
			href,
			reloadDocument: true,
		};
	}

	if (href === '/sugestoes-de-pedidos') {
		return {
			onClick() {
				notifier('Não existem sugestões no momento.');
			},
		};
	}

	return {
		onClick() {
			notifier('Opção indisponível no momento.');
		},
	};
};
