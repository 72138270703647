import { Flex, Skeleton } from 'front-commons/ds';

export default function ValuesSummarySkeleton() {
	return (
		<Flex direction="column" gap="8px">
			{Array.generate(3).map((index) => (
				<Flex key={index} direction="column" gap="8px">
					{index === 2 && <Skeleton height="1px" width="100%" margin="16px 0" />}
					<Flex justifyContent="space-between">
						<Skeleton height="16px" width="100px" />
						<Skeleton height="16px" width="100px" />
					</Flex>
				</Flex>
			))}
		</Flex>
	);
}
