import { Shape, Typography } from 'front-commons/ds';
import { DistributorStatusProps } from './interfaces';
import { DistributorLabel } from './styles';

export default function DistributorStatus({ hiddenLabel, status }: DistributorStatusProps) {
	return (
		<DistributorLabel className="hidden-in-options">
			<div className="shape">
				<Shape shape={status.shape} height="12px" width="12px" color={status?.color} />
			</div>
			{!hiddenLabel && (
				<Typography color="--text-primary" variant="Caption/Regular">
					{status?.label}
				</Typography>
			)}
		</DistributorLabel>
	);
}
