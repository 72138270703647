import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { RemoveHTMlTags } from './helpers';
import { PageGlobalProps } from './interfaces';

export default function Head({
	title,
	subtitle,
	custom,
}: Pick<PageGlobalProps, 'title' | 'subtitle'> & { custom?: React.ReactNode }) {
	const location = useLocation();

	return (
		<Helmet>
			<title>{RemoveHTMlTags(title, ' ')} :: Parceiro Hypera</title>
			<meta
				name="description"
				content={
					RemoveHTMlTags(subtitle, ' ') ||
					'Tenha acesso às melhores ofertas, com experiência de compra rápida e o distribuidor de sua preferência!'
				}
			/>
			<link rel="canonical" href={`${window.location.origin}${location.pathname}`} />

			{custom}
		</Helmet>
	);
}
