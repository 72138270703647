import * as RadixAccordion from '@radix-ui/react-accordion';
import * as Progress from '@radix-ui/react-progress';
import { Flex, getThemeAnimation, getThemeFontWeight, getThemeMedia, getThemeSpace } from 'front-commons/ds';
import styled, { css } from 'styled-components';
import TrashButton from 'containers/TrashButton';

export const WrapperBasket = styled.div<{ safetyHeight?: number | null }>`
	display: flex;
	flex-direction: column;
	height: 100%;

	position: relative;

	${({ safetyHeight }) =>
		safetyHeight &&
		safetyHeight !== 1646 &&
		css`
			height: ${safetyHeight}px;
		`}
`;

export const ContainerProducts = styled.div`
	overflow: hidden auto;

	padding: 0 ${getThemeSpace('8')};
	margin: 0 ${getThemeSpace('8')} 353px;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			padding: 0 ${getThemeSpace('24')};
			margin: 0 ${getThemeSpace('32')} 409px;
		}
	}
`;

export const BottomContainer = styled.div`
	position: fixed;
	bottom: 0;
	display: flex;
	flex-direction: column;
	padding: ${getThemeSpace('32')} ${getThemeSpace('16')};
	gap: ${getThemeSpace('32')};

	background-color: var(--surface-white);

	p {
		text-align: center;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			gap: ${getThemeSpace('48')};
			padding: ${getThemeSpace('32')} ${getThemeSpace('56')};
		}
	}
`;

// Accordion

export const AccordionRoot = styled(RadixAccordion.Root)`
	display: flex;

	flex-direction: column;
	gap: ${getThemeSpace('4')};

	${getThemeAnimation().accordion.openClose().Root};
`;

export const AccordionItem = styled(RadixAccordion.Item)``;

export const AccordionTrigger = styled(RadixAccordion.Trigger)`
	cursor: pointer;
	padding: 0;
	padding-top: ${getThemeSpace('16')};

	display: flex;
	position: relative;

	justify-content: space-between;
	align-items: center;

	width: 100%;

	font-weight: ${getThemeFontWeight('regular')};
	text-align: left;

	${getThemeAnimation().chevron.rotate('.div_rotate i', 'data-state', { initial: 'closed', final: 'open' }, '-180').Parent};
`;

export const TriggerContent = styled.div`
	display: flex;

	max-width: 250px;
	width: 100%;

	flex-direction: column;

	gap: ${getThemeSpace('8')};
`;

export const AccordionContent = styled(RadixAccordion.Content)`
	margin-bottom: 15px;
	padding: 0 16px 0 0;

	${getThemeAnimation().accordion.openClose().Content};
`;

export const Divider = styled.hr`
	margin: ${getThemeSpace('16')} 0 ${getThemeSpace('8')};

	height: 2px;
	width: 100%;

	background-color: var(--border-primary);
	border-radius: 5px;
`;

export const TagContent = styled.div`
	display: flex;

	gap: ${getThemeSpace('8')};
`;

// Progress

export const ProgressContainer = styled.div`
	display: flex;

	flex-direction: column;
	gap: ${getThemeSpace('4')};
`;

export const ProgressContent = styled(Progress.Progress)`
	position: relative;

	max-width: 228px;
	width: 100%;
	height: 4px;

	overflow: hidden;
	transform: translateZ(0);

	background: var(--border-primary);

	border-radius: 99999px;
`;

export const ProgressIndicator = styled(Progress.Indicator)`
	width: 100%;
	height: 100%;

	transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);

	background-color: var(--surface-accent-dark);

	border-radius: 5px;

	&[data-state='complete'] {
		background-color: var(--semantic-success-base);
	}
`;

export const ProgressText = styled.div`
	display: flex;

	align-items: center;
	gap: ${getThemeSpace('8')};

	svg {
		border: 1.5px solid var(--semantic-success-base);
		border-radius: 50%;
	}
`;

// Empty Basket

export const EmptyContainer = styled.div`
	padding: 0 0 40px;

	display: flex;
	flex-direction: column;

	justify-content: space-around;
	align-items: center;

	gap: ${getThemeSpace('24')};

	@media screen {
		@media (min-width: ${getThemeMedia('large')}) {
			padding: 0 0 30px;

			gap: ${getThemeSpace('32')};
		}
	}

	@media screen {
		@media (min-width: 1440px) {
			padding: 20px 0 40px;

			gap: ${getThemeSpace('32')};
		}
	}
`;

export const EmptyTextContainer = styled.div`
	padding: 0 ${getThemeSpace('8')};

	display: flex;
	flex-direction: column;
	align-items: center;

	gap: ${getThemeSpace('16')};

	h2,
	p {
		text-align: center;
	}

	p {
		max-width: 305px;
	}
`;

export const ButtonWrapper = styled.div`
	max-width: 302px;
	margin: 0 ${getThemeSpace('16')};
`;

export const BoxTitle = styled.div`
	display: flex;
	align-items: center;
	gap: ${getThemeSpace('8')};
`;

export const LoadingBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 30px;
	height: 30px;
`;

// Summary Card

export const WrapperProductCard = styled.div`
	display: flex;
	gap: ${getThemeSpace('16')};
	position: relative;

	margin-left: -${getThemeSpace('8')};
	margin-right: -${getThemeSpace('8')};
	padding-left: ${getThemeSpace('16')};
	border-radius: 8px;

	overflow: hidden;

	.combo-track {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;

		background: var(--semantic-warning-base);

		width: 16px;

		text-align: center;
		text-transform: uppercase;

		p {
			transform: rotate(-90deg);
			white-space: nowrap;
		}
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			margin-left: -${getThemeSpace('16')};
			margin-right: -${getThemeSpace('16')};
		}
	}
`;
export const ProductDetails = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${getThemeSpace('8')};
	overflow: hidden;
	width: 100%;
`;
export const ProductCardThumbnail = styled.figure`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 100%;

	img {
		max-width: 60px;
		max-height: 60px;
	}
`;
export const ProductCardInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${getThemeSpace('16')};
`;
export const ProductCardActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

export const ButtonDelete = styled(TrashButton)`
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;

		svg {
			color: var(--text-primary);
		}
	}

	svg {
		color: var(--semantic-error-text);
	}
`;

// Total Basket Price

export const Root = styled(RadixAccordion.Root)`
	${getThemeAnimation().accordion.openClose().Root}

	div + div {
		${getThemeAnimation().accordion.openClose().Content}
	}
`;

export const Trigger = styled(RadixAccordion.Trigger)`
	width: 100%;
	padding: 0;

	${getThemeAnimation().chevron.rotate('i', 'data-state', { initial: 'closed', final: 'open' }, '-180').Parent}
`;

export const ValuesContainer = styled(Flex)`
	border-bottom: 1px solid var(--border-primary);
	padding-bottom: ${getThemeSpace('16')};
	margin-bottom: ${getThemeSpace('16')};
`;

export const TriggerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
	z-index: 403;
`;

export const BoxLeftTrigger = styled.div<{ expanded: boolean }>`
	display: flex;
	align-items: center;
	gap: ${getThemeSpace('8')};
`;

export const DistributorTitleWrapper = styled.div`
	display: flex;
	gap: ${getThemeSpace('8')};
`;

export const ButtonBasketWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: ${getThemeSpace('8')};

	.edit-button {
		text-decoration: underline;
	}
`;
