import { theme } from 'front-commons/ds/core/tokens';
import { ValuesProps } from './interfaces';

export const valueLabels: Record<keyof ValuesProps, string> = {
	brute: 'Subtotal',
	discount: 'Desconto',
	totalLiquid: 'Total líquido',
	tax: 'Imposto',
	total: 'Total',
};

export const containerStyle = {
	common: { justifyContent: 'space-between' },
	total: {
		justifyContent: 'space-between',
		style: { borderTop: `1px solid ${theme.colors['--border-primary']}` },
		padding: '16px 0 0 0',
		margin: '8px 0 0 0',
	},
};
