import { Button, Flex, Typography } from 'front-commons/ds';
import { useEffect, useState } from 'react';
import AddToBasketButton from 'containers/AddToBasketButton';
import TagComboBasket from 'containers/Tags/TagComboBasket';
import { updateCartGTM } from 'shared/gtm';
import { getTypePromotion } from 'shared/product';
import useBasket from 'stores/basket';
import useCustomer from 'stores/customer';
import useDrawer from 'stores/drawer';
import { SummaryCardProps } from './interfaces';
import {
	ButtonBasketWrapper,
	ButtonDelete,
	ProductCardActions,
	ProductCardInfo,
	ProductCardThumbnail,
	ProductDetails,
	WrapperProductCard,
} from './styles';

export default function SummaryCard({
	product: {
		id,
		imageURL,
		productType,
		description,
		ean13,
		finalTotalPrice,
		quantity: productQuantity,
		productsInCombo,
		progressiveDiscount,
		progressiveDiscountProductMix,
		unitDiscount,
		unitPricePromotion,
		brandName,
		suggestionGroupId,
		suggestionGroupName
	},
	distributorId,
	buId,
	selectedPos,
	businessUnitName,
	distributorName,
}: SummaryCardProps) {
	const { handleHasPermission } = useCustomer();
	const hasPermission = handleHasPermission('BASKET_EDIT');

	const {
		handleRemoveFromBasket,
		basketStore: { loading: loadingBasket, deleting },
	} = useBasket();
	const [finalValue, setFinalValue] = useState(0);
	const [quantity, setQuantity] = useState(productQuantity);
	const { handleOpenDrawer } = useDrawer();
	const validatedImage = productsInCombo?.length ? productsInCombo[0].imageURL : imageURL;
	const hasOneItemInPromotionOrIsSimple =
		(productType === 'COMBO' && productsInCombo?.length === 1) || productType === 'SIMPLE';

	const finalPriceByQuantity = finalValue / quantity;

	useEffect(() => {
		setFinalValue(finalTotalPrice * (quantity || 1));
	}, [quantity, finalTotalPrice]);

	const GTMCartEvent = (type: string, newQuantity: number) => {
		const total = unitPricePromotion * (newQuantity || productQuantity);

		const products = [
			{
				ean13,
				description,
				price: unitPricePromotion,
				quantity: newQuantity || productQuantity,
				brandName,
				businessUnitName,
				discount: unitDiscount,
				affiliation: distributorName,
				combo: false,
			},
		];

		updateCartGTM(type, total, products);
	};

	return (
		<WrapperProductCard>
			{productType === 'COMBO' && (
				<TagComboBasket
					label={getTypePromotion({
						progressiveDiscount,
						progressiveDiscountProductMix,
					})}
					bgColor="--semantic-success-base"
				/>
			)}

			<ProductCardThumbnail>
				<img src={`${import.meta.env.VITE_STATIC_STORAGE_BASE}${validatedImage}`} alt="Imagem do produto" />
			</ProductCardThumbnail>
			<ProductDetails>
				<ProductCardInfo>
					<Typography variant="Paragraph/Semibold" color="--text-primary">
						{description.capitalize().textEllipsis(35)}
					</Typography>
					{hasOneItemInPromotionOrIsSimple && (
						<Typography variant="ParagraphSmall/Regular">
							<Typography as="span" variant="ParagraphSmall/Semibold">
								EAN:{' '}
							</Typography>
							{productType === 'COMBO' ? productsInCombo?.[0].ean13 : ean13}
						</Typography>
					)}
				</ProductCardInfo>
				<ProductCardActions>
					<ButtonBasketWrapper>
						<Flex alignItems="center" gap="8px">
							<Flex height="40px" direction="column">
								<AddToBasketButton
									hasPermission={hasPermission}
									productId={id}
									initialQuantity={productQuantity}
									buId={buId}
									distributorId={distributorId}
									disabled={deleting !== undefined || loadingBasket === 'refetch' || productType === 'COMBO'}
									onUpdateBasketType={GTMCartEvent}
									onItemChange={(items) => {
										setQuantity(items);
									}}
									counterOptions={{
										width: '100%',
										maxWidth: { small: '124px', medium: '128px' },
										hasPermission,
									}}
									suggestionGroupId={suggestionGroupId}
									suggestionGroupName={suggestionGroupName}
								/>
							</Flex>
							<Typography variant={{ small: 'ParagraphSmall/Semibold', medium: 'Paragraph/Semibold' }}>
								Por {Number(finalPriceByQuantity).toCurrency()}
							</Typography>
						</Flex>
						{productType === 'COMBO' && (
							<Button
								onClick={() =>
									handleOpenDrawer('promotion-details-drawer', {
										promotionId: id,
										posId: selectedPos.id,
									})
								}
								className="edit-button"
								variant="none"
								color="--text-link"
							>
								Editar combo
							</Button>
						)}
					</ButtonBasketWrapper>

					<ButtonDelete
						variant="none"
						loading={deleting !== undefined && deleting === id}
						width="30px"
						disabled={loadingBasket !== false}
						onClick={() => handleRemoveFromBasket(id)}
						hasPermission={hasPermission}
						data-testid="cart-remove-product"
					/>
				</ProductCardActions>
			</ProductDetails>
		</WrapperProductCard>
	);
}
