import { CatalogReducer } from './interfaces';

const initialState: CatalogReducer = {
	loading: false,
	promotionDetails: {},
	suggestionsProduct: [],
	catalogMenuItems: [],
	isPromotionActive: false,
};

export default function catalogReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_CATALOG_LOADING': {
			return {
				...state,
				loading: payload.loading,
			};
		}

		case 'SET_CATALOG_MENU_ITEMS': {
			return {
				...state,
				catalogMenuItems: payload.catalogMenuItems,
			};
		}

		case 'SET_SUGGESTIONS_PRODUCT_NOTIFIER_EXPIRATION': {
			return {
				...state,
				suggestionsProduct: state.suggestionsProduct.updateOrPush(payload.notifierExpiration, 'posId'),
			};
		}

		case 'CLEAR_SUGGESTIONS_PRODUCT_NOTIFIER_EXPIRATION': {
			return {
				...state,
				suggestionsProduct: {
					...state.suggestionsProduct,
					notifierExpiration: '',
				},
			};
		}

		case 'SET_CATALOG_LOADING_PROMOTION_DETAILS': {
			return {
				...state,
				promotionDetails: {
					loading: payload.loading,
				},
			};
		}

		case 'SET_IS_PROMOTION_ACTIVE': {
			return {
				...state,
				isPromotionActive: payload.state,
			};
		}

		case 'CLEAR_CATALOG': {
			return initialState;
		}

		default:
			return state;
	}
}
