import { Typography } from 'front-commons/ds';
import { getPercentage } from 'containers/Boni/BoniCheckoutProgressItem/helpers';
import HoverCard from 'containers/Distributors/DistributorsSelector/HoverCard';
import { currencyRound } from 'shared/currency';
import { ProgressProps } from './interfaces';
import { Indicator, Root } from './styles';

export default function Progress({ max, value, min, nextRangeInfo, type }: ProgressProps) {
	const current = min ? value - min : value;
	const maxValue = max ? max - min : max;

	const percentage = getPercentage(current, maxValue, min);

	return (
		<HoverCard
			content={
				<Typography>
					Acima de {type === 'quantity' ? `${nextRangeInfo?.from} itens` : Number(nextRangeInfo?.from).toCurrency()}{' '}
					<strong>{currencyRound(nextRangeInfo?.discount || 0).toLocaleString('pt-br')}% de desconto adicional</strong>
				</Typography>
			}
			maxWidth={220}
		>
			<Root value={current} max={maxValue}>
				<Indicator percentage={percentage} />
			</Root>
		</HoverCard>
	);
}
