import { getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const DistributorsSelectorContainer = styled.div`
	button[name='distributorsSelect'] {
		padding-left: ${getThemeSpace('8')};

		.price-details {
			display: none;
		}

		.select-status {
			display: none;
		}
	}
`;

export const DistributorOptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 100%;

	.select-status {
		width: fit-content;
	}
`;

export const DistributorLabel = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

export const BoxBottomSelector = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const BoxLeftSelector = styled.div`
	display: flex;
	align-items: center;
	gap: ${getThemeSpace('8')};
	width: 100%;
`;

export const DistributorPrice = styled.span`
	display: flex;
	align-items: center;
	flex-direction: column;
	white-space: nowrap;

	@media screen {
		@media (min-width: 576px) {
			flex-direction: row;
			gap: ${getThemeSpace('4')};
		}
	}
`;

export const SelectStatusContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${getThemeSpace('8')};

	min-width: 52px;
`;
