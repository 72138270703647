import { Grid, Skeleton } from 'front-commons/ds';

function BasketSkeletonTop() {
	return (
		<Grid gap="8px" margin="20px auto">
			<Skeleton height="25px" width="100px" />
			<Grid gap="4px" columns="1fr 0.2fr" alignItems="center">
				<Skeleton height="27px" width="200px" />
				<Skeleton type="circle" height="32px" margin="0 auto" />
			</Grid>
			<Skeleton height="27px" width="220px" />

			<Skeleton height="2px" width="100%" />
		</Grid>
	);
}

function BasketSkeletonBottom() {
	return (
		<Grid gap="32px" padding={{ small: '32px 16px', medium: '32px 56px' }}>
			<Grid columns="1fr 1fr">
				<Grid columns="1fr 1fr" alignItems="center" gap="4">
					<Skeleton height="20px" width="70px" />
					<Skeleton type="circle" height="25px" margin="0" />
				</Grid>
				<Grid justifyContent="flex-end">
					<Skeleton height="20px" width="100px" />
				</Grid>
			</Grid>

			<Grid gap="8px" justifyContent="center">
				<Skeleton height="20px" width="200px" />
				<Skeleton height="20px" width="100px" margin="0 auto" />
			</Grid>

			<Grid gap="16px" columns="1fr 1fr" justifyContent="center">
				<Skeleton height="30px" width="100%" />
				<Skeleton height="30px" width="100%" margin="0 auto" />
			</Grid>
		</Grid>
	);
}

export const BasketSkeleton = {
	Top: BasketSkeletonTop,
	Bottom: BasketSkeletonBottom,
};
