import { CatalogMenuItemsParams, MenuItemsDataType } from './interfaces';

export const menuItemsData: Record<MenuItemsDataType, Pick<CatalogMenuItemsParams, 'href' | 'label'>> = {
	promotions: {
		href: '/promocoes',
		label: 'Promoções',
	},
	lancamentos: {
		href: '/lancamentos',
		label: 'Lançamentos',
	},
	businessUnit: {
		href: '/produtos?divisoes=',
		label: 'Produtos',
	},
	suggestionsProduct: {
		href: '/sugestoes-de-pedidos',
		label: 'Sugestões de pedidos',
	},
};
