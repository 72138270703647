import { Dispatch } from '@reduxjs/toolkit';
import * as action from './actions';
import { HandleChangePosPromotionDistributorParams, HandleRemovePosSinglePromotionParams, HandleSetAddPosPromotionsParams, HandleSetLoadingParams } from './interfaces';

export const handleSetLoading = (payload: HandleSetLoadingParams): any => {
	return (dispatch: Dispatch) => {
		payload.loadingTarget ??= undefined;

		dispatch(action.setOrderImportLoading(payload));
	};
};

export const handleSetAddPosPromotions = (payload: HandleSetAddPosPromotionsParams): any => {
	return (dispatch: Dispatch) => {
		dispatch(action.setAddPosPromotions(payload));
	};
};

export const handleChangePosPromotionDistributor = (payload: HandleChangePosPromotionDistributorParams): any => {
	return (dispatch: Dispatch) => {
		dispatch(action.setChangePosPromotionDistributor(payload));
	};
};

export const handleRemovePosSinglePromotion = (payload: HandleRemovePosSinglePromotionParams): any => {
	return (dispatch: Dispatch) => {
		dispatch(action.removePosSinglePromotion(payload));
	};
};

export const handleClearPosPromotions = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(action.setClearPosPromotions());
	};
};
