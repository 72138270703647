import { Flex, Typography } from 'front-commons/ds';

export default function CurrentDistributor({ name, bold }: { name: string; bold?: boolean }) {
	return (
		<Flex alignItems="center" gap="8px">
			<Flex backgroundColor="--semantic-success-base" width="10px" height="10px" borderRadius="50%" />
			<Typography variant={bold ? 'Paragraph/Semibold' : 'Paragraph/Semibold'}>{name}</Typography>
		</Flex>
	);
}
