import { Flex } from 'front-commons/ds';
import styled from 'styled-components';
import { UNDO_TIME } from 'containers/Drawers/PromotionSuggestion/helpers';

export const Container = styled(Flex)`
	position: relative;
	border-bottom: 4px solid var(--border-primary);

	&[data-loading='true'] {
		border-bottom: 0;
	}

	&:not([data-loading='true']):before {
		@keyframes progressIncrease {
			0% {
				width: 0px;
			}

			100% {
				width: 100%;
			}
		}

		position: absolute;
		bottom: -4px;
		left: 0;

		height: 4px;
		width: 100px;
		background-color: var(--surface-accent);
		border-radius: 0 4px 4px 0;

		animation: progressIncrease ${UNDO_TIME}ms forwards linear;
		content: '';
	}

	svg {
		color: var(--text-invert);
	}
`;
