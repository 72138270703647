import { Flex, Icon, Typography } from 'front-commons/ds';
import { PromotionsDrawerContentProps } from 'containers/Drawers/PromotionsDrawerContent/interfaces';
import { ProductImage, WithoutPromotionsContainer } from './styles';

export default function NoPromotionDrawerCard({ data }: PromotionsDrawerContentProps) {
	return (
		<Flex direction="column">
			<Flex gap="8px">
				<ProductImage>
					<img
						src={`${import.meta.env.VITE_STATIC_STORAGE_BASE}${data?.imageURL}`}
						alt={data?.description?.capitalize()}
					/>
				</ProductImage>
				<Flex direction="column" gap="8px">
					<Typography variant="Paragraph/Semibold">{data?.description}</Typography>
					<Typography>EAN: {data?.ean13}</Typography>
				</Flex>
			</Flex>
			<WithoutPromotionsContainer direction="column" alignItems="center" justifyContent="center" gap="16px">
				<Icon name="storeRemove" color="--text-primary" size="80px" />
				<Typography>Não há promoções elegíveis para esse produto</Typography>
			</WithoutPromotionsContainer>
		</Flex>
	);
}
