import { Flex, Typography } from 'front-commons/ds';
import { containerStyle, valueLabels } from './helpers';
import { BasketSummaryProps, ValuesProps } from './interfaces';

export default function ValuesSummary({
	discount = 0,
	brute = 0,
	tax = 0,
	liquid = 0,
	hideValue,
}: Partial<BasketSummaryProps>) {
	const totalLiquid = discount && tax ? brute - discount : 0;
	const total = brute - discount + tax;

	const values: ValuesProps = {
		brute,
		discount,
		totalLiquid: liquid || totalLiquid,
		tax,
		total,
	};

	return (
		<Flex direction="column" width="100%" gap="8px">
			{Object.entries(values).map(([key, value]) => {
				const showOperator = key === 'discount';
				const isTotal = key === 'total';
				const styleKey = isTotal ? 'total' : 'common';

				return (
					// @ts-ignore
					<Flex key={key} {...containerStyle[styleKey]} display={!value || hideValue?.[key] ? 'none' : 'flex'}>
						<Typography color="--neutral-black">{valueLabels[key as keyof typeof valueLabels]}</Typography>
						<Typography color="--neutral-black" variant={isTotal ? 'Paragraph/Semibold' : 'Paragraph/Regular'}>
							{showOperator && '- '}
							{Number(value).toCurrency()}
						</Typography>
					</Flex>
				);
			})}
		</Flex>
	);
}
