import { Flex as ComponentFlex, Grid, Icon, Skeleton, Typography } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import { useMediaQuery } from 'front-commons/hooks';
import { BoxSkeleton, Flex } from './styles';

export default function Skeletons() {
	const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.small})`);

	return (
		<>
			<BoxSkeleton>
				<Grid gap="24px">
					<Skeleton height="35px" width="450px" />
					<Skeletons.Description />
					<Grid gap="8px">
						<Flex>
							<Typography>Distribuidores disponíveis</Typography>
							<Icon name="help" size="16px" color="--text-primary" />
						</Flex>
						<Skeletons.Distributors />
					</Grid>
					<Grid gap="16px">
						<Typography variant="Paragraph/Semibold" style={{ fontSize: '20px' }}>
							Desconto progressivo
						</Typography>
						<Skeletons.ProgressiveDiscount />
					</Grid>
					<Typography variant="Paragraph/Semibold" style={{ fontSize: '20px' }}>
						Itens do combo
					</Typography>
					<Skeletons.Products isTablet={isTablet} />
				</Grid>
			</BoxSkeleton>

			<Grid gap={{ small: '32px', medium: '48px' }} padding={{ small: '32px 16px', medium: '32px 56px' }}>
				<Grid columns="1fr 1fr">
					<Grid columns="100px 25px" alignItems="center" gap="8px">
						<Skeleton height="20px" width="100px" />
						<Skeleton type="circle" height="25px" margin="0" />
					</Grid>
					<Grid justifyContent="flex-end">
						<Skeleton height="20px" width="85px" />
					</Grid>
				</Grid>

				<Flex alignEnd>
					<Skeleton height="40px" width="160px" />
				</Flex>
			</Grid>
		</>
	);
}

Skeletons.Description = () => (
	<Flex>
		<Skeleton height="16px" width="100%" />
		<Skeleton height="16px" width="300px" />
	</Flex>
);

Skeletons.Distributors = () => (
	<Grid gap="8px">
		<Skeleton height="48px" width="100%" maxWidth="383px" />
		<Grid columns="max-content max-content" gap="8px">
			<Skeleton height="16px" type="circle" />
			<Skeleton height="16px" width="110px" />
		</Grid>
	</Grid>
);

Skeletons.ProgressiveDiscount = () => {
	return (
		<ComponentFlex gap="0px">
			{Array.from({ length: Number.getRandomInt(3, 5) }, (_, index) => (
				<ComponentFlex key={index} gap="0px" alignItems="center" width="100%">
					<Skeleton height="13px" />
					<Skeleton height="24px" type="circle" margin="-8px" zIndex={2} />
				</ComponentFlex>
			)).map((item) => item)}
		</ComponentFlex>
	);
};

Skeletons.ProductCardResponsive = () => {
	return (
		<Grid gap="16px">
			<Grid columns="68px 1fr">
				<Skeleton height="68px" width="68px" />
				<Grid>
					<Skeleton height="20px" width="160px" />
					<Skeleton height="20px" width="150px" />
				</Grid>
			</Grid>
			<Grid columns="1fr 1fr" alignItems="center">
				<Grid gap="4px">
					<Skeleton height="40px" width="112px" />
					<Skeleton height="18px" width="82px" />
				</Grid>
				<Grid gap="4px">
					<Grid columns="75px 60px" alignItems="center" gap="4px">
						<Skeleton height="18px" width="75px" />
						<Skeleton height="20px" width="60px" />
					</Grid>
					<Skeleton height="22px" width="100px" />
					<Skeleton height="18px" width="120px" />
				</Grid>
			</Grid>
		</Grid>
	);
};

Skeletons.ProductCardDesktop = () => {
	return (
		<Grid columns="80px 160px 116px 1fr" gap="16px">
			<Skeleton height="80px" width="80px" />
			<Grid gap="8px">
				<Skeleton height="20px" width="160px" />
				<Skeleton height="20px" width="150px" />
			</Grid>
			<Grid gap="4px" justifyContent="center">
				<Skeleton height="40px" width="116px" />
				<Skeleton height="18px" width="82px" margin="0 auto" />
			</Grid>
			<Grid gap="4px">
				<Grid columns="75px 60px" alignItems="center" gap="4px">
					<Skeleton height="18px" width="75px" />
					<Skeleton height="20px" width="60px" />
				</Grid>
				<Skeleton height="22px" width="100px" />
				<Skeleton height="18px" width="120px" />
			</Grid>
		</Grid>
	);
};

Skeletons.Products = ({ isTablet }: { isTablet: boolean }) => {
	return (
		<>
			{Array.from({ length: 3 }, () => '').map((_, i) =>
				isTablet ? <Skeletons.ProductCardDesktop key={i} /> : <Skeletons.ProductCardResponsive key={i} />,
			)}
		</>
	);
};
