import { Flex } from 'front-commons/ds';
import DistributorsDragAndDropSkeleton from 'containers/DragAndDrop/DistributorsDragAndDrop/Skeleton';

export default function DrawerDistributorSkeleton() {
	return (
		<Flex width="100%" gap="16px">
			<DistributorsDragAndDropSkeleton />
			<DistributorsDragAndDropSkeleton />
		</Flex>
	);
}
