import { Button, Flex, Typography } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import { useMediaQuery } from 'front-commons/hooks';
import { useNavigate } from 'react-router-dom';
import ContactUs from 'containers/ContactUs';
import Header from 'containers/Headers/Header';
import Head from 'routes/Head';
import { ErrorPageProps } from './interface';
import { ErrorContainer, ErrorImageWrapper } from './styles';

export default function Error({
	isGlobalBoundary,
	errorType,
	subtitle,
	description,
	showReload,
	showButtonGoToPH = false,
	showHeader = true,
}: ErrorPageProps) {
	const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.medium})`);

	const navigate = useNavigate();

	const imdSrc = isTablet
		? `https://hyperaadminprod.blob.core.windows.net/site-images/luana-erro-${errorType || '404'}.png`
		: 'https://hyperaadminprod.blob.core.windows.net/site-images/luana-erro-404_mobile.png'; // é a mesma imagem para 500

	const headTitle = {
		404: 'Página não encontrada',
		500: 'Serviço indisponível',
	};

	return (
		<>
			{errorType && <Head title={headTitle[errorType]} />}

			{showHeader && <Header type={isGlobalBoundary ? 'logged_and_not_logged' : 'dynamic'} logoNavigateReload />}

			<ErrorContainer>
				<ErrorImageWrapper>
					<img
						src={imdSrc}
						alt="Imagem da Luana, com camiseta branca, óculos com a armação vermelha, com a mão sobre o rosto e um semblante de assustada."
					/>
				</ErrorImageWrapper>

				<div>
					<Flex
						direction="column"
						gap="0px"
						margin={{ small: '0 0 32px 0', medium: '0 0 24px 0', large: '16px 0 0 0' }}
					>
						{errorType ? (
							<Typography
								as="h1"
								variant={{ small: 'Headline/H2 Bold', medium: 'Headline/H1 Bold' }}
								color="--text-link"
								style={{ fontWeight: 400 }}
							>
								Erro {errorType}
							</Typography>
						) : (
							<Typography
								as="h1"
								variant={{ small: 'Headline/H2 Bold', medium: 'Headline/H1 Bold' }}
								color="--text-link"
								style={{ fontWeight: 400 }}
							>
								Página não encontrada
							</Typography>
						)}

						{subtitle && (
							<Typography as="h2" variant={{ small: 'Paragraph/Semibold', medium: 'Subtitle' }} color="--text-primary">
								{subtitle}
							</Typography>
						)}
					</Flex>

					<Flex direction="column" maxWidth={{ medium: '624px' }} gap={{ small: '90px', medium: '24px' }}>
						<div>
							<Flex direction="column" margin={{ small: '0 32px 16px 0', medium: '0 0 24px 0' }}>
								<Typography color="--text-primary">{description}</Typography>
							</Flex>

							<ContactUs phoneUnderline phoneColor="--text-link" />
						</div>

						{showReload && (
							<Flex direction="column" gap="16px" alignItems={{ small: 'center', medium: 'flex-start' }}>
								<Typography color="--text-primary">Ou recarregue essa página</Typography>

								<Button
									size="large"
									onClick={() => navigate(0)}
									width="100%"
									maxWidth={{ small: '260px', medium: '280px' }}
								>
									Tente novamente
								</Button>
							</Flex>
						)}

						{showButtonGoToPH && (
							<Flex direction="column" alignItems={{ small: 'center', medium: 'flex-start' }}>
								<Button
									size="small"
									onClick={() => navigate('/')}
									width="100%"
									maxWidth={{ small: '260px', medium: '280px' }}
								>
									Ir para o Parceiro Hypera
								</Button>
							</Flex>
						)}
					</Flex>
				</div>
			</ErrorContainer>
		</>
	);
}
