import { Button, Counter, Flex as FlexContainer, Grid, Icon, Tooltip, Typography } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import { useDebounce, useMediaQuery } from 'front-commons/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DistributorsSelector from 'containers/Distributors/DistributorsSelector';
import ProgressiveDiscount from 'containers/ProgressiveDiscount';
import usePromotionContext from 'contexts/Promotion';
import { DistributorsProps, ProductInComboProps } from 'services/products/interfaces';
import useBasket from 'stores/basket';
import useCustomer from 'stores/customer';
import useDialog from 'stores/dialog';
import useDrawer from 'stores/drawer';
import {
	getDialogProps,
	hasUnableToUpdate,
	handlerDistributorValidity,
	hasQuantityChanged as handleHasQuantityChanged,
	getCurrentComponentRange,
	getRemainingDaysText,
} from './helpers';
import { DialogState, UnavailablePromotionDetailDrawerContentProps } from './interfaces';
import PromotionProductCard from './PromotionProductCard';
import Skeletons from './Skeletons';
import {
	Flex,
	Wrapper,
	Products,
	Container,
	BoxButtons,
	BottomContainer,
	ContainerProducts,
	InvalidDistributorsContainer,
} from './styles';
import TotalComboPrice from './TotalComboPrice';

export default function UnavailablePromotionDetailDrawerContent({
	promotionId = '',
	posId = '',
	orderDistributorByPrice,
}: UnavailablePromotionDetailDrawerContentProps) {
	const {
		data,
		summary,
		loading,
		closePromotionDrawer,
		showDrawerByUnavailablePromotionId,
		selectedDistributor,
		setSelectedDistributor,
		getProductInPromotionValues,
	} = usePromotionContext();

	const {
		handleOpenBasket,
		handleRemoveFromBasket,

		basketStore: {
			loading: loadingBasket,
			unavailable: productsUnavailable,
			changedUnavailable: changedUnavailableProducts,
		},

		unavailableHandler,
	} = useBasket();

	const { handleCloseAllDrawers } = useDrawer();
	const { handleHasPermission } = useCustomer();

	const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.small})`);

	const productsInCombo = summary?.map(({ productId: id, quantity }) => ({ productId: id, quantity }));

	const { handleOpenDialog } = useDialog();

	const currentQuantityInBasket = useMemo(() => {
		const unavailablePromotion = productsUnavailable?.businessUnitToReview
			.find((bu) => bu.businessUnitId === data.businessUnitId)
			?.products.find((product) => product.productId === promotionId);
		const unavailaBlePromotionUpdate = changedUnavailableProducts?.find((product) => product.productId === promotionId);

		if (!unavailaBlePromotionUpdate) return unavailablePromotion?.quantity;

		return unavailaBlePromotionUpdate.quantity;
	}, [changedUnavailableProducts, data, productsUnavailable]);

	const [fixedQuantity, setFixedQuantity] = useState<number | ''>(currentQuantityInBasket || 1);
	const [lastFixedQuantity, setLastFixedQuantity] = useState(currentQuantityInBasket || 1);
	const debouncedFixedQuantity = useDebounce(fixedQuantity, 500);

	const { brute, discount, liquid, tax } = getProductInPromotionValues?.(Number(debouncedFixedQuantity) || 1) || {};
	const hasValidDistributors = handlerDistributorValidity(data);
	const hasQuantityChanged =
		handleHasQuantityChanged(summary, promotionId, changedUnavailableProducts) && !data?.fixedAmount;

	const daysToEnd = getRemainingDaysText(data?.endDate);
	const hasPermission = handleHasPermission('BASKET_EDIT');

	const getChildQuantity = useCallback(
		(item: ProductInComboProps) => {
			const promotionChildrenUpdated = changedUnavailableProducts.find(
				(unavailablePromotion) => unavailablePromotion.productId === promotionId,
			)?.productsInCombo;

			const promotionChildUpdatedQuantity = promotionChildrenUpdated?.find(
				(promotionChild) => promotionChild.productId === item.productId,
			)?.quantity;

			return promotionChildUpdatedQuantity || item.quantity;
		},
		[changedUnavailableProducts, data],
	);

	const handleSetSelectedDistributor = (distributor: DistributorsProps) => {
		if (!window.location.pathname.includes('/revisar-pedido/produtos-indisponiveis')) return;
		setSelectedDistributor(distributor);
	};

	const handleOpenSideBasket = () => {
		closePromotionDrawer();
		handleCloseAllDrawers();
		handleOpenBasket();
	};

	const handleRemovePromotion = useCallback(() => {
		handleRemoveFromBasket(promotionId);
	}, [promotionId, posId]);

	const handleChangeDistributor = (distributor: DistributorsProps) => {
		unavailableHandler.change({
			distributor,
			productId: promotionId,
			quantity: Number(fixedQuantity),
			productsInCombo,
			isChecked: false,
		});

		handleSetSelectedDistributor(distributor);
	};

	const handleDialogClose = () => {
		showDrawerByUnavailablePromotionId({ promotionId, posId });
	};

	const handleCancelRemovePromotionDialog = () => {
		setFixedQuantity(lastFixedQuantity);
	};

	const handleSetDialogState = useCallback((state: DialogState) => {
		handleOpenDialog({
			...getDialogProps(state, {
				handleOpenSideBasket,
				handleRemovePromotion,
				handleDialogClose,
				handleCancelRemovePromotion: handleCancelRemovePromotionDialog,
			}),
		});
	}, []);

	const handleUpdateUnavailableProduct = () => {
		if (!liquid) return handleSetDialogState('no_value');

		unavailableHandler.update(
			productsUnavailable?.businessUnitToReview.map((bu) => {
				return {
					...bu,
					products: bu.products.map((product) => {
						if (product.productId !== promotionId) return product;

						return {
							...product,
							updatedDistributor: selectedDistributor,
							productsInCombo,
							quantity: 1,
						};
					}),
				};
			}) || [],
		);

		return unavailableHandler.change({
			distributor: selectedDistributor,
			productId: promotionId,
			quantity: 1,
			productsInCombo,
			isChecked: false,
		});
	};

	const handleChangeFixedQuantity = useCallback((quantity: number | '') => {
		if (quantity === 0) {
			handleSetDialogState('removing_unavailable_promotion');
			return;
		}

		setFixedQuantity((prevState) => {
			if (typeof prevState === 'number') {
				setLastFixedQuantity(prevState);
			}

			return quantity;
		});
	}, []);

	const handleRemoveQuantityFixedProduct = () => {
		if (fixedQuantity === 1) {
			handleSetDialogState('removing_unavailable_promotion');
			return;
		}

		setFixedQuantity((prevState) => (prevState ? prevState - 1 : prevState));
	};

	useEffect(() => {
		setFixedQuantity((prevState) => {
			if (prevState !== '') setLastFixedQuantity(prevState);

			return currentQuantityInBasket || 1;
		});
	}, [currentQuantityInBasket]);

	useEffect(() => {
		if (data.id && ((data?.fixedAmount && !!debouncedFixedQuantity) || !data.fixedAmount)) {
			unavailableHandler.change({
				distributor: selectedDistributor,
				productId: promotionId,
				quantity: Number(fixedQuantity),
				productsInCombo,
				isChecked: false,
			});
		}
	}, [data, debouncedFixedQuantity]);

	useEffect(() => {
		if (debouncedFixedQuantity) {
			unavailableHandler.update(
				productsUnavailable?.businessUnitToReview.map((bu) => {
					return {
						...bu,
						products: bu.products.map((product) => {
							if (product.productId !== promotionId) return product;

							return {
								...product,
								updatedDistributor: selectedDistributor,
								quantity: debouncedFixedQuantity,
							};
						}),
					};
				}) || [],
			);
		}
	}, [debouncedFixedQuantity]);

	useEffect(() => {
		const atualProduct = changedUnavailableProducts.find((product) => product.productId === promotionId);

		if (!atualProduct) {
			handleSetSelectedDistributor(undefined as any);
		} else {
			handleSetSelectedDistributor(
				data.distributors?.find(
					(distributor) => distributor?.distributorId === atualProduct?.distributor?.distributorId,
				) as DistributorsProps,
			);
		}
	}, [data.distributors, changedUnavailableProducts]);

	if (!promotionId) return <div />;

	return (
		<Wrapper>
			{!loading && (
				<Typography as="h2" color="--text-primary" variant="Headline/H2 Bold">
					{data?.description}
				</Typography>
			)}

			{['full', 'opening'].includes(loading) ? (
				<Skeletons />
			) : (
				<>
					<Container as={FlexContainer} direction="column" gap="24px">
						<Typography>
							{data?.moreAbout}
							{daysToEnd && (
								<>
									<br />
									<Tooltip content="Dias faltantes para os términos das promoções vigentes.">
										<strong>{daysToEnd}</strong>
									</Tooltip>
								</>
							)}
						</Typography>
						{!!data?.distributors?.length && hasValidDistributors ? (
							<Grid gap="8px">
								<Flex>
									<Typography>Distribuidores disponíveis</Typography>
									<Tooltip
										content="Alguns distribuidores estão indisponíveis para esta promoção por não possuírem estoque de todos os produtos."
										title="Distribuidores disponíveis"
									>
										<Icon name="help" size="16px" color="--text-primary" />
									</Tooltip>
								</Flex>
								<DistributorsSelector
									initialValue={
										selectedDistributor && selectedDistributor?.status !== 'UNAVAILABLE'
											? selectedDistributor
											: undefined
									}
									data={data.distributors as DistributorsProps[]}
									onChange={handleChangeDistributor}
									orderByLowerPrice={orderDistributorByPrice}
									isPromotionDrawer
									watchInitialValue
									permitEmptyValue
									productId={data.hyperaCode}
								/>
							</Grid>
						) : (
							<InvalidDistributorsContainer gap="24px">
								<Typography variant="Paragraph/Semibold" style={{ fontSize: '20px' }}>
									Distribuidores
								</Typography>
								<Typography>
									Promoção indisponível nos distribuidores selecionados. Você pode alterar sua seleção no botão abaixo.
								</Typography>
								<Button
									onClick={() => {
										handleCloseAllDrawers();
									}}
									href={`/minhas-farmacias/distribuidores?f=${posId}`}
									width={{ small: '100%', medium: 'fit-content' }}
								>
									Alterar distribuidores
								</Button>
							</InvalidDistributorsContainer>
						)}

						{!!data?.ranges?.length && !data?.progressiveDiscount && (
							<Grid gap="16px">
								<Typography variant="Paragraph/Semibold" style={{ fontSize: '20px' }}>
									Desconto progressivo
								</Typography>
								<ProgressiveDiscount
									data={{ type: data?.rangeByQuantity ? 'quantity' : 'currency', values: data?.ranges }}
									currentRangeReference={getCurrentComponentRange(
										data?.rangeByQuantity,
										data?.progressiveDiscount,
										summary,
										currentQuantityInBasket,
									)}
									contained
								/>
							</Grid>
						)}

						<ContainerProducts>
							<Typography variant="Paragraph/Semibold" style={{ fontSize: '20px' }}>
								Itens do combo
							</Typography>

							{loading === 'refetch' || loadingBasket ? (
								<Skeletons.Products isTablet={isTablet} />
							) : (
								<Products>
									{data?.productsInCombo?.map((item) => (
										<PromotionProductCard
											hasPermission={hasPermission}
											key={item.productId}
											product={{
												...item,
												quantity: getChildQuantity(item),
											}}
											promotionId={promotionId}
											mixedRanges={data?.ranges?.length ? data?.ranges : null}
											fixedAmount={data.fixedAmount}
											rangeByQuantity={data.rangeByQuantity}
											quantityInBasket={currentQuantityInBasket}
											progressiveDiscount={data.progressiveDiscount}
											hasValidDistributors={hasValidDistributors}
											hasDistributorSelected={!!selectedDistributor}
										/>
									))}
								</Products>
							)}
						</ContainerProducts>
					</Container>

					<BottomContainer>
						<TotalComboPrice discount={discount} tax={tax} liquid={liquid} brute={brute} />

						<BoxButtons>
							{!!currentQuantityInBasket && (
								<Button width="160px" variant="secondary" onClick={handleRemovePromotion} hasPermission={hasPermission}>
									Remover
								</Button>
							)}

							{data?.fixedAmount && (
								<Counter
									quantity={fixedQuantity}
									initialQuantity={lastFixedQuantity}
									onAdd={() => setFixedQuantity((prevState) => (prevState ? prevState + 1 : prevState))}
									onRemove={handleRemoveQuantityFixedProduct}
									onChange={handleChangeFixedQuantity}
									hasPermission={hasPermission}
									data-testid="add-to-cart-counter"
									width="100%"
									maxWidth="160px"
									disabled={!selectedDistributor}
								/>
							)}

							{!data?.fixedAmount && (
								<Button
									width="160px"
									onClick={handleUpdateUnavailableProduct}
									// loading={flexAddLoading}
									disabled={hasUnableToUpdate(hasQuantityChanged, currentQuantityInBasket, hasValidDistributors)}
									rightIcon={{ name: 'shopping_cart', color: '--text-invert' }}
									hasPermission={hasPermission}
								>
									Atualizar
								</Button>
							)}
						</BoxButtons>
					</BottomContainer>
				</>
			)}
		</Wrapper>
	);
}
