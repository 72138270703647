import { useSelector, useDispatch } from 'react-redux';
import { Redux } from 'stores/interfaces';
import * as middleware from './middleware';
import { FeaturesReducer } from './interfaces';

export default function useFeatures() {
	const dispatch = useDispatch();
	const featuresStore = useSelector((stores: Redux) => stores.featuresReducer);

	return {
		featuresStore,
		handleSetFeatures: (key: keyof FeaturesReducer, value: unknown) =>
			dispatch(middleware.handleSetFeatures(key, value)),
		handleResetFeatures: () => dispatch(middleware.handleSetResetFeatures()),
	};
}
