import * as Card from '@radix-ui/react-hover-card';
import { getThemeSpace } from 'front-commons/ds';
import styled, { css } from 'styled-components';
import { getAnimationStyle } from './helpers';
import { HoverCardProps } from './interfaces';

export const Trigger = styled(Card.Trigger)`
	width: 100%;
`;

export const Content = styled(Card.Content)<Partial<HoverCardProps>>`
	padding: ${getThemeSpace('8')};
	border-radius: 4px;

	background-color: var(--surface-white);

	box-shadow: 0px 0px 8px #00000033;

	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: ${maxWidth}px;
		`}

	${({ animation }) => animation && getAnimationStyle(animation)}
`;

export const Arrow = styled(Card.Arrow)`
	fill: var(--text-invert);
`;
