window.GAEvents = (keys?: string | string[]) => {
	const data = (window.dataLayer || []).reduce((acc, { event = '', ...eventData }) => {
		const filters = keys ? Array.isArray(keys) ? keys : [keys] : undefined;

		if (!event || event.startsWith('gtm.')) return acc;
		if (filters && !filters?.includes(event)) return acc;

		acc[event] = [...(acc[event] || []), eventData];
		return acc;
	}, {} as Record<string, any>);

	if (Object.keys(data).length === 1) return data[Object.keys(data)[0]];

	return data;
};