import { Button, Flex, getThemeSpace, styled, Typography } from 'front-commons/ds';

export const BoxPriceInfo = styled(Flex)`
	align-items: center;
	gap: ${getThemeSpace('4')};
	white-space: nowrap;
`;

export const BoxPriceInfoTop = styled(Flex)`
	align-items: center;
	gap: ${getThemeSpace('4')};
	white-space: nowrap;

	> p {
		text-decoration: line-through;
	}
`;

export const WrapperProductCard = styled(Flex)`
	&:not(:last-child) {
		padding-bottom: ${getThemeSpace('16')};
		margin-bottom: ${getThemeSpace('16')};
		border-bottom: 1px solid var(--border-primary);
	}
`;

export const ProductCardThumbnail = styled(Flex)`
	img {
		max-width: 68px;
		max-height: 68px;
	}

	@media screen {
		@media (min-width: 576px) {
			img {
				max-width: 80px;
				max-height: 80px;
			}
		}
	}
`;

export const UnitPriceInformation = styled(Flex)`
	> p {
		text-decoration: line-through;
	}
`;

export const LinkButton = styled(Button)`
	text-decoration: underline;
	color: var(--text-link);
	display: inline-block;
	padding: 0;
	height: auto;
	
	p	{
		font-size: 12px !important;
	}
`

export const ConflictDescription = styled(Typography)`
	width:100%;	
`