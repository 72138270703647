import { Flex, Typography } from 'front-commons/ds';
import { useEffect, useRef, Fragment } from 'react';
import { getCurrentRangeIndex, rangeMessage } from './helpers';
import { ProgressiveDiscountProps } from './interfaces';
import Progress from './Progress';
import { Container, ProgressContainer } from './styles';
import Target from './Target';

export default function ProgressiveDiscount({
	data,
	currentRangeReference: currentQuantity,
	contained = false,
	hasRangeProduct = false,
	progressiveDiscountProductMix = false,
	maxDiscountInfo = false,
}: ProgressiveDiscountProps) {
	const scrollRef = useRef(null);

	useEffect(() => {
		const positionMultiplier = getCurrentRangeIndex(data.values, currentQuantity);
		// @ts-ignore
		scrollRef.current.scrollLeft += 50 * positionMultiplier;
	}, []);

	return (
		<Container direction="column" gap="16px" contained={contained}>
			<Flex
				padding="16px"
				backgroundColor="--surface-accent"
				width="100%"
				display={contained ? 'flex' : 'none'}
				as={Typography}
				variant="Paragraph/Semibold"
				color="--text-invert"
			>
				Desconto progressivo
			</Flex>
			<Flex padding={contained ? '0 16px' : '0'} direction="column" gap="0px">
				<Typography variant="Paragraph/Semibold">
					{rangeMessage({
						data,
						currentRangeReference: currentQuantity,
						hasRangeProduct,
						progressiveDiscountProductMix,
					})}
				</Typography>
				{maxDiscountInfo && (
					<Typography>Adicione mais unidades e economize até {data.values.at(-1)?.discount}%.</Typography>
				)}
			</Flex>
			<ProgressContainer gap="0px" contained={contained} ref={scrollRef}>
				{data.values.map(({ to, from, discount }, index) => (
					<Fragment key={index}>
						{!index && (
							<Progress
								min={0}
								max={from}
								value={currentQuantity >= from && currentQuantity !== 0 ? from + 1 : currentQuantity}
								nextRangeInfo={{ from: 1, discount }}
								type={data.type}
							/>
						)}
						<Target completed={!!currentQuantity && currentQuantity >= from} discount={discount} />
						<Progress
							min={from}
							max={to!}
							value={currentQuantity}
							nextRangeInfo={data.values[index + 1]}
							type={data.type}
						/>
					</Fragment>
				))}
			</ProgressContainer>
		</Container>
	);
}
