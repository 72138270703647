import { useSelector, useDispatch } from 'react-redux';
import { Redux } from 'stores/interfaces';
import * as middleware from './middleware';
import {
	HandleChangePosPromotionDistributorParams,
	HandleRemovePosSinglePromotionParams,
	HandleSetAddPosPromotionsParams,
	HandleSetLoadingParams
} from './interfaces';
import { getPromotionInfo } from 'services/products';
import useDrawer from 'stores/drawer';
import { ShowDrawerByPromotionIdParams } from 'contexts/Promotion/interfaces';
import { useCallback } from 'react';
import { notify } from 'front-commons/ds';
import { getImportPromotionDetails } from 'services/import';

export default function useOrderImport() {
	const dispatch = useDispatch();
	const orderImportStore = useSelector((stores: Redux) => stores.orderImportReducer);

	const { handleOpenDrawer } = useDrawer()

	const handleSetLoading = (payload: HandleSetLoadingParams) => {
		dispatch(middleware.handleSetLoading(payload));
	}

	const handleAddPosPromotions = (payload: HandleSetAddPosPromotionsParams) => {
		dispatch(middleware.handleSetAddPosPromotions(payload));
	};

	const handleOpenDetailsDrawer = useCallback(
		async ({
			posId,
			promotionId,
			hasPrevData,
			sourceProductId,
			showReturnButton,
			importDistributor,
			orderImportProductId,
			disableDistributorSelector,

			handleRefetchItemList,
			handleChangeDistributorId
		}: ShowDrawerByPromotionIdParams) => {
			handleSetLoading({ loading: 'opening', loadingTarget: promotionId });

			const storedPrevData = orderImportStore.posPromotions?.[posId]?.[promotionId];

			const fetchPrevData = async () => {

				const response = await getImportPromotionDetails(orderImportProductId!, promotionId);

				handleAddPosPromotions({
					posId,
					promotionId,
					payload: {
						products: response.productsInCombo.map(({ productId, quantity }) => ({ productId, quantity })),
						quantity: response.quantity || 1,
						distributorId: response.distributorId,
						orderImportProductId: response.orderImportProductId,
					},
				});
			};

			if (hasPrevData && !storedPrevData) await fetchPrevData();

			try {
				const promotionData = await getPromotionInfo(posId, promotionId);

				handleOpenDrawer('import-promotion-details-drawer', {
					data: promotionData,
					posId,
					prevData: storedPrevData,
					promotionId,
					sourceProductId,
					showReturnButton,
					importDistributor,
					orderImportProductId,
					disableDistributorSelector,

					handleRefetchItemList,
					handleChangeDistributorId
				});
			} catch {
				notify.negative({ description: 'Promoção não encontrada' });
			} finally {
				handleSetLoading({ loading: '' });
			}
		},
		[orderImportStore],
	);

	return {
		orderImportStore,

		handleAddPosPromotions,
		handleOpenDetailsDrawer,

		handleClearPosPromotions() {
			dispatch(middleware.handleClearPosPromotions());
		},

		handleChangePosPromotionDistributor(payload: HandleChangePosPromotionDistributorParams) {
			dispatch(middleware.handleChangePosPromotionDistributor(payload));
		},

		handleRemovePosSinglePromotion(payload: HandleRemovePosSinglePromotionParams) {
			dispatch(middleware.handleRemovePosSinglePromotion(payload));
		},
	};
}
