import { Flex, getThemeMedia, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const PolicyContentHeading = styled(Flex)`
	h1,
	h2 {
		margin-bottom: ${getThemeSpace('16')};
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			h1 {
				margin-bottom: ${getThemeSpace('32')};
			}
		}
	}
`;

export const Block = styled.section`
	display: flex;
	flex-direction: column;
	gap: ${getThemeSpace('24')};
	position: relative;

	margin: 0 0 ${getThemeSpace('32')};
	padding: 0 0 ${getThemeSpace('32')};

	&::before {
		position: absolute;
		bottom: 0;

		border-bottom: 1px solid var(--border-primary);

		min-width: 100%;

		content: '';
	}

	h2 {
		margin-bottom: 6px;

		text-align: left;
	}

	ul {
		list-style: none;

		li {
			padding-left: 32px;

			&::before {
				position: absolute;
				left: 16px;

				color: var(--text-primary);
				font-weight: bold;
				content: '•';
			}
		}
	}
`;

export const SideMenuWrapper = styled(Flex)`
	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: -40px;

		width: 40px;
		height: 40px;

		border-radius: 999px;

		box-shadow: -28px -28px 0 10px var(--neutral-white);
	}

	& > * {
		z-index: 11;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		height: 100vh;
		margin-left: -100%;
		padding: 100%;
		background-color: var(--surface-white);
		z-index: 10;
	}
`;
