import { Dispatch } from '@reduxjs/toolkit';
import { setFeatures, setResetFeatures } from './actions';
import { FeaturesReducer } from './interfaces';

export const handleSetFeatures = (key: keyof FeaturesReducer, value: unknown): any => {
	return (dispatch: Dispatch) => {
		dispatch(setFeatures(key, value));
	};
};

export const handleSetResetFeatures = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(setResetFeatures());
	};
};
