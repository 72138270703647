import { Flex, Grid, Typography } from 'front-commons/ds';
import { useEffect } from 'react';
import DistributorsSelector from 'containers/Distributors/DistributorsSelector';
import TrashButton from 'containers/TrashButton';
import { ChangeDistributorProductCardProps } from './interfaces';

export default function ChangeDistributorProductCard({
	product,
	massiveDistributorId,
	basketSelectedDistributorId,
	onDistributorSelect,
	distributor,
	onRemove,
	isDeleting,
}: ChangeDistributorProductCardProps) {
	const isPromotion = product.productType === 'COMBO';
	const hasOneItemInPromotionOrIsSimple =
		(isPromotion && product?.productsInCombo && product.productsInCombo.length === 1) || !isPromotion;

	const initialDistributor = product.distributors.find(
		({ distributorId }) => distributorId === basketSelectedDistributorId,
	);

	useEffect(() => {
		const massiveDistributor = product.distributors.find(({ distributorId }) => massiveDistributorId === distributorId);
		onDistributorSelect(product.id, massiveDistributor || initialDistributor!);
	}, [massiveDistributorId, initialDistributor]);

	return (
		<Flex
			width="100%"
			direction={{ small: 'column', medium: 'row' }}
			justifyContent="space-between"
			alignItems={{ small: 'flex-start', medium: 'center' }}
			rowGap={{ small: '16px', medium: undefined }}
		>
			<Flex direction="column" gap="0px" justifyContent="space-between" maxWidth="209px">
				<Typography variant="ParagraphSmall/Regular" color="--text-secondary">
					{hasOneItemInPromotionOrIsSimple
						? `EAN: ${isPromotion ? product.productsInCombo?.[0].productInfo.ean13 : product.ean13}`
						: product.description.capitalize()}
				</Typography>
				<Typography variant="ParagraphSmall/Regular">
					{/* @ts-ignore */}
					{isPromotion && product?.moreAbout ? product.moreAbout : product.description.capitalize()}
				</Typography>
				<Typography variant="ParagraphSmall/Regular">
					<strong>{!!distributor?.unitPrice && distributor.unitPrice.toCurrency()}</strong>
					{!!distributor?.taxSubstitution && ` + ${distributor.taxSubstitution.toCurrency()}`}
				</Typography>
			</Flex>

			<Flex gap="32px" width="fit-content">
				<Grid columns={{ small: '272px', medium: '268px' }}>
					<DistributorsSelector
						data={product.distributors.filter(({ status }) => status !== 'UNAVAILABLE')}
						onChange={(selected) => {
							onDistributorSelect(product.id, selected);
						}}
						initialValue={distributor}
						size="small"
						watchInitialValue
						showFooter={false}
						disabled={isDeleting}
						permitEmptyValue
					/>
				</Grid>
				<TrashButton variant="none" onClick={() => onRemove(product.id)} disabled={isDeleting} />
			</Flex>
		</Flex>
	);
}
