import { getThemeMedia, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const ErrorContainer = styled.div`
	position: relative;

	margin: ${getThemeSpace('56')} auto ${getThemeSpace('82')};
	width: 100%;
	max-width: 1216px;
	min-height: 300px;
	padding: 0 ${getThemeSpace('16')};

	@media screen {
		@media (min-width: 576px) {
			margin-top: ${getThemeSpace('82')};
		}

		@media (min-width: ${getThemeMedia('medium')}) {
			display: flex;
			align-items: flex-start;
			gap: ${getThemeSpace('16')};
		}

		@media (min-width: 1248px) {
			gap: ${getThemeSpace('82')};

			min-height: 400px;
			margin-top: ${getThemeSpace('90')};
		}
	}
`;

export const ErrorImageWrapper = styled.div`
	img {
		position: absolute;
		right: 8px;
		top: -48px;

		width: 136px;
	}

	@media screen {
		@media (min-width: 576px) {
			img {
				right: 24px;
				top: -76px;

				width: 168px;
			}
		}

		@media (min-width: ${getThemeMedia('medium')}) {
			img {
				position: static;

				width: 256px;
			}
		}

		@media (min-width: 1248px) {
			position: relative;

			width: 100%;
			max-width: 510px;

			img {
				position: absolute;

				min-width: 564px;
			}
		}
	}
`;
