export const data = [
	{
		title: 'A Hypera',
		content: [
			{
				link: 'https://www.hypera.com.br/',
				text: 'A Hypera Pharma',
				isTarget: true,
			},
			{
				link: 'https://www.hypera.com.br/nossas-marcas/',
				text: 'Nossas marcas',
				isTarget: true,
			},
			{
				link: '/sobre-nos',
				text: 'Parceiro Hypera',
				isTarget: false,
			},
			{
				link: '/parcerias',
				text: 'Parcerias',
				isTarget: false,
			},
			{
				link: '/fidelidade',
				text: 'Fidelidade',
				isTarget: false,
			},
		],
	},
	{
		title: 'Suporte',
		content: [
			{
				link: '/perguntas-frequentes',
				text: 'FAQ',
				isTarget: false,
			},
			{
				link: '/politicas-de-privacidade',
				text: 'Política de privacidade',
				isTarget: false,
			},
		],
	},
];
