import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button, Flex, getThemeMedia, getThemeShadow, getThemeSpace, Grid } from 'front-commons/ds';
import styled, { css } from 'styled-components';

export const Wrapper = styled(Flex)`
	position: relative;
	height: 100%;
	overflow-y: auto;

	[data-testid="add-to-cart-counter"] {
		height: 40px;
	}
`;

export const HeaderPromotionDetails = styled(Flex)`
	border-bottom: 1px solid var(--surface-primary);
`;

export const FooterPromotionDetails = styled(Flex)`
	box-shadow: inset ${getThemeShadow('1')};

	p {
		text-align: center;
	}
`;

// Skeleton

export const BoxSkeleton = styled(Flex)`
	overflow-y: auto;
`;

export const FlexSkeleton = styled.div<{ alignEnd?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: ${({ alignEnd }) => alignEnd && 'flex-end'};
	flex-wrap: wrap;
	gap: 8px;
`;

export const GridContainer = styled(Grid)`
	height: 100%;
`;

// Total Basket Price

export const Trigger = styled(DropdownMenu.Trigger)`
	width: 100%;
	padding: 0;
`;

export const Portal = styled(DropdownMenu.Portal)``;

export const Content = styled(DropdownMenu.Content)`
	@keyframes downEntrance {
		0% {
			opacity: 0;
			transform: rotateX(-100deg);
			transform-origin: bottom;
		}

		100% {
			opacity: 1;
			transform: rotateX(0deg);
			transform-origin: bottom;
		}
	}

	@keyframes upExit {
		0% {
			opacity: 1;
			transform: rotateX(0deg);
			transform-origin: bottom;
		}

		100% {
			opacity: 0;
			transform: rotateX(70deg);
			transform-origin: bottom;
		}
	}

	box-shadow: inset ${getThemeShadow('1')};
	background-color: var(--surface-white);

	width: 100vw;
	height: auto;
	padding: ${getThemeSpace('16')};

	&[data-state='open'] {
		animation: downEntrance 0.5s ease 0s 1 normal forwards;
	}

	&[data-state='false'] {
		animation: upExit 1s ease 0s 1 normal forwards;
	}

	> span {
		display: none;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			width: 696px;
			height: auto;
			padding: ${getThemeSpace('16')} ${getThemeSpace('24')};
		}
	}
`;

export const WrapperPrice = styled(Flex)`
	span {
		text-align: right;
	}

	p {
		text-align: left;
	}
`;

export const TriggerContainer = styled(Flex)`
	cursor: pointer;
`;

export const WrapperOrdering = styled(Flex)`
	box-shadow: ${getThemeShadow('1')};
`;

export const ButtonSortOption = styled(Button) <{ selected: boolean }>`
	justify-content: flex-start;
	padding: ${getThemeSpace('4')} ${getThemeSpace('16')};

	&:hover {
		background-color: var(--blue-50);
	}

	${({ selected }) =>
		selected &&
		css`
			color: var(--hypera-blue-600);
		`}
`;

export const FlexFilters = styled(Flex)`
	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`;

export const FlexTooltip = styled(Flex)`
	> button {
		pointer-events: auto;
	}

	> div div {
		max-width: 250px;
	}
`;