import { UnavailablePromotionProductsTableProps } from './interfaces';
import { UnavailablePromotionProductsTableContainer } from './styles';

export default function UnavailablePromotionProductsTable({ data }: UnavailablePromotionProductsTableProps) {
	return (
		<UnavailablePromotionProductsTableContainer
			variant="simple"
			size="small"
			columns={{
				description: { name: 'Produto' },
				ean13: { name: 'EAN' },
				quantity: { name: 'Qtd' },
			}}
			rows={data}
			templateColumns={{ small: '118px 112px 64px', medium: 'minmax(310px, 1fr) 120px 112px' }}
		/>
	);
}
