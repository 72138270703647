import axios from 'axios';
import { http } from 'msw';
import { handleReject, handleRequest } from './helpers';
import { CreateAPIConnectionCbProps } from './interfaces';
import { captureException } from '@sentry/react';

export const createAPIConnection = (serviceURL: string, callbacks?: CreateAPIConnectionCbProps) => {
	const api = axios.create({
		baseURL: `${import.meta.env.VITE_API_BASE}${serviceURL}`,
	});

	api.interceptors.request.use(
		(config) => handleRequest(config, callbacks?.onRequest),
		(error) => {
			return Promise.reject(error);
		},
	);

	api.interceptors.response.use(
		(response) => {
			return response;
		},
		(response) => {
			callbacks?.onReject?.(response);
			handleReject(response);
			captureException(response);
		},
	);

	return api;
};

export const intercept = {
	get(pathname: string, mockFn: (responseParams?: MockResponse) => MockReturn) {
		return http.get(`${import.meta.env.VITE_API_BASE}${pathname}`, mockFn);
	},
	post(pathname: string, mockFn: (responseParams?: MockResponse) => MockReturn) {
		return http.post(`${import.meta.env.VITE_API_BASE}${pathname}`, mockFn);
	},
	put(pathname: string, mockFn: (responseParams?: MockResponse) => MockReturn) {
		return http.put(`${import.meta.env.VITE_API_BASE}${pathname}`, mockFn);
	},
	delete(pathname: string, mockFn: (responseParams?: MockResponse) => MockReturn) {
		return http.delete(`${import.meta.env.VITE_API_BASE}${pathname}`, mockFn);
	},
};
