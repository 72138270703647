import { Flex, Skeleton } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import ValuesSummarySkeleton from 'containers/Summaries/ValuesSummary/Skeleton';

export default function DistributorChangeSkeleton() {
	return (
		<Flex direction="column" padding={{ small: '24px 16px', medium: '24px 64px' }}>
			<Flex alignItems={{ small: 'flex-start', medium: 'center' }} direction={{ small: 'column', medium: 'row' }}>
				<Skeleton height="16px" width="200px" />
				<Skeleton height="32px" width="268px" />
			</Flex>
			<Flex direction="column" margin="16px 0" gap="32px">
				{Array.generate(5).map((index) => (
					<Flex
						key={index}
						justifyContent="space-between"
						alignItems={{ small: 'flex-start', medium: 'center' }}
						direction={{ small: 'column', medium: 'row' }}
						rowGap={{ small: '16px', medium: undefined }}
					>
						<Skeleton height="16px" width="200px" />
						<Flex>
							<Skeleton height="32px" width="268px" />
							<Skeleton type="circle" height="32px" width="268px" />
						</Flex>
					</Flex>
				))}
			</Flex>
			<Flex
				style={{ border: `1px solid ${theme.colors['--border-primary']}`, borderRadius: 4 }}
				direction="column"
				padding="16px"
				gap="8px"
			>
				<Flex alignItems="center" margin="0 0 16px 0" gap="16px">
					<Skeleton height="20px" width="120px" />
					<Skeleton type="circle" height="16px" />
				</Flex>
				<ValuesSummarySkeleton />
			</Flex>
		</Flex>
	);
}
