import { Grid, Logo, getThemeMedia, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const FooterStyle = styled.footer<{ backgroundColor?: string }>`
	position: relative;
	background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'var(--surface-accent-dark)')};

	margin-top: auto;
	z-index: 149;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			min-height: 320px;
		}
	}
`;

export const FooterContainer = styled.div`
	max-width: 1200px;

	margin: auto;

	padding: ${getThemeSpace('32')};
	padding-top: 64px;
	padding-bottom: ${getThemeSpace('40')};
`;

export const LogoStyled = styled(Logo)`
	padding-bottom: ${getThemeSpace('32')};
`;

export const GridList = styled(Grid)`
	align-items: start;
`;
