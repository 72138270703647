import { ErrorInfo, Component } from 'react';
import Error500 from 'pages/Error/Error500';
import { ErrorBoundaryProps, StateParams } from './interfaces';

class ErrorBoundary extends Component<ErrorBoundaryProps, StateParams> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error(error, errorInfo);
	}

	render() {
		const { hasError } = this.state;
		const { children, isGlobalBoundary } = this.props;

		if (hasError) {
			return <Error500 isGlobalBoundary={isGlobalBoundary} />;
		}

		return children;
	}
}

export default ErrorBoundary;
