import { theme } from 'front-commons/ds/core/tokens';
import { useMediaQuery } from 'front-commons/hooks';
import { Suspense, lazy, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SimulatedBanner from 'containers/Banners/SimulatedBanner';
import PosSelector from 'containers/Headers/Header/components/PosSelector';
import CatalogMenu from 'containers/Menus/CatalogMenu';
import useCustomer from 'stores/customer';
import usePos from 'stores/pos';
import { getNavigateToByContext } from './helpers';
import { HeaderProps } from './interfaces';
import { Container, DropdownPharmacySelector } from './styles';

const HeaderBrandSection = lazy(() => import(/* webpackChunkName: "HeaderBrandSection" */ './HeaderBrandSection'));
const HeaderSearchSection = lazy(() => import(/* webpackChunkName: "HeaderSearchSection" */ './HeaderSearchSection'));

export default function Header({ type = 'logged', logoNavigateReload }: HeaderProps) {
	const location = useLocation();
	const navigate = useNavigate();

	const {
		posStore: { selectedPos },
	} = usePos();
	const { customerStore, simulatedViewType } = useCustomer();

	const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.medium})`);

	const showInDynamicMode = useCallback(
		(condition: boolean) => {
			return type === 'dynamic' && condition;
		},
		[type],
	);

	const navigateLogoProps = useMemo(() => {
		const navigateTo = getNavigateToByContext({ customerId: customerStore.data?.id, pharmacyId: selectedPos?.id });

		if (navigateTo === location.pathname)
			return {
				onClick() {
					navigate(0);
				},
			};

		if (logoNavigateReload) {
			return {
				onClick() {
					navigate(navigateTo);
				},
			};
		}

		return {
			href: navigateTo,
		};
	}, [customerStore.data?.id, selectedPos?.id, location.pathname]);

	const isHomeOrDynamicWithPharmacy = useMemo(() => type === 'home' || showInDynamicMode(!!selectedPos?.id), [type]);

	const isSimulatedUser = useMemo(
		() => !!simulatedViewType() && !['/simular-farmacia', '/meu-perfil/dados'].includes(location.pathname),
		[location.pathname],
	);

	const isValidCustomer = !!customerStore.token && customerStore.provider === 'PARCEIRO_HYPERA';

	const canShowBrandSectionMenu = type !== 'not_logged' || showInDynamicMode(true);

	return (
		<Container>
			{isSimulatedUser && <SimulatedBanner />}

			<Suspense fallback={<div />}>
				<HeaderBrandSection
					type={type}
					isTablet={isTablet}
					isCustomerLoading={customerStore.loading}
					logoProps={navigateLogoProps}
					showMenu={isValidCustomer && canShowBrandSectionMenu}
					showImportButton={!isHomeOrDynamicWithPharmacy && !!customerStore.token}
				/>
			</Suspense>

			{isHomeOrDynamicWithPharmacy && (
				<>
					<Suspense fallback={<div />}>
						<HeaderSearchSection
							showPos={isHomeOrDynamicWithPharmacy && isTablet}
							isTablet={isTablet}
							location={location}
							showBasketButton={
								isHomeOrDynamicWithPharmacy &&
								location.pathname !== '/revisar-pedido' &&
								location.pathname !== '/otimizacao-de-pedido'
							}
						/>
					</Suspense>

					<DropdownPharmacySelector>
						<PosSelector />
					</DropdownPharmacySelector>

					<CatalogMenu display={{ small: 'none', medium: 'flex' }} variant="primary" />
				</>
			)}
		</Container>
	);
}
