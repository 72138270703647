import { Typography, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const PrivacyMenuItem = styled(Typography)`
	padding: ${getThemeSpace('8')};
`;

export const PrivacyBlockQuote = styled(Typography)`
	padding: ${getThemeSpace('8')} ${getThemeSpace('16')};
	background-color: var(--surface-white);
`;
