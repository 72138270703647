import { Skeleton, Tag } from 'front-commons/ds';
import { BusinessUnitTagProps } from './interfaces';

export default function BusinessUnitTag({ businessUnitName, loading }: BusinessUnitTagProps) {
	if (loading) return <BusinessUnitTag.Skeleton />;

	return (
		<Tag
			data-testid="business-unit-tag"
			bgColor={businessUnitName?.includes('Marcas') ? '--surface-accent-light' : '--brand-neoQuimica'}
			label={businessUnitName}
			labelOptions={{ color: businessUnitName?.includes('Marcas') ? '--semantic-info-text' : '--text-invert' }}
		/>
	);
}

BusinessUnitTag.Skeleton = () => {
	return <Skeleton data-testid="business-unit-tag-loading" width="100px" height="20px" />;
};
