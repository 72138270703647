import { Flex, Select } from 'front-commons/ds';
import { scroller } from 'react-scroll';
import useDrawer from 'stores/drawer';
import usePolicy from 'stores/policy';
import { PolicyReducer } from 'stores/policy/interfaces';
import { PrivacyMenuItem } from './styles';

export default function PrivacyPolicyMenuDrawer() {
	const { policyStore, atualContent, selectOptions, handleSetCurrentPolicySelected } = usePolicy();
	const { handleCloseDrawer } = useDrawer();

	return (
		<Flex
			direction="column"
			padding={{ small: '24px 16px 16px', medium: '0' }}
			gap="16px"
			width="100%"
			maxWidth={{ medium: '274px' }}
		>
			<Select
				name="policy-type"
				value={policyStore.currentPolicySelected}
				label="Normas de Segurança e Privacidade"
				onChange={({ currentTarget: { value } }) =>
					handleSetCurrentPolicySelected(value as PolicyReducer['currentPolicySelected'])
				}
				options={selectOptions}
				permitClearSelected={false}
			/>

			<Flex direction="column" gap="0px" maxHeight="100%" overflow="auto">
				{atualContent?.items.map(({ label }) => (
					<PrivacyMenuItem
						key={label}
						variant="ParagraphSmall/Semibold"
						as="a"
						href={`#${label.slugify()}`}
						onClick={() => {
							scroller.scrollTo(`${label.slugify()}`, {
								duration: 200,
								delay: 50,
								smooth: true,
								offset: -80,
							});
							handleCloseDrawer('privacy-policy-menu-drawer');
						}}
					>
						{label}
					</PrivacyMenuItem>
				))}
			</Flex>
		</Flex>
	);
}
