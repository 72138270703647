import { BasketSummaryProps } from 'containers/Summaries/ValuesSummary/interfaces';
import { SummaryProps } from './interfaces';

export const UNDO_TIME = 30 * 1000;

export const getValuesSummary = (summary: Record<string, SummaryProps>) => {
	const values = Object.values(summary).reduce(
		(
			acc: Partial<Omit<BasketSummaryProps, 'hideValue'>>,
			{ discount, quantity, currentDistributorId, distributors, basketQuantity, currentValue },
		) => {
			const distributor = distributors?.find(({ distributorId }) => distributorId === currentDistributorId);

			const brute = (distributor?.unitPrice || 0) / basketQuantity;
			const distributorDiscount = distributor?.discount || 0;

			const initialLiquid = brute * ((100 - (discount || distributorDiscount)) / 100) * quantity + (acc?.liquid || 0);
			const liquid = initialLiquid < currentValue ? initialLiquid : currentValue;

			const response = {
				brute: brute * quantity + (acc?.brute || 0),
				liquid: liquid + (acc?.liquid || 0),
			};

			return response;
		},
		{},
	);

	const discountValue = values.brute! - values.liquid!;
	const response = { ...values, discount: discountValue < 0 ? discountValue * -1 : discountValue };

	return response;
};
