import { FidelityReducer } from './interfaces';

export const setLoading = (state: FidelityReducer['loading']) => ({
	type: 'SET_LOADING_FIDELITY',
	payload: { state },
});

export const setLoadingToken = (state: FidelityReducer['loadingToken']) => ({
	type: 'SET_LOADING_FIDELITY_TOKEN',
	payload: { state },
});

export const setIsOwner = (state: FidelityReducer['isOwner']) => ({
	type: 'SET_IS_OWNER',
	payload: { state },
});

export const setToken = (tokenFidelity: FidelityReducer['tokenFidelity']) => ({
	type: 'SET_FIDELITY_TOKEN',
	payload: { tokenFidelity },
});

export const setStatus = (status: FidelityReducer['status']) => ({
	type: 'SET_STATUS',
	payload: { status },
});

export const setFidelityData = (data: FidelityReducer['data']) => ({
	type: 'SET_FIDELITY_DATA',
	payload: { data },
});



export const setRetryFetchData = (date: FidelityReducer['retryFetchData']) => ({
	type: 'RETRY_FETCH_DATA',
	payload: { date },
});

export const clearFidelity = () => ({
	type: 'CLEAR_FIDELITY',
});
