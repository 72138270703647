import { Dispatch } from '@reduxjs/toolkit';
import { setCurrentPolicySelected, setCookiesPolicies } from './actions';
import { PolicyReducer } from './interfaces';

export const handleSetCurrentPolicySelected = (currentPolicySelected: PolicyReducer['currentPolicySelected']): any => {
	return (dispatch: Dispatch) => {
		dispatch(setCurrentPolicySelected(currentPolicySelected));
	};
};

export const handleSetCookiesPolicies = (): any => {
	return (dispatch: Dispatch) => {
		const oneYear = 1000 * 60 * 60 * 24 * 365;
		const expirationDate = new Date().getTime() + oneYear;

		dispatch(setCookiesPolicies({ agreement: true, expirationDate }));
	};
};
