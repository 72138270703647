import { Flex, getThemeAnimation, getThemeMedia, getThemeShadow, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			height: 370px;
		}
	}
`;

export const List = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	max-height: 100%;

	a {
		display: flex;
		height: 40px;
		width: 100%;
		justify-content: space-between;
		padding: ${getThemeSpace('8')};
	}

	&::-webkit-scrollbar-track {
		display: none;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	&::-webkit-scrollbar-thumb {
		display: none;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			min-width: 225px;
		}
	}
`;

export const TriggerContainer = styled(Flex)<{ expanded: boolean }>`
	align-items: center;
	justify-content: center;
	gap: ${getThemeSpace('8')};
	color: var(--text-primary);
	padding: 0px ${getThemeSpace('16')};
	border-radius: 8px 8px 0 0;
	transition: all 300ms ease-in-out;
	border: 1px solid transparent;
	cursor: pointer;
	background: ${({ expanded }) => (expanded ? '#ffffff26 0% 0% no-repeat padding-box;' : 'var(--surface-accent)')};

	&:hover {
		color: var(--text-primary);
		background: #ffffff26 0% 0% no-repeat padding-box;
		border-color: #ffffff80;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			height: 40px;
		}
	}

	${getThemeAnimation().chevron.rotate('i', 'data-state', { initial: 'closed', final: 'open' }, '-180').Parent};
`;

export const BoxButtonScroll = styled.div`
	display: none;
	align-items: center;
	justify-content: center;
	/* position: fixed; */
	height: 28px;
	width: -webkit-fill-available;
	/* bottom: 0; */
	background-color: var(--surface-white);

	button {
		width: 100%;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			display: flex;
		}
	}
`;

export const DropdownMobile = styled.div`
	width: 100%;
	height: calc(100vh - 137px);
	background-color: var(--surface-white);
	position: fixed;
	left: 0px;
	top: 0px;
	transform: translate3d(0px, 135px, 0px);
	min-width: max-content;
	z-index: 999;
`;

export const FlexLeft = styled(Flex)`
	box-shadow: ${getThemeShadow('1')};
`;
