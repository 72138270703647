import { Counter, Flex, Grid, Typography } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import { useMediaQuery } from 'front-commons/hooks';
import { useCallback, useEffect, useState } from 'react';
import ProgressiveDiscount from 'containers/ProgressiveDiscount';
import TagProductDiscount from 'containers/Tags/TagProductDiscount';
import usePromotionContext from 'contexts/Promotion';
import { getPromotionChildQuantityMessage } from 'shared/messages';
import {
	getCurrentRange,
	getDiscountPrice,
	getInitialQuantity,
	getSummaryQuantities,
	getSummaryBrutePrice,
} from './helpers';
import { PromotionProductCardProps } from './interfaces';
import {
	BoxTop,
	BoxPrice,
	BoxPriceInfo,
	ProductDetails,
	ProductCardInfo,
	BoxInfoQuantity,
	WrapperProductCard,
	ProductCardThumbnail,
} from './styles';

export default function PromotionProductCard({
	promotionId,
	product: {
		ranges,
		productId,
		minimumQuantity,
		quantity: productQuantity,
		productInfo: { description, ean13, imageURL },
	},
	fixedAmount,
	mixedRanges,
	rangeByQuantity,
	progressiveDiscount,
	hasValidDistributors,
	quantityInBasket: basketMultiplier,
	hasPermission,
	hasDistributorSelected,
}: PromotionProductCardProps) {
	const { productsInComboPrices, updateSummary, summary, getFlexQuantityInBasket } = usePromotionContext();

	const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.small})`);

	const quantityInBasket = basketMultiplier || 1;
	const price = productsInComboPrices.find((item) => item.productId === productId)?.price!;

	const flexBasketQuantity = getFlexQuantityInBasket(productId, promotionId);
	const [quantity, setQuantity] = useState<number | ''>(
		getInitialQuantity(fixedAmount, minimumQuantity, flexBasketQuantity, productQuantity, hasValidDistributors),
	);
	const [minValue, setMinValue] = useState(false);

	const { discount, taxSubstitution } = getCurrentRange({
		ranges,
		quantity: Number(quantity) * quantityInBasket,
		totalPrice: Number(quantity) * price * quantityInBasket,
		fixedAmount,
		mixedRanges,
		productPrices: getSummaryBrutePrice(summary) * quantityInBasket,
		rangeByQuantity,
		quantityInBasket,
		productQuantities: getSummaryQuantities(summary) * quantityInBasket,
		progressiveDiscount,
	});
	const discountPercentPrice = getDiscountPrice(discount, price);

	useEffect(() => {
		if (minimumQuantity) {
			if (Number(quantity) <= Number(minimumQuantity)) {
				setMinValue(true);
			} else {
				setMinValue(false);
			}
		} else {
			setMinValue(false);
		}
	}, [quantity]);

	const handleRemoveItem = useCallback(() => {
		if (minimumQuantity) {
			if (Number(quantity) > Number(minimumQuantity) && quantity !== '') setQuantity(quantity - 1);
			return;
		}

		if (Number(quantity) > 0 && quantity !== '') setQuantity(quantity - 1);
	}, [quantity, minimumQuantity]);

	useEffect(() => {
		updateSummary({ discount, price, productId, quantity: Number(quantity), tax: taxSubstitution });
	}, [discount, taxSubstitution, quantity, price, productId]);

	useEffect(() => {
		setQuantity(
			getInitialQuantity(fixedAmount, minimumQuantity, flexBasketQuantity, productQuantity, hasValidDistributors),
		);
	}, [fixedAmount, minimumQuantity, flexBasketQuantity, productQuantity, hasValidDistributors]);

	return (
		<WrapperProductCard>
			<Flex direction="column" gap="16px" width="100%">
				<ProductDetails>
					<BoxTop>
						<ProductCardThumbnail>
							<img
								src={`${import.meta.env.VITE_STATIC_STORAGE_BASE}${imageURL}`}
								alt={`Imagem do produto ${description}`}
							/>
						</ProductCardThumbnail>
						{!isTablet && (
							<ProductCardInfo>
								<Typography variant="Paragraph/Semibold" color="--text-primary">
									{description}
								</Typography>
								<Grid columns="auto 20px" alignItems="center" justifyContent="flex-start">
									<Typography variant="ParagraphSmall/Regular">
										<Typography as="span" variant="ParagraphSmall/Semibold">
											EAN:{' '}
										</Typography>
										{ean13}
									</Typography>
								</Grid>
							</ProductCardInfo>
						)}
					</BoxTop>
					<Grid columns={{ small: '1fr 1fr', medium: '1.5fr 128px 185px' }} gap="16px">
						{isTablet && (
							<ProductCardInfo>
								<Typography variant="Paragraph/Semibold" color="--text-primary">
									{description}
								</Typography>
								<Grid columns="auto 20px" alignItems="center" justifyContent="flex-start">
									<Typography variant="ParagraphSmall/Regular">
										<Typography as="span" variant="ParagraphSmall/Semibold">
											EAN:{' '}
										</Typography>
										{ean13}
									</Typography>
								</Grid>
							</ProductCardInfo>
						)}
						<BoxInfoQuantity>
							<Counter
								hasPermission={hasPermission}
								width="100%"
								quantity={!price && hasValidDistributors ? 0 : quantity}
								onAdd={() => setQuantity(Number(quantity) + 1)}
								onBlur={(value) =>
									minimumQuantity && Number(value) < Number(minimumQuantity) && setQuantity(minimumQuantity)
								}
								onRemove={() => handleRemoveItem()}
								onChange={(value) => setQuantity(value)}
								maxWidth={{ small: '124px', medium: '128px' }}
								disabled={!hasDistributorSelected || fixedAmount || !hasValidDistributors}
								hasMiminumQuantity={minValue}
								initialQuantity={!price && hasValidDistributors ? 0 : quantity}
							/>
							<Typography variant="ParagraphSmall/Regular">
								{getPromotionChildQuantityMessage({
									fixedAmount,
									quantity: productQuantity || 1,
									price,
									minimumQuantity,
									hasValidDistributors,
								})}
							</Typography>
						</BoxInfoQuantity>
						<BoxPrice>
							{!!(discountPercentPrice * Number(quantity)) && (
								<>
									<BoxPriceInfo>
										<Typography variant={{ small: 'ParagraphSmall/Regular', medium: 'Paragraph/Regular' }}>
											De {(Number(quantity) * price).toCurrency()}
										</Typography>
										{!!discount && <TagProductDiscount discount={discount} />}
									</BoxPriceInfo>
									<BoxPriceInfo className="final_price">
										<Typography variant={{ small: 'Paragraph/Semibold', medium: 'Subtitle' }}>
											Por {(Number(quantity) * discountPercentPrice).toCurrency()}
										</Typography>
									</BoxPriceInfo>
									<Typography variant={{ small: 'ParagraphSmall/Regular', medium: 'Paragraph/Regular' }}>
										<strong>+ {!!taxSubstitution && (Number(quantity) * taxSubstitution).toCurrency()} </strong> imposto
									</Typography>
								</>
							)}
						</BoxPrice>
					</Grid>
				</ProductDetails>
				{ranges?.length && progressiveDiscount && (!fixedAmount || progressiveDiscount) && (
					<ProgressiveDiscount
						data={{ values: ranges, type: rangeByQuantity ? 'quantity' : 'currency' }}
						currentRangeReference={
							rangeByQuantity ? Number(quantity) * quantityInBasket : Number(quantity) * price * quantityInBasket
						}
						contained
					/>
				)}
			</Flex>
		</WrapperProductCard>
	);
}
