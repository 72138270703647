import Error from '.';

export default function Error404() {
	return (
		<Error
			errorType="404"
			subtitle="Não encontramos essa página..."
			description="Pedimos desculpas. Por favor, faça uma nova busca ou entre em contato:"
		/>
	);
}
