import { Typography } from 'front-commons/ds';
import { Wrapper } from './styles';
import { TagCampaignLimiterProps } from './interfaces';

export default function TagCampaignLimiter({ availableLimit, alignment = 'center' }: TagCampaignLimiterProps) {
	return (
		<Wrapper
			direction="column"
			gap="0px"
			borderRadius="8px"
			padding="8px"
			justifyContent="center"
			alignItems={alignment}
		>
			{availableLimit != null && availableLimit > 0 ? (
				<>
					<Typography variant="Paragraph/Semibold">
						{availableLimit}
						<Typography variant="ParagraphSmall/Regular"> compras restantes</Typography>
					</Typography>
				</>
			) : (
				<>
					<Typography variant="Paragraph/Semibold">Esta promoção atingiu o limite</Typography>
				</>
			)}
			<Typography variant="Caption/Regular">Promoção com quantidade de compra limitada</Typography>
		</Wrapper>
	);
}
