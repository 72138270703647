import * as Dialog from '@radix-ui/react-dialog';
import { getThemeMedia } from 'front-commons/ds';
import styled from 'styled-components';

export const Content = styled(Dialog.Content)`
	transform: translateX(-50%);

	position: fixed;
	bottom: 20px;
	left: 50%;
	z-index: 1000;

	padding: 20px;

	border-radius: 8px;

	width: calc(100% - 30px);
	max-width: 1144px;

	overflow: hidden;

	box-shadow: 0 0 4px rgba(0, 0, 0, 0.25); // caso especial

	background-color: var(--surface-white);

	@media screen {
		@media (min-width: ${getThemeMedia('large')}) {
			padding: 20px 99px;
		}
	}
`;

export const Description = styled(Dialog.Description)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;

	text-align: center;

	@media screen {
		@media (min-width: ${getThemeMedia('large')}) {
			flex-direction: row;
			justify-content: space-between;

			text-align: left;
		}
	}

	span,
	a {
		color: var(--text-primary);
	}
	a {
		text-decoration: underline;
	}
`;
