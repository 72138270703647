import { ItemSuggestionCardProps } from './interfaces';
import PromotionProduct from './PromotionProduct';
import SimpleProduct from './SimpleProduct';

export default function ItemSuggestionCard({
	promotionData,
	simpleProductData,
	selectedDistributor,
	itemState,
	updateSummary,
	promotionHandlers,
}: ItemSuggestionCardProps) {
	if (promotionData && selectedDistributor && promotionHandlers && simpleProductData && itemState)
		return (
			<PromotionProduct
				promotionHandlers={promotionHandlers}
				data={promotionData}
				selectedDistributor={selectedDistributor}
				simpleProductData={simpleProductData}
				updateSummary={updateSummary!}
				itemState={itemState}
			/>
		);

	return <SimpleProduct data={simpleProductData!} />;
}
