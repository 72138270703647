import { GetProgressPercentageParams } from './interfaces';

export const getPercentage = (current: number, max: number, min?: number) => {
	if (min && current + min < min) return 0;
	return (current / max) * 100;
};

export const getProgressPercentage = ({ max, current, min }: Omit<GetProgressPercentageParams, 'missionNumber'>) => {
	const percentage = getPercentage(current, max, min);

	if (max === 0) return 0;

	return percentage < 10 ? 10 : percentage;
};
