import { CustomerDataResponse } from 'services/customer/interfaces';
import { CustomerReducer } from './interfaces';

export const initialState: CustomerReducer = {
	loading: false,
	token: null,
	data: {} as CustomerDataResponse,
	userIsLoggingOut: false,
	provider: null,
	firstAccess: [],
};

export default function customerReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_CUSTOMER': {
			return { ...state, data: payload.customer as CustomerReducer['data'] };
		}

		case 'SET_LOADING': {
			return { ...state, loading: payload.loading as CustomerReducer['loading'] };
		}

		case 'SET_PARTIALLY_UPDATE': {
			return { ...state, data: { ...state.data, ...(payload.customer as CustomerReducer['data']) } };
		}

		case 'SET_TOKEN': {
			return { ...state, token: payload.token as CustomerReducer['token'] };
		}

		case 'SET_PROVIDER': {
			return { ...state, provider: payload.provider as CustomerReducer['provider'] };
		}

		case 'RESET_USER_IS_LOGGING_OUT': {
			return { ...state, userIsLoggingOut: false };
		}

		case 'SET_LOGOUT': {
			return { ...initialState, userIsLoggingOut: !!payload.userIsLoggingOut };
		}

		case 'SET_2FA_EXPIRATION': {
			return { ...state, data: { ...state.data, twoFAExpiration: payload.timestamp } };
		}

		case 'MANAGE_FIRST_ACCESS': {
			const newFirstAccess = new Set(state.firstAccess);
			// @ts-expect-error
			newFirstAccess[payload.action.toLowerCase()](payload.item);
			return { ...state, firstAccess: Array.from(newFirstAccess) };
		}

		default:
			return state;
	}
}
