import { ajaxListenerConfirmation, fallbackInsiderObject } from './helpers';
import { InsiderPageType, InsiderPageTypes, InsiderUserParams } from './interfaces';

const insiderTagReinitializer = () => {
  window?.Insider?.eventManager?.dispatch('init-manager:re-initialize');
};

const insiderPageDispatcher = (type: InsiderPageTypes) => {
  fallbackInsiderObject();

  window.insider_object.page = {
    type,
  };

  if (type === InsiderPageType.CONFIRMATION) {
    ajaxListenerConfirmation(function (url: string, _: any, method: string) {
      if (
        method.toLowerCase() === 'post' &&
        url.indexOf('/hit') > -1 &&
        window.insider_object.page.type === InsiderPageType.CONFIRMATION
      ) {
        window.insider_object = {
          page: {
            type: InsiderPageType.OTHER,
          },
        };
      }
    });
  }
};

const insiderUserDispatcher = (user: InsiderUserParams) => {
  fallbackInsiderObject();

  window.insider_object.user = {
    ...window.insider_object.user,
    ...user,
    gdpr_optin: true,
  };

  if (user.email) {
    insiderTagReinitializer();
  }
};

const insiderClearUserDispatcher = () => {
  fallbackInsiderObject();

  window.insider_object.user = {};
};

export const insiderDispatcher = {
  page: insiderPageDispatcher,
  reinitialize: insiderTagReinitializer,
  user: insiderUserDispatcher,
  clearUser: insiderClearUserDispatcher,
};
