import { DrawersId, RegisteredDrawerParams } from './interfaces';

export const setCloseDrawer = (drawer: Partial<RegisteredDrawerParams>) => ({
	type: 'SET_CLOSE_DRAWER',
	payload: { drawer },
});

export const setCloseAllDrawers = () => ({
	type: 'SET_CLOSE_ALL_DRAWERS',
});

export const setOpenDrawer = <T>(id: string, props: { data?: T; onGoBack?(): void, showReturnButton?: boolean }) => ({
	type: 'SET_OPEN_DRAWER',
	payload: { drawer: { id, props } },
});

export const setPrepareCloseDrawer = (id: string) => ({
	type: 'SET_PREPARE_CLOSE_DRAWER',
	payload: { drawer: { id } },
});

export const setDynamicTitle = (id: DrawersId, title: React.ReactNode) => ({
	type: 'SET_DYNAMIC_TITLE',
	payload: { drawer: { id, title } },
});

export const setDynamicSubtitle = (id: string, text: string) => ({
	type: 'SET_DYNAMIC_SUBTITLE',
	payload: { drawer: { id, text } },
});

export const setUpdateDrawerProp = (id: DrawersId, props: Partial<Omit<RegisteredDrawerParams, 'id'>>) => ({
	type: 'SET_UPDATE_DRAWER_PROP',
	payload: { drawer: { id, props } },
});
