export const CONTACT_INFO = {
	phones: {
		whatsapp: {
			raw: '551136274401',

			get masked() {
				return this.raw.slice(2).replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
			},

			get link() {
				return `https://wa.me/${this.raw}`;
			},
		},

		call: {
			raw: '08005750020',

			get masked() {
				return this.raw.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
			},

			get link() {
				return `tel:${this.raw}`;
			},
		},
	},

	email: {
		raw: 'atendimento.parceiro@hypera.com.br',

		get link() {
			return `mailto:${this.raw}`;
		},
	},

	openingHours: 'Segunda a sexta-feira das 9:00 às 20:00',
};
