import { createAPIConnection } from 'services';
import { GetPointsResponse, GetTokenResponse } from './interfaces';
import { requestHandler } from '../helpers';

const api = createAPIConnection('/fidelity', {
	onReject() {
		throw new Error('Erro ao se comunicar com o serviço de fidelidade');
	},
});

export const getToken = async (posId: string, onlyValidate: boolean) => {
	return requestHandler<GetTokenResponse>(api.get(`/${posId}/validate?onlyValidate=${onlyValidate}`));
};

export const getPoints = async (posId: string) => {
	return requestHandler<GetPointsResponse>(api.get(`/${posId}`));
};
