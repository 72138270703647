import { BasketReducer } from './interfaces';

export const setBasket = (basket: BasketReducer['basket']) => ({
	type: 'SET_BASKET',
	payload: { basket },
});

export const setLoading = (loading: BasketReducer['loading']) => ({
	type: 'SET_BASKET_LOADING',
	payload: { loading },
});

export const setUnavailable = (unavailable: BasketReducer['unavailable']) => ({
	type: 'SET_BASKET_UNAVAILABLE',
	payload: { unavailable },
});

export const setChangedUnavailable = (changedUnavailable: BasketReducer['changedUnavailable']) => ({
	type: 'SET_BASKET_CHANGED_UNAVAILABLE',
	payload: { changedUnavailable },
});

export const setValidTill = (validTill: BasketReducer['validTill']) => ({
	type: 'SET_BASKET_VALID_TILL',
	payload: { validTill },
});

export const setBasketCheckedState = (checkedBaskets: BasketReducer['checkedBaskets']) => ({
	type: 'SET_BASKET_CHECKED_STATE',
	payload: { checkedBaskets },
});

export const setBasketDeleting = (deleting: BasketReducer['deleting']) => ({
	type: 'SET_BASKET_DELETING',
	payload: { deleting },
});
