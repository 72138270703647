import { Grid } from 'front-commons/ds';
import { useState } from 'react';
import { DragDropContext, Droppable, DropResult, DragStart } from 'react-beautiful-dnd';
import AvailableColumn from './AvailableColumn';
import { handleDropAction } from './helpers';
import { ColumnsProps } from './interfaces';
import SelectedColumn from './SelectedColumn';

export default function DistributorsDragAndDrop({
	isDrawer,
	selectedProps,
	availableProps,
	onAdd,
	onRemove,
	onReorder,
}: ColumnsProps) {
	const [isAvailableDisabled, setIsAvailableDisabled] = useState(false);
	const [applyMinHeight, setApplyMinHeight] = useState(false);

	const handleDrop = ({ draggableId, destination, source }: DropResult) => {
		setIsAvailableDisabled(false);
		if (availableProps.isPed) setApplyMinHeight(false);

		if (!destination) return;

		const action = handleDropAction(source, destination);

		if (action === 'ADD_CARD') onAdd(draggableId, destination?.index);
		if (action === 'REMOVE_CARD') onRemove(draggableId);
		if (action === 'CHANGE_ORDER_CARD') onReorder(source.index, destination.index);
	};

	const handleDrag = ({ source }: DragStart) => {
		if (source.droppableId === 'AVAILABLE_COL') setIsAvailableDisabled(true);

		if (source.droppableId === 'SELECTED_COL' && availableProps.isPed) {
			setApplyMinHeight(true);
		}
	};

	return (
		<DragDropContext onDragEnd={handleDrop} onDragStart={handleDrag}>
			<Grid
				gap={{ small: '16px', medium: isDrawer ? '24px' : '48px' }}
				columns={{ small: '1fr', medium: 'repeat(2, 1fr)' }}
			>
				<Droppable droppableId="AVAILABLE_COL" isDropDisabled={isAvailableDisabled}>
					{(dropProvided) => (
						<AvailableColumn {...availableProps} dropProvided={dropProvided} applyMinHeight={applyMinHeight} />
					)}
				</Droppable>
				<Droppable droppableId="SELECTED_COL">
					{(dropProvided) => <SelectedColumn {...selectedProps} dropProvided={dropProvided} />}
				</Droppable>
			</Grid>
			{selectedProps?.CustomCTA}
		</DragDropContext>
	);
}
