import { Root } from '@radix-ui/react-accordion';
import { Counter, Flex, Typography } from 'front-commons/ds';
import { useEffect, useMemo, useState } from 'react';
import ProgressiveDiscount from 'containers/ProgressiveDiscount';
import useDrawer from 'stores/drawer';
import { PromotionProductProps } from './interfaces';
import { PromotionInfo, Item, Thumbnail } from './styles';
import { getCurrentRange } from '../PromotionProductCard/helpers';

export default function PromotionProduct({
	data,
	simpleProductData,
	selectedDistributor,
	itemState,
	updateSummary,
	promotionHandlers,
}: PromotionProductProps) {
	const { drawerStore } = useDrawer();

	const {
		productsInCombo,
		description,
		moreAbout,
		fixedAmount,
		hasRangeProduct,
		rangeByQuantity,
		progressiveDiscount,
		progressiveDiscountProductMix,
		discountToApply,
	} = data;

	const [
		{
			productInfo: { imageURL },
			minimumQuantity = 0,
			ranges: childRanges,
		},
	] = productsInCombo;
	const ranges = data.ranges.length ? data.ranges : childRanges;
	const singleRange = ranges.length === 1;

	const simpleQuantity = useMemo(() => {
		if (fixedAmount) return 1;
		if (minimumQuantity > simpleProductData.quantity) return minimumQuantity;

		return simpleProductData.quantity;
	}, [simpleProductData.quantity, minimumQuantity, drawerStore]);

	const drawerState = useMemo(() => {
		return drawerStore.drawers.find((drawer) => drawer.id === 'promotion-suggestion')!.open;
	}, [drawerStore]);

	const [quantity, setQuantity] = useState(simpleQuantity || 1);
	const [discount, setDiscount] = useState(0);

	const counterMinQuantity = quantity === (fixedAmount ? 1 : minimumQuantity || 1);
	const unityPrice =
		(selectedDistributor.unitPrice! / (fixedAmount ? 1 : simpleProductData.quantity)) * ((100 - discount) / 100);

	const handleQuantityChange = (operation: 'add' | 'remove' | number) => {
		setQuantity((prevState) => {
			const getNewQuantity = () => {
				if (typeof operation === 'number') return operation;

				return prevState + (operation === 'add' ? 1 : -1);
			};

			const newQuantity = getNewQuantity();

			promotionHandlers(simpleProductData.id).set.quantity(newQuantity);

			return newQuantity;
		});
	};

	useEffect(() => {
		const rangeData = getCurrentRange({
			ranges,
			mixedRanges: ranges,

			fixedAmount,
			hasRangeProduct,
			rangeByQuantity,
			progressiveDiscount,
			progressiveDiscountProductMix,

			productPrices: unityPrice,
			totalPrice: unityPrice * quantity,

			allProductQuantities: quantity,
			quantityInBasket: fixedAmount ? quantity * minimumQuantity : quantity,
			updatedProductQuantity: quantity,
		});

		const newDiscount = ranges?.length ? rangeData.discount : discountToApply;

		updateSummary(simpleProductData.id, {
			quantity,
			discount: newDiscount,
			currentValue: unityPrice * quantity,
		});

		setDiscount(newDiscount);
	}, [quantity]);

	useEffect(() => {
		setQuantity(simpleQuantity);
		promotionHandlers(simpleProductData.id).set.quantity(simpleQuantity);
	}, [drawerState]);

	return (
		<>
			{!singleRange && itemState === 'READY' && (
				<ProgressiveDiscount
					contained
					data={{ type: rangeByQuantity ? 'quantity' : 'currency', values: ranges }}
					currentRangeReference={quantity}
					maxDiscountInfo
				/>
			)}
			<Root type="multiple">
				<Item value="COMBO">
					<Flex
						position="absolute"
						top="0"
						left="0"
						backgroundColor="--semantic-success-base"
						borderRadius="0 4px 4px 4px"
						padding="0 8px"
						height="24px"
						alignItems="center"
						justifyContent="center"
					>
						<Typography color="--text-invert" variant="ParagraphSmall/Regular">
							Combo
						</Typography>
					</Flex>
					<PromotionInfo>
						<Flex gap="16px">
							<Thumbnail>
								<img
									src={`${import.meta.env.VITE_STATIC_STORAGE_BASE}${imageURL}`}
									alt={`Imagem do produto ${description}`}
								/>
							</Thumbnail>
							<Flex direction="column" gap="0px" maxWidth="160px">
								<Typography
									as="span"
									variant="ParagraphSmall/Regular"
									color="--text-primary"
									whiteSpace="nowrap"
									textEllipsis={1}
									title={moreAbout.capitalize()}
								>
									{moreAbout.capitalize()}
								</Typography>
								<Flex as={Typography} variant="ParagraphSmall/Regular" display="block">
									{description.capitalize()}
								</Flex>
							</Flex>
						</Flex>
						<Flex direction="column" alignItems="flex-start" gap="8px" margin={{ small: 'auto auto', medium: 'none' }}>
							<Typography>
								<strong>{(unityPrice * quantity).toCurrency()}</strong> valor do combo
							</Typography>
							<Counter
								hasPermission
								quantity={quantity}
								onAdd={() => handleQuantityChange('add')}
								onRemove={() => handleQuantityChange('remove')}
								onChange={(newQuantity) => handleQuantityChange(Number(newQuantity))}
								width="140px"
								hasMiminumQuantity={counterMinQuantity}
								disabled={itemState !== 'READY'}
							/>
							<Typography variant="ParagraphSmall/Regular">Valor unitário {unityPrice?.toCurrency()}</Typography>
						</Flex>
					</PromotionInfo>
				</Item>
				{singleRange && (
					<Flex
						backgroundColor="sematic-success"
						padding="6px 8px"
						margin="-8px 0 0 0"
						borderRadius="0 0 8px 8px"
						as={Typography}
						variant="ParagraphSmall/Semibold"
						color="neutral0"
					>
						Com {discount}% de desconto
					</Flex>
				)}
			</Root>
		</>
	);
}
