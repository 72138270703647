import * as RadixAccordion from '@radix-ui/react-accordion';
import { Button, Flex, getThemeAnimation, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const Container = styled(Flex)``;

export const Content = styled(Flex)`
	overflow-y: auto;
	flex-direction: column;

	& > :last-child {
		border: none;
	}
`;

export const CTAContainer = styled(Flex)`
	position: sticky;
	display: flex;
	flex-direction: column;
	bottom: 0;

	box-shadow: 2px 2px 8px #33333340;

	background-color: var(--surface-white);
`;

export const IconContainer = styled(Flex)`
	path {
		fill: var(--semantic-success-text) !important;
	}
`;

export const Trigger = styled(RadixAccordion.Trigger)`
	width: 100%;
	padding: 0;

	${getThemeAnimation().chevron.rotate('i', 'data-state', { initial: 'closed', final: 'open' }, '-180').Parent}
`;

export const ValuesContainer = styled(Flex)`
	border-bottom: 1px solid var(--border-primary);
	padding-bottom: ${getThemeSpace('16')};
	margin-bottom: ${getThemeSpace('16')};
`;

export const SummaryRoot = styled(RadixAccordion.Root)`
	${getThemeAnimation().accordion.openClose().Root}
`;

export const SummaryContent = styled(RadixAccordion.Content)`
	${getThemeAnimation().accordion.openClose().Content}
`;

export const SuggestionButton = styled(Button)`
	background-color: var(--semantic-success-base) !important;
`;

export const SuggestionButtonOutlined = styled(Button)`
	background-color: var(--var-neutral-white) !important;
	border: 1px solid var(--semantic-success-base);
	height: 40px !important;

	p {
		color: var(--semantic-success-base) !important;
	}
`;
