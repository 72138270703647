import { Flex, Typography } from 'front-commons/ds';
import IconRotator from 'front-commons/ds/components/Icon/Rotator';
import { TriggerContainer } from './styles';

export default function TotalComboTrigger({ totalPrice, expanded }: { totalPrice: number; expanded: boolean }) {
	return (
		<TriggerContainer alignItems="center" justifyContent="space-between" width="100%">
			<Flex alignItems="center" gap="8px">
				<Typography style={{ fontSize: '18px' }}>
					Total (Preço <strong>Por</strong>)
				</Typography>
				<IconRotator name="expand_less" size="16px" rotate={expanded} />
			</Flex>

			<Typography variant="Paragraph/Semibold" style={{ fontSize: '18px' }}>
				{Number(totalPrice).toCurrency({ removeSpace: true })}
			</Typography>
		</TriggerContainer>
	);
}
