import { Typography } from 'front-commons/ds';
import { TagPromotionBasketProps } from './interfaces';
import { Container } from './styles';

export default function TagComboBasket({ label, bgColor }: TagPromotionBasketProps) {
	return (
		<Container bgColor={bgColor}>
			<Typography color="--text-invert" variant="ParagraphSmall/Semibold">
				{label}
			</Typography>
		</Container>
	);
}
