import { Button, Icon, Typography } from 'front-commons/ds';
import { EmptyBasketProps } from './interfaces';
import { ButtonWrapper, EmptyContainer, EmptyTextContainer } from './styles';

export default function EmptyBasket({ onClick }: EmptyBasketProps) {
	return (
		<EmptyContainer>
			<Icon
				name="shopping_cart"
				color="--semantic-info-text"
				size={{ small: '144px', medium: '72px', large: '96px' }}
			/>

			<EmptyTextContainer>
				<Typography as="h2" color="--text-primary" variant="Headline/H2 Bold">
					Seu carrinho está vazio.
				</Typography>
				<Typography color="--text-primary">
					Você pode navegar pelo menu ou utilizar a busca para encontrar o que deseja.
				</Typography>
			</EmptyTextContainer>

			<ButtonWrapper>
				<Button onClick={onClick} variant="primary" isFullWidth size="large" type="submit">
					Continuar comprando
				</Button>
			</ButtonWrapper>
		</EmptyContainer>
	);
}
