import { DistributorsProps, DistributorStatus } from 'services/products/interfaces';
import DistributorOption from './DistributorOption';
import { DistributorStatusParams, GetDistributorsOptionsParams } from './interfaces';

export const getStatusData = (status: DistributorStatus, stock?: number): DistributorStatusParams => {
	switch (status) {
		case 'AVAILABLE': {
			return {
				label: `Estoque disponível (${stock} produtos)`,
				shape: 'circle',
				text: `${stock}`,
				color: '--semantic-success-text',
				hoverDescription: 'Possível estoque disponível',
			};
		}

		case 'AVAILABLE_STOCK_RISK': {
			return {
				label: `Estoque baixo (${stock} produtos)`,
				shape: 'square',
				text: `${stock}`,
				color: '--semantic-warning-text',
				hoverDescription: 'Possível estoque disponível',
			};
		}

		case 'UNAVAILABLE': {
			return {
				label: 'Estoque indisponível ou não informado',
				shape: 'triangle',
				text: '-',
				color: '--text-disable',
				hoverDescription: 'Sem condição comercial cadastrada',
			};
		}

		default: {
			return {
				label: 'Estoque indisponível ou não informado',
				shape: 'triangle',
				text: '-',
				color: '--semantic-error-text',
				hoverDescription: 'Estoque indisponível ou não informado',
			};
		}
	}
};

export const getDistributorsOptions = ({
	distributorsData,
	state,
	businessUnitName,
	initialValue,
	isPromotionDrawer,
	value,
}: GetDistributorsOptionsParams) => {
	if (initialValue) {
		const distributorIndex = distributorsData.indexOf(initialValue);

		if (distributorIndex !== -1) {
			const removedDistributor = distributorsData.splice(distributorIndex, 1)[0];
			distributorsData.unshift(removedDistributor);
		}
	}

	return distributorsData.map((distributor) => {
		const distributorDisabled = distributor.status === 'UNAVAILABLE';
		return {
			label: (
				<DistributorOption
					key={state.id}
					data={distributor}
					isPromotionDrawer={isPromotionDrawer}
					businessUnitName={businessUnitName}
					isSelected={value?.distributorId === distributor.distributorId}
				/>
			),
			value: JSON.stringify(distributor),
			disabled: distributorDisabled,
			stockStatus: distributor.status,
			stock: distributor.stock || 0,
		};
	});
};

/**
 *	ASC ordering by price and availability
 */
export const orderByLowerPriceAndAvailable = (a: DistributorsProps, b: DistributorsProps) => {
	if (a.status === 'UNAVAILABLE') return 1; // Keep unavailable on last position

	if (!a.finalPrice || !b.finalPrice) return 0; // Ignore positioning when hasnt price

	if (a.finalPrice < b.finalPrice) return -1; // When price `a` is lower than `b` set `a` before `b`

	return 0; // Ignore positioning
};

/**
 *	Keep the current value on top
 */
export const orderBySelectedFirst = (value: DistributorsProps | null) => (a: DistributorsProps) => {
	if (a.distributorId === value?.distributorId) return -1;
	return 0;
};
