import { GetPromotionInfoResponse } from 'services/products/interfaces';
import { DialogState, GetDialogProps } from './interfaces';

export const getDiscountPrice = (discount: number, price: number) => (discount / 100 - 1) * -1 * price;

export const getDialogProps = (state: DialogState, action?: GetDialogProps) => {
	if (state === 'conflict')
		return {
			heading: { title: 'Conflito de produtos' },
			content: {
				description:
					'Já existe esse produto em seu carrinho. Para comprá-lo com outra condição comercial, você deve removê-lo do carrinho.',
			},
			footer: {
				primaryButton: { label: 'Ver carrinho', onClick: action?.handleOpenSideBasket },
				secondaryButton: { label: 'Cancelar' },
			},
		};

	if (state === 'distributor')
		return {
			heading: {
				title: 'Deseja continuar?',
				showCloseButton: true,
			},
			content: {
				description:
					'Atenção! Caso tenha algum pedido em seu carrinho do(s) distribuidor(es) alterado(s), o mesmo será excluído. Deseja continuar?',
			},
			onClose: action?.handleDialogClose,
			footer: {
				primaryButton: { label: 'Continuar', onClick: action?.handleRemovePromotion },
				secondaryButton: { label: 'Ver carrinho', onClick: action?.handleOpenSideBasket },
			},
		};

	if (state === 'no_value')
		return {
			heading: {
				title: 'Combo sem quantidade',
			},
			content: {
				description: 'Adicione a quantidade dos produtos desejada para continuar',
			},
			footer: {
				primaryButton: { label: 'Entendi' },
			},
		};

	return {
		heading: {
			title: 'Atualizar carrinho',
		},
		content: {
			description: 'Foram adicionados mais itens ao seu combo, para prosseguir você deve atualizar o seu carrinho.',
		},
		footer: {
			primaryButton: { label: 'Voltar a promoção' },
		},
	};
};

export const handlerDistributorValidity = (data: GetPromotionInfoResponse) =>
	!!data?.distributors?.length && data?.distributors.some(({ status }) => status !== 'UNAVAILABLE');
