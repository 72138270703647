import { CartIcon, Flex, Typography } from 'front-commons/ds';
import useBasket from 'stores/basket';
import { IconWrapper } from './styles';

export default function SideBasketHeader() {
	const {
		basketStore: { basket: basketData, loading: loadingBasket, deleting },
	} = useBasket();

	const loading = !!((loadingBasket && loadingBasket !== 'full') || deleting);

	return (
		<Flex data-testid="side-basket-drawer" justifyContent="space-between" width="100%">
			<Typography as="h2" color="--semantic-info-text" variant="Paragraph/Semibold" style={{ fontSize: '20px' }}>
				Itens no carrinho
			</Typography>

			<IconWrapper margin={{ small: '-56px 64px 0 0', medium: '-40px 20px 0 0' }}>
				<CartIcon amount={basketData ? basketData.totalItems : 0} loading={loading} />
			</IconWrapper>
		</Flex>
	);
}
