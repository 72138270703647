import { CatalogReducer, CatalogSuggestionsProductParams } from './interfaces';

export const setCatalogLoading = (loading: CatalogReducer['loading']) => ({
	type: 'SET_CATALOG_LOADING',
	payload: { loading },
});

export const setCatalogMenuItems = (catalogMenuItems: CatalogReducer['catalogMenuItems']) => ({
	type: 'SET_CATALOG_MENU_ITEMS',
	payload: { catalogMenuItems },
});

export const setSuggestionsProductNotifierExpiration = (notifierExpiration: CatalogSuggestionsProductParams) => ({
	type: 'SET_SUGGESTIONS_PRODUCT_NOTIFIER_EXPIRATION',
	payload: { notifierExpiration },
});

export const clearSuggestionsProductNotifierExpiration = () => ({
	type: 'CLEAR_SUGGESTIONS_PRODUCT_NOTIFIER_EXPIRATION',
});

export const setLoadingPromotionDetails = (loading: CatalogReducer['promotionDetails']['loading']) => ({
	type: 'SET_CATALOG_LOADING_PROMOTION_DETAILS',
	payload: { loading },
});

export const setIsPromotionActive = (state: CatalogReducer['isPromotionActive']) => ({
	type: 'SET_IS_PROMOTION_ACTIVE',
	payload: {
		state,
	},
});

export const clearCatalog = () => ({
	type: 'CLEAR_CATALOG',
});
