import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingType } from 'containers/Drawers/PromotionDetailDrawerContent/interfaces';
import { notify } from 'front-commons/ds';
import usePos from 'stores/pos';
import { DistributorsProps, GetPromotionInfoResponse } from 'services/products/interfaces';
import { getPromotionInfo, getSearchedProductsDrawerPromotion } from 'services/products';
import { CatalogReducer, GetPromotionDetailsParams, PromotionDetailsSummaryParams } from './interfaces';
import { useDispatch } from 'react-redux';

import { handleLoadingPromotionDetails as loadingPromotionDetails } from './middleware';
import { getDiscountPrice, reorderItems } from 'shared/promotionDrawer';
import useBasket from 'stores/basket';

export default function usePromotionDetails() {
	const [data, setData] = useState({} as GetPromotionInfoResponse);
	const [backupData, setBackupData] = useState({} as GetPromotionInfoResponse);
	const [notFoundProducts, setNotFoundProducts] = useState(false);
	const [loading, setLoading] = useState<LoadingType>('full');
	const [summary, setSummary] = useState<PromotionDetailsSummaryParams[]>([]);
	const [isOpenPromotionDetails, setIsPromotionDetails] = useState(false);
	const [selectedDistributor, setSelectedDistributor] = useState({} as DistributorsProps);

	const dispatch = useDispatch();
	const handleLoadingPromotionDetails = (state?: CatalogReducer['promotionDetails']['loading']) => {
		dispatch(loadingPromotionDetails(state));
	};

	const {
		posStore: { selectedPos },
	} = usePos();

	const {
		basketStore: { basket: basketData },
	} = useBasket();

	const productsInComboPrices = useMemo(() => {
		return data?.productsInCombo?.map(({ productId, distributorsPrice }) => ({
			productId,
			price:
				distributorsPrice.find(({ distributorId }) => distributorId === selectedDistributor?.distributorId)?.price || 0,
		}));
	}, [data, selectedDistributor]);

	const resetPromotionData = () => {
		setSelectedDistributor({} as DistributorsProps);
		setData({} as GetPromotionInfoResponse);
		setBackupData({} as GetPromotionInfoResponse);
		setSummary([]);
	};

	const closePromotionDrawer = async () => {
		setIsPromotionDetails(false);

		await Promise.fake({ delay: 500 });

		resetPromotionData();
		setNotFoundProducts(false);

		handleLoadingPromotionDetails();
	};

	const updateSummary = useCallback(
		(productSummary: PromotionDetailsSummaryParams) => {
			setSummary((prevState) => [
				...(prevState.filter?.(({ productId }) => productId !== productSummary.productId) || []),
				productSummary,
			]);
		},
		[setSummary],
	);

	const getPromotionDetails = async ({ promotionId, loadingType = 'full', posId }: GetPromotionDetailsParams) => {
		const pharmacyId = posId || selectedPos?.id;

		try {
			setLoading(loadingType);
			resetPromotionData();
			setNotFoundProducts(false);
			handleLoadingPromotionDetails(promotionId);

			const promotionInfo = await getPromotionInfo(pharmacyId, promotionId);

			setData(promotionInfo);
			setBackupData(promotionInfo);
		} catch (error) {
			closePromotionDrawer();
			notify.negative({ description: 'Promoção não encontrada' });
		} finally {
			setLoading('');
			handleLoadingPromotionDetails();
		}
	};

	const getProductInComboValues = useCallback(
		(quantityImportPromotion?: number) =>
			summary.reduce(
				({ brute, discount, liquid, tax }, curr) => {
					const quantityInBasket =
						quantityImportPromotion ||
						basketData?.baskets?.flatMap(({ products }) => products).find(({ id }) => id === data?.id)?.quantity ||
						1;

					const totalBrute = curr.quantity * curr.price * quantityInBasket;
					const totalDiscount = curr.quantity * getDiscountPrice(curr.discount, curr.price) * quantityInBasket; // not rounded
					const totalTax = curr.price ? curr.quantity * curr.tax * quantityInBasket : 0;

					return {
						discount: totalBrute - totalDiscount + discount,
						tax: totalTax + tax,
						brute: totalBrute + brute,
						liquid: totalDiscount + totalTax + liquid,
					};
				},
				{
					discount: 0,
					tax: 0,
					brute: 0,
					liquid: 0,
				},
			),
		[summary, basketData],
	);

	const getSearchedProducts = useCallback(
		async (promotionId: string, pharmacyId: string, searchValue: string) => {
			if (searchValue === '') {
				setData(backupData);
				setNotFoundProducts(false);
				return;
			}

			try {
				setLoading('refetch');

				const { productIds } = await getSearchedProductsDrawerPromotion(pharmacyId, promotionId, searchValue);

				if (productIds.length === 0) {
					setData(backupData);
					setNotFoundProducts(true);
				} else {
					setData({
						...backupData,
						productsInCombo: backupData.productsInCombo?.filter((p) => productIds.includes(p.productId)),
					});
					setNotFoundProducts(false);
				}
			} catch (error) {
				notify.negative({ description: 'Produto não encontrado' });
			} finally {
				setLoading('');
			}
		},
		[backupData],
	);

	const handleSortItems = useCallback(
		(sortIdentifier: number) => {
			const reorderedItems = reorderItems(
				data.productsInCombo,
				selectedDistributor.distributorId,
				sortIdentifier,
				summary.filter((i) => i.quantity > 0).map((item) => item.productId),
			);

			setData({ ...backupData, productsInCombo: reorderedItems || backupData.productsInCombo });
		},
		[data, summary, backupData, selectedDistributor],
	);

	const getFlexQuantityInBasket = useCallback(
		(productId: string, promotionId: string) => {
			const allPromotionInBasket = basketData?.baskets
				?.flatMap(({ products }) => products)
				.filter((product) => product.productType === 'COMBO');

			const foundedPromotion = allPromotionInBasket?.find((promotion) => promotion.id === promotionId);
			const foundedProduct = foundedPromotion?.productsInCombo?.find((product) => product.productId === productId);

			return foundedProduct?.quantity;
		},
		[basketData],
	);

	const getProductValidity = useCallback(
		(productId: string) => {
			const valid = data?.productsInCombo
				?.find((p) => p.productId === productId)
				?.distributorsPrice.find((i) => i.distributorId === selectedDistributor?.distributorId)?.valid;

			return valid;
		},
		[data, selectedDistributor],
	);

	useEffect(() => {
		setSummary([]);
	}, [isOpenPromotionDetails]);

	return {
		data,
		loading,
		summary,
		selectedDistributor,
		productsInComboPrices,
		isOpenPromotionDetails,
		notFoundProducts,
		setLoading,
		updateSummary,
		getProductInComboValues,
		closePromotionDrawer,
		getPromotionDetails,
		setSelectedDistributor,
		getFlexQuantityInBasket,
		getProductValidity,
		getSearchedProducts,
		handleSortItems,
	};
}
