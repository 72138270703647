export class CustomError extends Error {
	code: string | undefined;

	statusCode: number | undefined;

	constructor(message: string, code?: string, statusCode?: number) {
		super(message);
		this.code = code;
		this.statusCode = statusCode;
	}
}
