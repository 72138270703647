import { TriggerProps } from '../../interfaces';
import { Container } from './styles';
import { Flex, Icon, Typography } from 'front-commons/ds';
import HeaderSelectorSkeletonsRender from '../Skeleton';

export default function Trigger({ cnpj, name, loading, expanded }: TriggerProps) {
	return (
		<Container
			gap="8px"
			width="100% !important"
			cursor="pointer"
			margin={{ small: '0 0 0 16px', medium: '0' }}
			padding={{ medium: '4px 16px' }}
			position="relative"
			minWidth="268px"
			alignItems="center"
			data-state={expanded ? 'open' : 'closed'}
			data-testid="pharmacy-selector-trigger-content"
			borderRadius="4px"
			backgroundColor={{ medium: expanded ? '--surface-accent-dark' : undefined }}
		>
			<Icon name="store" size={{ small: '24px', medium: '32px' }} />

			<HeaderSelectorSkeletonsRender trigger={loading}>
				<Flex
					gap={{ small: '4px', medium: '0px' }}
					width="100%"
					direction={{ medium: 'column' }}
					alignItems={{ small: 'center', medium: 'start' }}
					title={`${CNPJ(cnpj).mask} ${name.toUpperCase()}`}
				>
					<Typography variant={{ small: 'ParagraphSmall/Regular', medium: 'Caption/Regular' }} whiteSpace="nowrap">
						{CNPJ(cnpj).mask}
					</Typography>
					<Flex display={{ medium: 'none' }} as={Typography} variant="ParagraphSmall/Regular">
						|
					</Flex>
					<Typography
						variant={{ small: 'ParagraphSmall/Regular', medium: 'Paragraph/Semibold' }}
						textEllipsis={1}
						align="start"
					>
						{name.toUpperCase()}
					</Typography>
				</Flex>
			</HeaderSelectorSkeletonsRender>

			<Icon name="expand_more" size="16px" />
		</Container>
	);
}
