import { useSelector, useDispatch } from 'react-redux';
import { HandleOpenDialogProps } from './interfaces';
import * as middleware from './middleware';

export default function useDialog() {
	const dispatch = useDispatch();
	const dialogStore = useSelector((stores: ReduxStore) => stores.dialogReducer);
	const handlePreventDataLoss = {
		isEnabled: !!window.onbeforeunload,

		enable() {
			window.onbeforeunload = (event) => {
				event.preventDefault();
				event.returnValue = true;
			};
		},

		disable() {
			window.onbeforeunload = null;
		},
	}

	return {
		dialogStore,
		handleOpenDialog: async (dialog: HandleOpenDialogProps) => dispatch(middleware.handleOpenDialog(dialog)),
		handleCloseDialog: () => dispatch(middleware.handleCloseDialog()),
		handlePreventDataLoss,
	};
}
