export const text = {
	single: {
		feedback: 'Substituição realizada, desconto aplicado com sucesso.',
		cta: 'Desfazer substituição',
	},
	many: {
		feedback: 'Substituições feitas e descontos aplicados com sucesso.',
		cta: 'Desfazer todas as substituições',
	},
};
