import styled from 'styled-components';
import { TagPromotionBasketProps } from './interfaces';

export const Container = styled.div<Pick<TagPromotionBasketProps, 'bgColor'>>`
	display: flex;
	background-color: var(${({ bgColor }) => bgColor});
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;

	width: 16px;

	text-align: center;
	text-transform: uppercase;

	p {
		transform: rotate(-90deg);
		white-space: nowrap;
	}
`;
