import { SuggestedProductTagProps } from './interfaces';
import { SuggestedProductTagContainer } from './styles';

export default function SuggestedProductTag({
	suggestionGroupName,
	suggestionBackgroundColor = '--semantic-disable-weak',
	suggestionTextColor = '--semantic-disable-text',
	fullRadius,
	ignorePosition,
	width,
}: SuggestedProductTagProps) {
	return (
		<SuggestedProductTagContainer
			bgColor={suggestionBackgroundColor as any}
			color={suggestionTextColor as any}
			fullRadius={fullRadius}
			ignorePosition={ignorePosition}
			width={width}
		>
			{suggestionGroupName}
		</SuggestedProductTagContainer>
	);
}
