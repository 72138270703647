import { Dialog } from 'front-commons/ds';
import useDialog from 'stores/dialog';

export default function DialogsRoot() {
	const { dialogStore } = useDialog();

	if (!dialogStore.dialog) return null;

	return <Dialog isOpen {...dialogStore.dialog} />;
}
