import { Typography } from 'front-commons/ds';
import { DistributorsFiltersExhibitionType, FindPharmaciesResponse } from 'services/pos/interfaces';
import { PosParams } from 'stores/pos/interfaces';
import { BusinessUnitResponse } from './interfaces';

export const selectedDistributorsHandler = (
	selectedDistributors: BusinessUnitResponse[],
	availableDistributors: BusinessUnitResponse[],
) => {
	if (selectedDistributors.length === availableDistributors.length) return selectedDistributors;

	return availableDistributors.map(({ businessUnitId }) => {
		const distributorTurn = selectedDistributors.find((distributor) => distributor.businessUnitId === businessUnitId);
		if (distributorTurn) return distributorTurn;

		return {
			businessUnitId,
			distributors: [],
		};
	}) as BusinessUnitResponse[];
};

export const regionsLabel: Record<UFAcronym, string> = {
	AC: 'Acre',
	AL: 'Alagoas',
	AP: 'Amapá',
	AM: 'Amazonas',
	BA: 'Bahia',
	CE: 'Ceará',
	DF: 'Distrito Federal',
	ES: 'Espírito Santo',
	GO: 'Goiás',
	MA: 'Maranhão',
	MT: 'Mato Grosso',
	MS: 'Mato Grosso do Sul',
	MG: 'Minas Gerais',
	PA: 'Pará',
	PB: 'Paraíba',
	PR: 'Paraná',
	PE: 'Pernambuco',
	PI: 'Piauí',
	RJ: 'Rio de Janeiro',
	RN: 'Rio Grande do Norte',
	RS: 'Rio Grande do Sul',
	RO: 'Rondônia',
	RR: 'Roraima',
	SC: 'Santa Catarina',
	SP: 'São Paulo',
	SE: 'Sergipe',
	TO: 'Tocantins',
};

export const getSimpleSelectedPosData = (posList: FindPharmaciesResponse[] | PosParams[], posId: string) => {
	const posData = posList?.find(({ pointOfSaleId }) => pointOfSaleId === posId);

	if (!posData) return undefined;

	return (
		<Typography title={posData.tradeName}>
			{CNPJ(posData.cnpj).mask} <strong>{posData.tradeName}</strong>
		</Typography>
	);
};

export const getFiltersColumnSize = (
	view: DistributorsFiltersExhibitionType | undefined,
	simulatedViewType: boolean,
	hasFlags: boolean,
) => {
	if (view === 'SIMPLE' || simulatedViewType) return '500px';

	return hasFlags ? '1fr 1fr 2fr' : '1fr 2fr';
};

export const maybeArrayToString = (data: string[] | string | undefined = '') => (Array.isArray(data) ? data[0] : data);
