import { Flex, Shape, Typography } from 'front-commons/ds';
import { getStatusData } from './helpers';
import HoverCard from './HoverCard';
import { DistributorSelectStatusProps } from './interfaces';
import { SelectStatusContainer } from './styles';

export default function DistributorSelectStatus({
	status = 'UNAVAILABLE',
	stock,
	isPromotionDrawer = false,
}: DistributorSelectStatusProps) {
	return (
		<Flex className="select-status">
			<HoverCard
				side="top"
				align="start"
				openDelay={0}
				closeDelay={0}
				content={getStatusData(status!)?.hoverDescription}
				animation="popup"
			>
				<SelectStatusContainer>
					<Shape
						shape={getStatusData(status!)?.shape || 'triangle'}
						height="10px"
						width="10px"
						color={getStatusData(status!)?.color || 'alertError'}
					/>
					{!isPromotionDrawer && (
						<Typography variant="ParagraphSmall/Regular" color="--text-primary">
							{getStatusData(status, stock)?.text}
						</Typography>
					)}
				</SelectStatusContainer>
			</HoverCard>
		</Flex>
	);
}
