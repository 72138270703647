import { Loading } from 'front-commons/ds';
import { LoadingPageContainer } from './styles';

export default function LoadingPage() {
	return (
		<LoadingPageContainer>
			<Loading size="24" color="--semantic-info-text" />
		</LoadingPageContainer>
	);
}
