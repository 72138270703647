import { Flex, getThemeMedia, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const Container = styled.header`
	position: sticky;
	z-index: 101 !important;
`;

export const ContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin: 0 auto;

	max-width: 1216px;
	width: 100%;
`;

export const HeaderBrandSectionContainer = styled.div`
	display: flex;
	align-items: center;

	background-color: var(--surface-white);
	padding: ${getThemeSpace('8')} ${getThemeSpace('16')};

	width: 100%;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			height: 64px;
		}
	}
`;

export const DropdownContainer = styled.div`
	display: none;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			display: flex;
		}
	}
`;

export const IconsContainer = styled(Flex)`
	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			display: none;
		}
	}
`;

export const HeaderSearchSectionContainer = styled.nav`
	display: flex;
	align-items: center;

	background-color: var(--surface-accent);
	padding: ${getThemeSpace('8')} ${getThemeSpace('16')};

	width: 100%;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			height: 64px;
		}
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: ${getThemeSpace('16')};
	margin-left: ${getThemeSpace('16')};

	width: 100%;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			justify-content: flex-end;
		}
	}
`;

export const HeaderCategoriesContainer = styled.div`
	display: flex;
`;

export const DropdownPharmacySelector = styled.div`
	display: flex;
	background-color: var(--surface-accent);

	> button {
		padding: ${getThemeSpace('8')} ${getThemeSpace('16')};
		width: 100%;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			display: none;
		}
	}
`;
