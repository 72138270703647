import { useSelector, useDispatch } from 'react-redux';
import { Redux } from 'stores/interfaces';
import * as middleware from './middleware';
import { DrawersId, RegisteredDrawerParams } from './interfaces';
import { useCallback } from 'react';

export default function useDrawer() {
	const dispatch = useDispatch();
	const drawerStore = useSelector((stores: Redux) => stores.drawerReducer);

	const isDrawerOpen = useCallback(
		(drawerName: DrawersId) => {
			return !!drawerStore.drawers.some((drawer) => drawer.id === drawerName);
		},
		[drawerStore.drawers],
	);

	return {
		drawerStore,
		handleCloseDrawer: (drawerId: DrawersId) => dispatch(middleware.handleSetCloseDrawer(drawerId)),
		handleCloseAllDrawers: () => dispatch(middleware.handleSetCloseAllDrawers()),
		handleOpenDrawer: <T = GenericObject>(drawerId: DrawersId, data?: T) =>
			dispatch(middleware.handleSetOpenDrawer<T>(drawerId, data)),
		handleSetDynamicTitle: (drawerId: DrawersId, title: React.ReactNode) =>
			dispatch(middleware.handleSetDynamicTitle(drawerId, title)),
		handleSetDynamicSubtitle: (drawerId: DrawersId, text: string) =>
			dispatch(middleware.handleSetDynamicSubtitle(drawerId, text)),
		handleUpdateDrawerProps: (drawerId: DrawersId, props: Partial<Omit<RegisteredDrawerParams, 'id'>>) =>
			dispatch(middleware.handleSetUpdateDrawerProp(drawerId, props)),
		isDrawerOpen,
	};
}
