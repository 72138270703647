import { SelectChangeMethodType } from "front-commons/ds/components/Select/interfaces";

export const onMultipleSelectStateHandler = (setter: React.Dispatch<React.SetStateAction<string[]>>, { value, method, allValueOptions }: {
	value: string;
	method?: SelectChangeMethodType;
	allValueOptions: string[]
}
) => {

	if (method === 'ADD_SINGLE') {
		setter((prevState) => ([...prevState, value]))
	}

	if (method === 'REMOVE') {
		setter((prevState) => prevState.filter((item) => item !== value))
	}

	if (method === 'REMOVE') {
		setter((prevState) => prevState.filter((item) => item !== value))
	}

	if (method === 'ADD_ALL') {
		setter(allValueOptions)
	}

	if (method === 'CLEAR') {
		setter([])
	}
}