import { OrderImportReducer } from './interfaces';

const initialState: OrderImportReducer = {
	posPromotions: {},
	loading: '',
	loadingTarget: undefined
};

export default function orderImportReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_ORDER_IMPORT_LOADING': {
			return { ...state, ...payload };
		}

		case 'CLEAR_POS_PROMOTIONS': {
			return { ...state, posPromotions: {} };
		}

		case 'ADD_POS_PROMOTIONS': {
			const prevState = state.posPromotions;
			const posPromotions = {
				...prevState,
				[payload.posId]: {
					...(prevState?.[payload.posId] || {}),
					[payload.promotionId]: {
						...(prevState?.[payload.posId]?.[payload.promotionId] || {}),
						...payload.payload,
					},
				},
			}

			return { ...state, posPromotions };
		}

		case 'CHANGE_POS_PROMOTIONS_DISTRIBUTOR': {
			const prevState = state.posPromotions;
			const posPromotions = {
				...prevState,
				[payload.posId]: {
					...(prevState?.[payload.posId] || {}),
					[payload.promotionId]: {
						...(prevState?.[payload.posId]?.[payload.promotionId] || {}),
						distributorId: payload.distributorId,
					},
				},
			}

			return { ...state, posPromotions };
		}

		case 'REMOVE_POS_SINGLE_PROMOTION': {
			const prevState = state.posPromotions;

			const posPromotions = {
				...prevState,
				[payload.posId]: Object.deleteProp(prevState[payload.posId], payload.promotionId),
			}

			return { ...state, posPromotions };
		}

		default:
			return state;
	}
}
