import { AxiosError } from 'axios';
import { EditPromotionProps } from 'containers/ProductCard/horizontal/PromotionCard/interfaces';
import { EditProps } from 'containers/ProductCard/horizontal/SimpleCard/interfaces';
import { createAPIConnection } from 'services';
import { handleSetLogout } from 'stores/customer/middleware';
import { getStore } from 'stores/helpers';
import { store } from 'stores/store';
import {
	GetImportedBasketsResponse,
	GetOrderImportRevisionResponse,
	GetOrderSummaryInfoResponse,
	GetOrderUploadProgressResponse,
	GetPointOfSaleOrdersResponse,
	GetProductsByPosIdResponse,
	ImportUpdatedLinesParams,
	OrderImportPosDistributorsResponse,
	SetOrderImportResponse,
	UploadOrderResponse,
	ImportsProgressContentDataProps,
	GetImportPromotionDetailsResponse,
	ImportsProgressMessageList,
} from './interfaces';
import { requestHandler } from '../helpers';

const api = createAPIConnection('/import', {
	onRequest(config) {
		const provider = getStore().customerReducer?.provider;

		if (config.url === '/import') Object.assign(config?.headers || {}, { 'content-type': 'multipart/form-data' });
		if (provider === 'ZECA_COPILOTO') Object.assign(config?.headers || {}, { 'Partner-origin': 'zecacopiloto' });
	},

	onReject({ response }) {
		if (
			response.data.code === 406 &&
			response.data.errorDescription.includes('divergente') &&
			getStore().customerReducer.provider !== 'PARCEIRO_HYPERA'
		) {
			store.dispatch(handleSetLogout());
		}
	}
});

export const uploadOrder = async (formData: FormData) =>
	requestHandler<UploadOrderResponse>(api.post('/order', formData));

export const getOrderUploadProgress = async ({ orderImportId }: { orderImportId: string }) =>
	requestHandler<GetOrderUploadProgressResponse>(api.get(`/order/${orderImportId}/status`));

export const getOrderImportRevision = async (orderImportId: string, page: number) => {
	return requestHandler<GetOrderImportRevisionResponse>(
		api.get(`/order/${orderImportId}/revision?page=${page}&size=20`),
	);
};

export const sendUploadedLines = async (orderImportId: string, lineData: ImportUpdatedLinesParams[]) => {
	return requestHandler<GetOrderImportRevisionResponse>(
		api.post('/order/revalidate', {
			orderImportId,
			orderImportProductList: lineData,
		}),
		{ throwData: true },
	);
};

export const sendFinishValidation = async (orderImportId: string, ignoreErrors: boolean) => {
	return requestHandler<GetOrderImportRevisionResponse>(
		api.post(`/order/${orderImportId}/revision/finish`, { ignoreErrors }),
	);
};

export const setOrderImport = async (data: string) => {
	return requestHandler<SetOrderImportResponse>(
		api.post(`order/copy-paste`, { content: data }, { headers: { 'content-type': 'multipart/form-data' } }),
	);
};

export const getOrderSummaryInfo = async (importId: string) =>
	requestHandler<GetOrderSummaryInfoResponse>(api.get(`order/${importId}/summary`));

export const getPointOfSaleOrders = async (importId: string) =>
	requestHandler<GetPointOfSaleOrdersResponse[]>(api.get(`order/${importId}/pos/list`), { throwData: true });

export const createImportBaskets = async (importId: string) =>
	requestHandler(api.post(`order/${importId}/baskets?originMethod=ORDER_IMPORT`), { throwData: true });

export const getImportedBaskets = async (importId: string) =>
	requestHandler<GetImportedBasketsResponse>(api.get(`order/${importId}/baskets`), { throwData: true });

export const getProductsByPosId = async (orderImportPointOfSaleId: string, params: PaginationRequestParams) =>
	requestHandler<GetProductsByPosIdResponse>(api.get(`order/pos/${orderImportPointOfSaleId}/products`, { params }));

export const updateProductByPosId = async (data: EditProps) =>
	requestHandler<{ discount?: number }>(api.put('order/product', data), { throwData: true });

export const updateOrderImportPromotion = async (data: EditPromotionProps) =>
	requestHandler(api.put('order/product', data), { throwData: true });

export const posWithoutDistributors = async ({ orderImportId }: { orderImportId: string }) =>
	requestHandler<OrderImportPosDistributorsResponse>(api.get(`order/${orderImportId}/distributors/pending`), {
		throwData: true,
	});

export const getImportPromotionDetails = async (orderImportProductId: string, promotionId: string) =>
	requestHandler<GetImportPromotionDetailsResponse>(
		api.get(`order/product/${orderImportProductId}/combo/${promotionId}/detail`),
	);

export const posRemove = async ({ orderImportId, orderImportPointOfSaleIds }: { orderImportId: string, orderImportPointOfSaleIds: string[] }) =>
	requestHandler(api.put(`order/${orderImportId}/pointOfSale/ignore`, { orderImportPointOfSaleIds }));

export const getImportsStatusList = async (params: any) => {
	return requestHandler<ImportsProgressContentDataProps>(api.get('order/list', { params }));
};

export const getImportsStatus = async () => requestHandler<ImportsProgressMessageList[]>(api.get(`order/status`));

export const getDataExport = async (orderImportId: string) => {
	const response = await api.get(`order/export/${orderImportId}`, { responseType: 'blob' });

	return response;
};

export const revalidateImport = ({ orderImportId }: { orderImportId: string }) =>
	requestHandler(api.post(`order/${orderImportId}/revalidate-import`));

export const getPosImportData = ({ orderImportId }: { orderImportId: string }) =>
	requestHandler<{
		pointOfSales: {
			id: string;
			cnpj: string;
			name: string;
			region: string;
		}[]
	}>(api.get(`order/${orderImportId}/pos-list`));

export const getImportSuggestion = async (importId: string, navigate: Function) => {
	try {
		const response = await api.get(`order/basket-suggestion/${importId}`);
		if (response.data.code === 200) {
			navigate('/importacao-pedido/otimizacao', { state: { data: response.data?.content, suggestionFrom: 'import', importId } });
			return response.data;
		}
		return { status: response?.data?.code };
	} catch (error) {
		if (error instanceof AxiosError) {
			throw error?.response?.data?.code;
		}
		throw error;
	}
};

export const acceptImportSuggestion = async (importId: string, agnosticImportVersionId: number) => {
	try {
		const response = await api.post(`order/basket-suggestion/${importId}/accept`, {
			agnosticImportVersionId,
		});
		return response.data;
	} catch (error) {
		if (error instanceof AxiosError) {
			throw error?.response?.data?.code;
		}
		throw error;
	}
};
