import { Flex, Typography } from 'front-commons/ds';
import { UpdatePasswordProps } from './interfaces';

export default function UpdatePassword({ email }: UpdatePasswordProps) {
	return (
		<Flex padding={{ small: '0 16px', medium: '0' }}>
			<Typography>
				Enviamos um e-mail para <Typography variant="Paragraph/Semibold">{email}</Typography> contendo as instruções
				necessárias para a alteração da sua senha.
			</Typography>
		</Flex>
	);
}
