import { Button } from 'front-commons/ds';
import { TrashButtonProps } from './interfaces';
import { TrashIcon } from './styles';

export default function TrashButton({ ...buttonProps }: TrashButtonProps) {
	return (
		<Button {...buttonProps}>
			<TrashIcon name="delete" color="--text-primary" />
		</Button>
	);
}
