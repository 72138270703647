import * as Card from '@radix-ui/react-hover-card';
import { HoverCardProps } from './interfaces';
import { Arrow, Content, Trigger } from './styles';

export default function HoverCard({
	children,
	content,
	maxWidth,
	side,
	align,
	openDelay = 700,
	closeDelay = 300,
	animation,
}: HoverCardProps) {
	return (
		<Card.Root openDelay={openDelay} closeDelay={closeDelay}>
			<Trigger>{children}</Trigger>
			<Card.Portal>
				<Content maxWidth={maxWidth} side={side} align={align} animation={animation}>
					{content}
					<Arrow />
				</Content>
			</Card.Portal>
		</Card.Root>
	);
}
