import { Flex, Skeleton } from 'front-commons/ds';
import { memo } from 'react';

function PosList() {
	return (
		<Flex direction="column" gap="16px">
			{Array.generate(4).map((index) => (
				<Flex key={index} gap="8px" alignItems="center">
					<Skeleton width="22px" height="22px" type="circle" />
					<Skeleton height="16px" />
				</Flex>
			))}
		</Flex>
	);
}

function Trigger() {
	return (
		<Flex gap="8px" direction="column" margin="2px 0 0">
			<Flex display={{ small: 'none', medium: 'flex' }}>
				<Skeleton width="98px" height="14px" />
			</Flex>
			<Skeleton height="16px" width="170px" />
		</Flex>
	);
}

function HeaderSelectorSkeletonsRender({
	posList,
	trigger,
	children,
}: { children?: React.ReactElement } & Record<string, unknown>) {
	if (posList) return <PosList />;
	if (trigger) return <Trigger />;

	return children || null;
}

export default memo(HeaderSelectorSkeletonsRender);
