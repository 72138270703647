import * as Accordion from '@radix-ui/react-accordion';
import { getThemeMedia, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const Item = styled(Accordion.Item)`
	position: relative;

	border: 1px solid var(--semantic-success-base);
	border-radius: 4px;
	padding-bottom: ${getThemeSpace('16')};
	padding-top: 38px;
`;

export const PromotionInfo = styled(Accordion.Header)`
	display: flex;
	flex-direction: column;
	gap: ${getThemeSpace('24')};

	padding: 0 ${getThemeSpace('16')};
	width: 100%;

	font-weight: normal;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			flex-direction: row;
			gap: 64px;
		}
	}

	& > * {
		flex: 1;
	}
`;

export const Thumbnail = styled.figure<{ isChildren?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 72px;
	height: 72px;

	img {
		max-width: 72px;
		max-height: 72px;
	}
`;
