import { GetPromotionChildQuantityMessageParams } from './interfaces';

export const getPromotionChildQuantityMessage = ({
	fixedAmount,
	quantity,
	price,
	minimumQuantity,
	hasValidDistributors,
}: GetPromotionChildQuantityMessageParams) => {
	const quantityAbbrev = 'Qtd';
	const defaultMessage = `Sem ${quantityAbbrev.toLowerCase()} mínima`;

	if (fixedAmount) return `${quantityAbbrev} fixa: ${quantity || minimumQuantity}`;

	if (minimumQuantity) return `${quantityAbbrev} mínima: ${minimumQuantity}`;

	if (!price || !hasValidDistributors) return defaultMessage;

	return defaultMessage;
};
