import { Icon, Tooltip, Typography } from 'front-commons/ds';
import { AccordionProgressProps } from './interfaces';
import { ProgressContainer, ProgressText, ProgressContent, ProgressIndicator } from './styles';

export default function ProgressMinimumValue({
	diffToMinimum = 0,
	minimumValueForSale = 1,
	fullWidth,
}: AccordionProgressProps) {
	const currentValue = minimumValueForSale - diffToMinimum;
	const percent = ((currentValue / minimumValueForSale) * 100).toFixed(2);
	const equalValue = diffToMinimum === minimumValueForSale || diffToMinimum <= 0;

	return (
		<ProgressContainer fullWidth={fullWidth}>
			<ProgressText>
				{equalValue && !fullWidth && <Icon name="check_circle" size="16px" color="--semantic-success-base" fill />}
				<Typography color="--text-primary" variant="ParagraphSmall/Regular">
					{equalValue
						? 'Valor mínimo atingido'
						: `Faltam ${Number(minimumValueForSale - currentValue).toCurrency()} para o valor mínimo`}
				</Typography>
				{fullWidth && (
					<Tooltip content="O valor mínimo do pedido é determinado pelos distribuidores, podendo haver divergências entre capitais e outras regiões da mesma UF.">
						<Icon name="info" size="20px" />
					</Tooltip>
				)}
			</ProgressText>

			<ProgressContent
				max={minimumValueForSale}
				className="ProgressRoot"
				value={minimumValueForSale - diffToMinimum}
				fullWidth={fullWidth}
			>
				<ProgressIndicator
					data-state={(diffToMinimum <= 0 || diffToMinimum === minimumValueForSale) && 'complete'}
					className="ProgressIndicator"
					style={{ width: `${percent}%` }}
				/>
			</ProgressContent>
		</ProgressContainer>
	);
}
