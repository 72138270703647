import { GetTypePromotionProps } from './interfaces';

export const getTypePromotion = ({
	longName = false,
	progressiveDiscount,
	progressiveDiscountProductMix,
}: GetTypePromotionProps) => {
	if (progressiveDiscount || progressiveDiscountProductMix) return longName ? 'Desconto Progressivo' : 'Progressivo';

	return 'Combo';
};
