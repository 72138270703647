import { useSelector, useDispatch } from 'react-redux';
import { Redux } from 'stores/interfaces';
import * as middleware from './middleware';
import { policyContents } from './helpers';
import { useMemo } from 'react';
import { PolicyReducer } from './interfaces';

export default function usePolicy() {
	const dispatch = useDispatch();
	const policyStore = useSelector((stores: Redux) => stores.policyReducer);

	const atualContent = useMemo(
		() => policyContents.find(({ value }) => value === policyStore.currentPolicySelected),
		[policyStore.currentPolicySelected],
	);
	const selectOptions = useMemo(() => policyContents.map(({ label, value }) => ({ label, value })), []);

	return {
		policyStore,
		selectOptions,
		atualContent,
		handleSetCurrentPolicySelected: (currentPolicySelected: PolicyReducer['currentPolicySelected']) =>
			dispatch(middleware.handleSetCurrentPolicySelected(currentPolicySelected)),
		handleSetCookiesPolicies: () => dispatch(middleware.handleSetCookiesPolicies()),
	};
}
