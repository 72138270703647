import { Button, Flex, getThemeFontSize, getThemeMedia, getThemeSpace } from 'front-commons/ds';
import styled, { css } from 'styled-components';
import { CatalogMenuProps } from './interfaces';

export const CatalogMenuItem = styled(Button).attrs(() => ({ variant: 'none', color: 'neutral0' }))<{
	hasTag?: boolean;
}>`
	height: 40px !important;
	padding: 0px ${getThemeSpace('16')} !important;
	border-radius: 8px;
	transition: all 300ms ease-in-out;
	border: 1px solid transparent;
	white-space: nowrap;

	${({ hasTag }) =>
		hasTag &&
		css`
			padding-right: 48px !important;
		`}

	& > [data-testid="tag-container"] {
		min-height: auto;
		height: 16px;
	}

	@media screen and (max-width: 767px) {
		[data-testid='icon-chevronDown-24px'] {
			fill: var(--text-primary);
		}
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			position: relative;
			border-radius: 8px 8px 0 0;

			& > [data-testid='tag-container'] {
				position: absolute;
				right: ${getThemeSpace('4')};
				top: 0px;
				transition: all 300ms ease;
			}

			&:hover {
				background: #ffffff26 0% 0% no-repeat padding-box;
				border-color: #ffffff80;

				& > [data-testid='tag-container'] {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
`;

export const CatalogMenuContainer = styled(Flex)<Pick<CatalogMenuProps, 'variant'>>`
	overflow-y: hidden;
	overflow-x: auto;
	height: 56px;
	padding-left: 4px;

	${({ variant }) => {
		if (variant === 'primary')
			return css`
				background-color: var(--surface-accent);

				${CatalogMenuItem} {
					color: var(--text-invert);

					&:hover {
						color: var(--text-invert);
					}
				}
			`;

		return css`
			background-color: var(--surface-white);

			${CatalogMenuItem} {
				color: var(--text-primary);
				font-size: ${getThemeFontSize('14')};

				&:hover {
					color: var(--text-primary);
				}
			}
		`;
	}}

	& > * {
		max-height: 40px;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			height: auto;
			padding-top: auto;
			padding-left: auto;
		}
	}
`;
