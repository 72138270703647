import * as Dialog from '@radix-ui/react-dialog';
import { Button, Typography } from 'front-commons/ds';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import usePolicy from 'stores/policy';
import { Content, Description } from './styles';

export default function PolicyCookiesPopup() {
	const {
		handleSetCookiesPolicies,
		policyStore: { cookiesPolicies },
	} = usePolicy();

	const hasExpired = useMemo(() => {
		if (!cookiesPolicies?.expirationDate) return true;
		return cookiesPolicies.expirationDate < new Date().getTime();
	}, [cookiesPolicies]);
	return (
		<Dialog.Root open={hasExpired} modal={false}>
			<Dialog.Portal>
				<Content>
					<Description data-testid="privacy-policy">
						<Typography as="span" style={{ fontSize: '18px' }}>
							O funcionamento desse site depende da utilização de cookies considerados essenciais. Caso queira saber
							mais, visite nossa <Link to="/politicas-de-privacidade">Política de Privacidade</Link>. Ao continuar
							navegando, você concorda com estas condições.
						</Typography>
						<Button
							data-testid="accept-policy-button"
							size="medium"
							onClick={() => handleSetCookiesPolicies()}
							variant="secondary"
						>
							Continuar
						</Button>
					</Description>
				</Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
}
