import { notify } from 'front-commons/ds';
import { useCallback, useEffect, useState } from 'react';
import NoPromotionDrawerCard from 'containers/Cards/NoPromotionDrawerCard';
import PromotionDrawerCard from 'containers/Cards/PromotionDrawerCard';
import SuggestedProductCard from 'containers/Cards/SuggestedProductCard';
import { getPromotionsByProduct } from 'services/products';
import { PromotionParams } from 'services/products/interfaces';
import useDrawer from 'stores/drawer';
import { PromotionsDrawerContentProps } from './interfaces';
import { PromotionsDrawerContentContainer } from './styles';

export default function PromotionsDrawerContent({
	data,
	posId = '',
	productId = '',
	orderImportProductId = '',
	handleRefetchItemList,
	useCatalogContext,
	hideSuggestion,
	currentDistributor,
}: PromotionsDrawerContentProps) {
	const [promotions, setPromotions] = useState<PromotionParams[]>([]);

	const { isDrawerOpen, handleCloseDrawer, handleSetDynamicSubtitle } = useDrawer();

	const [withoutPromotions, setWithoutPromotions] = useState(false);
	const [loading, setLoading] = useState(false);

	const isOpen = isDrawerOpen('promotions-drawer');

	const fetchPromotions = useCallback(async () => {
		if (!isOpen) return;

		try {
			setLoading(true);
			setPromotions([]);
			const response = await getPromotionsByProduct(posId!, productId!);

			if (!response.promotions.length) {
				setWithoutPromotions(true);
				handleSetDynamicSubtitle('promotions-drawer', `(Encontramos 0 promoções)`);
				return;
			}

			handleSetDynamicSubtitle(
				'promotions-drawer',
				`(Encontramos ${response.promotions.length} promoç${response.promotions.length > 1 ? 'ões' : 'ão'})`,
			);

			setPromotions(response.promotions);
			setWithoutPromotions(false);
		} catch (error) {
			notify.negative({ description: 'Não foi possível buscar as promoções desse produto.' });
			handleCloseDrawer('promotions-drawer');
			setPromotions([]);
		} finally {
			setLoading(false);
		}
	}, [productId, posId, isOpen]);

	useEffect(() => {
		fetchPromotions();
	}, [fetchPromotions]);

	return (
		<PromotionsDrawerContentContainer withoutPromotions={withoutPromotions}>
			{loading && Array.generate(5).map((i) => <PromotionDrawerCard.Skeleton key={i} />)}

			{!loading && data?.suggestedProduct && !hideSuggestion && (
				<SuggestedProductCard
					orderImportProductId={orderImportProductId}
					{...data.suggestedProduct}
					posId={posId}
					handleRefetchItemList={handleRefetchItemList}
					{...data}
				/>
			)}

			{!loading && (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>
					{withoutPromotions ? (
						<NoPromotionDrawerCard data={data} />
					) : (
						<>
							{promotions.map((promotion, index) => (
								<PromotionDrawerCard
									key={index}
									data={promotion}
									posId={posId}
									sourceProductId={productId}
									orderImportProductId={orderImportProductId}
									handleRefetchItemList={handleRefetchItemList}
									useCatalogContext={useCatalogContext}
									currentDistributor={currentDistributor}
								/>
							))}
						</>
					)}
				</>
			)}
		</PromotionsDrawerContentContainer>
	);
}
