import { Flex, Grid, Icon, Typography } from 'front-commons/ds';
import { useEffect, useState } from 'react';
import ItemSuggestionCard from 'containers/Cards/ItemSuggestionCard';
import CurrentDistributor from 'containers/Cards/ItemSuggestionCard/CurrentDistributor';
import UndoSuggestionCard from 'containers/Cards/UndoSuggestionCard';
import DistributorsSelector from 'containers/Distributors/DistributorsSelector';
import { DistributorsProps } from 'services/products/interfaces';
import { PromotionSuggestionCardProps } from './interfaces';
import { IconContainer, SuggestionButtonOutlined } from './styles';

export default function PromotionSuggestionCard({
	hasOnlyOneSuggestion,
	simpleProductInfo,
	showReplaceButton,
	suggestion,
	itemState,
	loading,
	updateSummary,
	onUndoTimeout,
	promotionHandlers,
	handleChangeProductType,
}: PromotionSuggestionCardProps) {
	const [selectedDistributor, setSelectedDistributor] = useState({} as DistributorsProps);

	const handleReplaceClick = () => {
		const { get: updatedData, map } = promotionHandlers(simpleProductInfo.id);

		handleChangeProductType({
			changeInto: 'PROMOTION',
			data: {
				removeProductId: simpleProductInfo.id,
				addProduct: {
					productId: suggestion.id,
					distributorId: selectedDistributor.distributorId,
					quantity: suggestion.fixedAmount ? updatedData.quantity : 1,
					productsInCombo: map.productsInCombo(
						suggestion.productsInCombo,
						updatedData.quantity,
						suggestion.fixedAmount,
					),
				},
			},
		});
	};

	const handleUndoClick = () => {
		handleChangeProductType({
			changeInto: 'SIMPLE',
			data: {
				addProduct: {
					productId: simpleProductInfo.id,
					distributorId: simpleProductInfo.currentDistributorId,
					quantity: simpleProductInfo.quantity,
				},
				removeProductId: suggestion.id,
			},
		});
	};

	const handleUndoTimeout = () => {
		onUndoTimeout?.(simpleProductInfo.id);
	};

	const handleDistributorChange = (distributor: DistributorsProps) => {
		setSelectedDistributor(distributor);
	};

	useEffect(() => {
		updateSummary(simpleProductInfo.id, { currentDistributorId: selectedDistributor.distributorId });
		promotionHandlers(simpleProductInfo.id).set.distributorId(selectedDistributor.distributorId);
	}, [selectedDistributor]);

	return (
		<Flex direction="column" gap="24px" width="100%">
			<Flex gap="8px" alignItems="center" margin="0 0 8px 0">
				<IconContainer
					backgroundColor="--surface-primary"
					width="32px"
					height="32px"
					alignItems="center"
					justifyContent="center"
					borderRadius="100%"
				>
					<Icon name="divergentArrow" fill color="--semantic-success-text" size="18px" />
				</IconContainer>
				<Typography variant="Headline/H2 Bold">{suggestion.description}</Typography>
			</Flex>

			<Flex
				justifyContent="space-between"
				alignItems="center"
				width="100%"
				gap="16px"
				display={itemState === 'READY' ? 'flex' : 'none'}
			>
				<Typography variant="ParagraphSmall/Regular">Disponível no distribuidor:</Typography>
				<Grid columns="minmax(100px, 404px)" maxWidth="404px">
					<DistributorsSelector
						data={suggestion.distributors}
						onChange={handleDistributorChange}
						size="small"
						showFooter={false}
					/>
				</Grid>
			</Flex>

			{itemState !== 'READY' && (
				<Flex margin="0 0 -16px 0">
					<CurrentDistributor name={selectedDistributor.distributorName} bold />
				</Flex>
			)}

			<ItemSuggestionCard
				promotionData={suggestion}
				selectedDistributor={selectedDistributor}
				promotionHandlers={promotionHandlers}
				simpleProductData={simpleProductInfo}
				itemState={itemState}
				updateSummary={updateSummary}
			/>

			{itemState === 'PARTIALLY_CHANGED' && (
				<Flex margin="-24px 0 0 0">
					<UndoSuggestionCard onUndo={handleUndoClick} onTimeout={handleUndoTimeout} loading={loading} />
				</Flex>
			)}

			{itemState === 'READY' && (
				<>
					<ItemSuggestionCard simpleProductData={simpleProductInfo} />
					{showReplaceButton && !hasOnlyOneSuggestion && (
						<Flex justifyContent="flex-end" margin="-8px 0 0 0">
							<SuggestionButtonOutlined
								onClick={handleReplaceClick}
								disabled={loading}
								loading={loading}
								minWidth="175px"
								variant="none"
							>
								Substituir por combo
							</SuggestionButtonOutlined>
						</Flex>
					)}
				</>
			)}
		</Flex>
	);
}
