export enum InsiderRouteState {
  SHOULD_CONFIRM = 'shouldConfirm',
}

export enum InsiderPageType {
  CONFIRMATION = 'Confirmation',
  OTHER = 'other',
}

export type InsiderPageTypes = InsiderPageType.CONFIRMATION | InsiderPageType.OTHER;

export interface InsiderUserParams {
  uuid?: string;
  gender?: string;
  birthday?: string;
  has_transacted?: boolean;
  transaction_count?: number;
  name?: string;
  surname?: string;
  username?: string;
  email?: string;
  email_optin?: boolean;
  phone_number?: string;
  sms_optin?: boolean;
  whatsapp_optin?: boolean;
  language?: string;
  returning?: boolean;
  list_id?: number[];
}
