import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Grid, getThemeMedia, getThemeShadow, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	grid-template-rows: auto 4fr 1fr;
	height: 100%;

	h2 {
		padding: 0 ${getThemeSpace('16')} ${getThemeSpace('16')};
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			h2 {
				padding: 0 ${getThemeSpace('56')} ${getThemeSpace('16')};
			}
		}
	}
`;

export const InvalidDistributorsContainer = styled(Grid)`
	height: fit-content;
`;

export const Container = styled.div`
	overflow-y: auto;
	overflow-x: hidden;

	padding: 0 ${getThemeSpace('8')};
	margin: 0 ${getThemeSpace('8')};

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			padding: 0 ${getThemeSpace('16')};
			margin: 0 ${getThemeSpace('40')};
		}
	}
`;

// Skeleton

export const BoxSkeleton = styled.div`
	overflow-y: auto;

	padding: 0 ${getThemeSpace('8')};
	margin: 0 ${getThemeSpace('8')};

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			padding: 0 ${getThemeSpace('16')};
			margin: 0 ${getThemeSpace('40')};
		}
	}
`;

export const Flex = styled.div<{ alignEnd?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: ${({ alignEnd }) => alignEnd && 'flex-end'};
	flex-wrap: wrap;
	gap: 8px;
`;

// Card
export const ContainerProducts = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${getThemeSpace('24')};
`;

export const WrapperProductCard = styled.div`
	display: flex;
	gap: ${getThemeSpace('16')};
	overflow: hidden;

	&:not(:last-child) {
		padding-bottom: ${getThemeSpace('16')};
		margin-bottom: ${getThemeSpace('16')};
		border-bottom: 2px solid var(--border-primary);
	}
`;

export const ProductDetails = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${getThemeSpace('8')};
	overflow: hidden;
	width: 100%;

	@media screen {
		@media (min-width: ${getThemeMedia('small')}) {
			align-items: center;
			flex-direction: row;
		}
	}
`;

export const ProductCardThumbnail = styled.figure`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 68px;
	height: 68px;

	img {
		max-width: 68px;
		max-height: 68px;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('small')}) {
			width: 80px;
			height: 80px;

			img {
				max-width: 80px;
				max-height: 80px;
			}
		}
	}
`;

export const ProductCardInfo = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	gap: ${getThemeSpace('8')};
`;

export const Products = styled.div`
	display: flex;
	flex-direction: column;
`;

export const BoxTop = styled.div`
	display: flex;
	gap: ${getThemeSpace('8')};
`;

export const BoxPrice = styled.div`
	display: flex;
	flex-direction: column;

	.final_price {
		flex-wrap: wrap;
	}
`;

export const BoxPriceInfo = styled.div`
	display: flex;
	align-items: center;
	gap: ${getThemeSpace('4')};

	white-space: nowrap;
`;

export const BoxInfoQuantity = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${getThemeSpace('4')};

	@media screen {
		@media (min-width: ${getThemeMedia('small')}) {
			align-items: start;
		}
	}
`;

// Footer

export const BottomContainer = styled.div`
	display: flex;
	flex-direction: column;
	box-shadow: inset ${getThemeShadow('1')};
	gap: ${getThemeSpace('32')};
	padding: ${getThemeSpace('16')};

	p {
		text-align: center;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			padding: ${getThemeSpace('16')} ${getThemeSpace('56')} ${getThemeSpace('24')};
		}
	}
`;

// Total Basket Price

export const Trigger = styled(DropdownMenu.Trigger)`
	width: 100%;
	padding: 0;
`;

export const Portal = styled(DropdownMenu.Portal)``;

export const Content = styled(DropdownMenu.Content)`
	@keyframes downEntrance {
		0% {
			opacity: 0;
			transform: rotateX(-100deg);
			transform-origin: bottom;
		}

		100% {
			opacity: 1;
			transform: rotateX(0deg);
			transform-origin: bottom;
		}
	}

	@keyframes upExit {
		0% {
			opacity: 1;
			transform: rotateX(0deg);
			transform-origin: bottom;
		}

		100% {
			opacity: 0;
			transform: rotateX(70deg);
			transform-origin: bottom;
		}
	}

	box-shadow: inset ${getThemeShadow('1')};
	background-color: var(--surface-white);

	width: 100vw;
	height: auto;
	padding: ${getThemeSpace('16')};

	&[data-state='open'] {
		animation: downEntrance 0.5s ease 0s 1 normal forwards;
	}

	&[data-state='false'] {
		animation: upExit 1s ease 0s 1 normal forwards;
	}

	> span {
		display: none;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			width: 700px;
			height: auto;
			padding: ${getThemeSpace('16')} ${getThemeSpace('56')};
		}
	}
`;

export const WrapperPrice = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${getThemeSpace('16')};

	span {
		text-align: right;
	}

	p {
		text-align: left;
	}
`;

export const TriggerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
`;

export const BoxLeftTrigger = styled.div<{ expanded: boolean }>`
	display: flex;
	align-items: center;
	gap: ${getThemeSpace('8')};
`;

export const BoxButtons = styled.div`
	display: flex;
	gap: ${getThemeSpace('8')};
	width: 100%;
	justify-content: flex-end;
`;
