import { Br, Counter, Flex, Grid, Icon, Tooltip, Typography } from 'front-commons/ds';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ProgressiveDiscountCards from 'containers/ProgressiveDiscountCards';
import TagProductDiscount from 'containers/Tags/TagProductDiscount';
import { getPromotionChildQuantityMessage } from 'shared/messages';
import { getDiscountPrice, getSummaryBrutePrice, getSummaryQuantities } from 'shared/promotionDrawer';
import { getCurrentRange, getCurrentRangeProgressive, getInitialQuantity, getQuantityByDiscount } from './helpers';
import { ConflictMessageProps, PromotionProductCardProps } from './interfaces';
import {
	BoxPriceInfo,
	WrapperProductCard,
	ProductCardThumbnail,
	UnitPriceInformation,
	BoxPriceInfoTop,
} from './styles';
import ConflictMessage from './ConflictMessage';

export default function PromotionProductCard({
	promotionId,
	product,
	fixedAmount,
	mixedRanges,
	rangeByQuantity,
	progressiveDiscount,
	progressiveDiscountProductMix,
	hasValidDistributors,
	quantityInBasket = 1,
	hasPermission,
	hasRangeProduct,
	summary,
	productsInPromotionPrices: productsInComboPrices,
	posId,
	basketData,
	updateSummary,
	getFlexQuantityInBasket,
	getProductValidity,
	onOpenDrawer,
	getProductsInBasketByType,
}: PromotionProductCardProps) {
	const [quantity, setQuantity] = useState<number | ''>(0);

	const {
		ranges,
		productId,
		minimumQuantity,
		quantity: initialQuantity,
		productInfo: { description, ean13, imageURL, brandName, categoryName },
		distributorsPrice,
	} = product;
	const price = productsInComboPrices?.find((item) => item.productId === productId)?.price!;
	const isValid = getProductValidity(productId);

	const quantityAsNumber = Number(quantity);
	const isProductUnavailable = isValid !== undefined && !isValid;
	const isProgressiveWithRange = progressiveDiscount || (progressiveDiscountProductMix && hasRangeProduct);
	const shouldShowProgressiveBar = isValid && ranges?.length && isProgressiveWithRange && !fixedAmount;
	const haveQuantityAdded = quantityAsNumber > 0;

	const flexBasketQuantity = useMemo(() => {
		return getFlexQuantityInBasket(productId, promotionId);
	}, [productId, promotionId, getFlexQuantityInBasket]);

	const { discount, taxSubstitution } = useMemo(() => {
		const totalPrice = quantityAsNumber * price * quantityInBasket;
		const productPrices = getSummaryBrutePrice(summary) * quantityInBasket;
		const allProductQuantities = getSummaryQuantities(summary) * quantityInBasket;
		const updatedProductQuantity = quantityAsNumber * quantityInBasket;

		return getCurrentRange({
			fixedAmount,
			hasRangeProduct,
			mixedRanges,
			productPrices,
			allProductQuantities,
			progressiveDiscount,
			progressiveDiscountProductMix,
			updatedProductQuantity,
			quantityInBasket,
			rangeByQuantity,
			ranges,
			totalPrice,
		});
	}, [
		ranges,
		quantityAsNumber,
		mixedRanges,
		rangeByQuantity,
		quantityInBasket,
		progressiveDiscount,
		hasRangeProduct,
		progressiveDiscountProductMix,
		price,
		summary,
	]);

	const { conflictType, conflictedPromotionId } = useMemo(() => {
		const { promotions, simples } = getProductsInBasketByType?.() || {};

		const promotionConflicting = promotions?.find(
			(promotion) =>
				promotionId !== promotion.id && promotion.productsInCombo.some((product) => product.productId === productId),
		);
		const isInBasket = simples?.some((product) => product.id === productId);

		if (!promotionConflicting && !isInBasket) return { conflictType: null };

		return {
			conflictType: (promotionConflicting ? 'IN_PROMOTION' : 'IN_BASKET') as ConflictMessageProps['conflictType'],
			conflictedPromotionId: promotionConflicting?.id,
		};
	}, [basketData]);

	const showTaxSubstitution = !!taxSubstitution && haveQuantityAdded;
	const calculatedTax = taxSubstitution * quantityAsNumber;

	const discountPercentPrice = useMemo(() => getDiscountPrice(discount, price), [discount, price]);

	const hasMinimumQuantity = useMemo(() => {
		if (!minimumQuantity) return false;

		if (quantityAsNumber <= minimumQuantity) {
			return true;
		}

		return false;
	}, [minimumQuantity, quantity]);

	const handleRemoveItem = useCallback(() => {
		if (quantity === '') return;

		if (minimumQuantity) {
			if (quantity > minimumQuantity) setQuantity(quantity - 1);
			return;
		}

		if (quantity > 0) setQuantity(quantity - 1);
	}, [quantity, minimumQuantity]);

	useEffect(() => {
		const updatedQuantity = getInitialQuantity(
			fixedAmount,
			minimumQuantity,
			flexBasketQuantity,
			initialQuantity,
			hasValidDistributors,
			summary.find((p) => p.productId === productId)?.quantity,
		);

		setQuantity(updatedQuantity);
	}, [fixedAmount, minimumQuantity, flexBasketQuantity, initialQuantity, hasValidDistributors]);

	useEffect(() => {
		updateSummary({
			discount: isValid ? discount : 0,
			price,
			productId,
			quantity: isValid ? quantityAsNumber : 0,
			tax: isValid ? taxSubstitution : 0,
			distributors: distributorsPrice,
			ean13,
			description,
			brandName,
			categoryName,
		});
	}, [discount, taxSubstitution, quantity, price, productId, ean13, description]);

	return (
		<WrapperProductCard gap="16px" overflow="hidden">
			<Flex direction="column" gap="16px" width="100%">
				<Flex
					direction={{ small: 'column', medium: 'row' }}
					alignItems={{ medium: 'center' }}
					overflow="hidden"
					gap="8px"
					width="100%"
				>
					<Flex gap="8px">
						<ProductCardThumbnail
							as="figure"
							alignItems="center"
							justifyContent="center"
							width={{ small: '68px', medium: '80px' }}
							height={{ small: '68px', medium: '80px' }}
						>
							<img
								src={`${import.meta.env.VITE_STATIC_STORAGE_BASE}${imageURL}`}
								alt={`Imagem do produto ${description}`}
							/>
						</ProductCardThumbnail>
						<Flex display={{ medium: 'none' }} direction="column" gap="8px" overflow="hidden">
							<Typography variant="Paragraph/Semibold" color="--text-primary">
								{description}
							</Typography>
							<Grid columns="auto 20px" alignItems="center" justifyContent="flex-start">
								<Typography variant="ParagraphSmall/Regular">{ean13}</Typography>
							</Grid>
							<UnitPriceInformation gap="4px" alignItems="center">
								{haveQuantityAdded && !!discount && (
									<Typography variant="Caption/Regular">{Number(price).toCurrency()}</Typography>
								)}
								<Flex gap="0px" alignItems="center">
									<Typography variant="ParagraphSmall/Semibold">
										{Number(
											haveQuantityAdded && !!discount
												? discountPercentPrice + taxSubstitution
												: price + taxSubstitution,
										).toCurrency()}
									</Typography>
									<Typography variant="Caption/Regular">/und</Typography>
								</Flex>
							</UnitPriceInformation>
						</Flex>
					</Flex>

					<Grid columns={{ small: '1fr 1fr', medium: '1.5fr 128px 185px' }} gap="16px">
						<Flex display={{ small: 'none', medium: 'flex' }} direction="column" gap="8px" overflow="hidden">
							<Typography variant="Paragraph/Semibold" color="--text-primary">
								{description}
							</Typography>
							<Grid columns="auto 20px" alignItems="center" justifyContent="flex-start">
								<Typography variant="ParagraphSmall/Regular">{ean13}</Typography>
							</Grid>
							<UnitPriceInformation gap="4px" alignItems="center">
								{haveQuantityAdded && !!discount && (
									<Typography variant="Caption/Regular">{Number(price).toCurrency()}</Typography>
								)}
								<Flex gap="0px" alignItems="center">
									<Typography variant="ParagraphSmall/Semibold">
										{Number(
											haveQuantityAdded && !!discount
												? discountPercentPrice + taxSubstitution
												: price + taxSubstitution,
										).toCurrency()}
									</Typography>
									<Typography variant="Caption/Regular">/und</Typography>
								</Flex>
							</UnitPriceInformation>
						</Flex>
						<Flex direction="column" alignItems={{ small: 'center', medium: 'flex-start' }} gap="4px">
							<Counter
								hasPermission={hasPermission}
								width="100%"
								quantity={!price && hasValidDistributors ? 0 : quantity}
								onAdd={() => setQuantity(quantityAsNumber + 1)}
								onBlur={(value) =>
									minimumQuantity && Number(value) < Number(minimumQuantity) && setQuantity(minimumQuantity)
								}
								onRemove={() => handleRemoveItem()}
								onChange={(value) => setQuantity(value)}
								maxWidth={{ small: '124px', medium: '128px' }}
								disabled={fixedAmount || !hasValidDistributors || !isValid}
								hasMiminumQuantity={hasMinimumQuantity}
								initialQuantity={!price && hasValidDistributors ? 0 : quantity}
							/>
							<Typography variant="ParagraphSmall/Regular">
								{getPromotionChildQuantityMessage({
									fixedAmount,
									quantity: initialQuantity || 1,
									price,
									minimumQuantity,
									hasValidDistributors,
								})}
							</Typography>
						</Flex>
						<Flex direction="column" gap="0px">
							{hasValidDistributors && isValid && (
								<>
									{haveQuantityAdded && !!discount && (
										<BoxPriceInfoTop>
											<Typography variant="ParagraphSmall/Regular" color="--text-disable">
												{Number((quantityAsNumber || 1) * price).toCurrency()}
											</Typography>
											<TagProductDiscount
												bgColor="--semantic-promo-base"
												discount={discount}
												labelOptions={{ color: '--surface-white', variant: 'ParagraphSmall/Regular' }}
												iconOptions={{ name: 'arrow_downward', color: '--surface-white', size: '12px' }}
											/>
										</BoxPriceInfoTop>
									)}
									<BoxPriceInfo wrap="wrap" alignItems="center">
										<Typography
											variant={
												haveQuantityAdded
													? { small: 'Paragraph/Semibold', medium: 'Subtitle' }
													: { small: 'ParagraphSmall/Regular', medium: 'Paragraph/Regular' }
											}
										>
											{haveQuantityAdded
												? Number((quantityAsNumber || 1) * discountPercentPrice + calculatedTax).toCurrency()
												: Number(price).toCurrency()}
										</Typography>

										<Tooltip
											content={
												showTaxSubstitution ? (
													<>
														<Typography color="--neutral-white" variant="ParagraphSmall/Semibold">
															<strong>{Number(calculatedTax).toCurrency()}</strong>
														</Typography>
														<Br />
														<Typography color="--neutral-white" variant="Caption/Regular">
															em impostos
														</Typography>
													</>
												) : (
													<Typography color="--neutral-white" variant="ParagraphSmall/Semibold">
														O imposto será informado na Nota Fiscal
													</Typography>
												)
											}
										>
											<Icon name="info" size="20px" />
										</Tooltip>
									</BoxPriceInfo>
								</>
							)}
						</Flex>
					</Grid>
				</Flex>

				{conflictType && (
					<ConflictMessage
						conflictType={conflictType}
						onOpenConflictedDrawer={() => {
							onOpenDrawer('promotion-details-drawer', {
								promotionId: conflictedPromotionId,
								posId,
								initialLoading: 'opening',
								showReturnButton: true,
							});
						}}
					/>
				)}

				{isProductUnavailable && (
					<Flex
						alignItems="center"
						justifyContent="center"
						gap="8px"
						padding="4px 0"
						backgroundColor="--semantic-warning-weak"
					>
						<Icon name="info" color="--semantic-warning-text" />
						<Typography>Este produto não está disponível no distribuidor selecionado.</Typography>
					</Flex>
				)}

				{shouldShowProgressiveBar && (
					<Flex margin="0">
						<ProgressiveDiscountCards
							data={{ type: rangeByQuantity ? 'quantity' : 'currency', values: ranges }}
							atualValue={getCurrentRangeProgressive(
								rangeByQuantity,
								progressiveDiscount,
								summary,
								quantityAsNumber,
								price,
								quantityInBasket,
							)}
							onClick={({ range }) => setQuantity(getQuantityByDiscount(range, rangeByQuantity, price))}
						/>
					</Flex>
				)}
			</Flex>
		</WrapperProductCard>
	);
}
