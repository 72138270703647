import { css } from 'styled-components';

export const styling = css`
	animation: popup 0.3s;
	will-change: transform;

	&[data-state='closed'] {
		animation: popupClose 0.3s;
	}

	@keyframes popup {
		0% {
			opacity: 0;
			transform: scale(0);
			transform-origin: left bottom;
		}
		100% {
			opacity: 1;
			transform: scale(1);
			transform-origin: left bottom;
		}
	}

	@keyframes popupClose {
		0% {
			opacity: 1;
			transform: scale(1);
			transform-origin: left bottom;
		}
		100% {
			opacity: 0;
			transform: scale(0);
			transform-origin: left bottom;
		}
	}
`;

export const getAnimationStyle = (animationType?: 'popup') => {
	switch (animationType) {
		case 'popup':
			return styling;
		default:
			return undefined;
	}
};
