import features from 'config/featureFlags';
import { FeaturesReducer } from './interfaces';

const initialState: FeaturesReducer = features;

export default function featuresReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_FEATURES': {
			return { ...state, [payload.key]: payload.value };
		}

		case 'SET_RESET_FEATURES': {
			return features;
		}

		default:
			return state;
	}
}
