import { Dispatch } from '@reduxjs/toolkit';
import {
	setSuggestionsProductNotifierExpiration,
	clearSuggestionsProductNotifierExpiration,
	setCatalogMenuItems,
	setCatalogLoading,
	setLoadingPromotionDetails,
	setIsPromotionActive,
} from './actions';
import dayjs from 'dayjs';
import { CatalogMenuItemsParams, CatalogReducer } from './interfaces';
import { menuItemsData } from './helpers';
import { getMenuFlags } from 'services/products';
import { PosReducer } from 'stores/pos/interfaces';

export const handleCatalogLoading = (loading: CatalogReducer['loading']): any => {
	return (dispatch: Dispatch) => {
		dispatch(setCatalogLoading(loading));
	};
};

export const handleSetIsPromotionActive = (loading: CatalogReducer['isPromotionActive']): any => {
	return (dispatch: Dispatch) => {
		dispatch(setIsPromotionActive(loading));
	};
};

export const handleSetCatalogMenuItems = (selectedPos: PosReducer['selectedPos']): any => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(handleCatalogLoading(true));

			const menuFlags = await getMenuFlags(selectedPos.id);

			const catalogMenuItems: CatalogMenuItemsParams[] = [];

			dispatch(handleSetIsPromotionActive(menuFlags.hasPromotions));

			if (menuFlags.hasPromotions) {
				catalogMenuItems.push({
					...menuItemsData.promotions,
					status: 'AVAILABLE',
				});
			}

			if (menuFlags.hasReleaseProducts) {
				catalogMenuItems.push({
					...menuItemsData.lancamentos,
					status: 'AVAILABLE',
				});
			}

			if (selectedPos.businessUnities.length) {
				selectedPos.businessUnities.forEach((businessUnit) => {
					catalogMenuItems.push({
						label: `Comprar ${businessUnit.name.toPascalCase(true)}`,
						href: `${menuItemsData.businessUnit.href}${businessUnit.id}`,
						status: 'AVAILABLE',
					});
				});
			}

			if (menuFlags.hasProductSuggestions) {
				catalogMenuItems.push({
					...menuItemsData.suggestionsProduct,
					status: menuFlags.productSuggestionQuantity ? 'AVAILABLE' : 'UNAVAILABLE',
					counter: menuFlags.productSuggestionQuantity,
				});
			}

			return dispatch(setCatalogMenuItems(catalogMenuItems));
		} catch {
			return dispatch(setCatalogMenuItems([]));
		} finally {
			dispatch(handleCatalogLoading(false));
		}
	};
};

export const handleClearSuggestionsProductNotifierExpiration = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(clearSuggestionsProductNotifierExpiration());
	};
};

export const handleSetSuggestionsProductNotifierExpiration = (): any => {
	return (dispatch: Dispatch, getState: any) => {
		const date = dayjs().add(5, 'days');

		dispatch(
			setSuggestionsProductNotifierExpiration({
				posId: getState().posReducer.selectedPos.id,
				notifierExpiration: date.format(),
			}),
		);
	};
};

export const handleLoadingPromotionDetails = (loading: CatalogReducer['promotionDetails']['loading']): any => {
	return (dispatch: Dispatch) => {
		dispatch(setLoadingPromotionDetails(loading));
	};
};
