import * as RadixAccordion from '@radix-ui/react-accordion';
import { Grid, Icon, Typography } from 'front-commons/ds';
import BusinessUnitTag from 'containers/BusinessUnitTag';
import ProgressMinimumValue from 'containers/ProgressMinimumValue/ProgressMinimumValue';
import { BasketAccordionProps } from './interfaces';
import {
	AccordionContent,
	AccordionItem,
	AccordionRoot,
	AccordionTrigger,
	Divider,
	TagContent,
	TriggerContent,
	DistributorTitleWrapper,
} from './styles';
import SummaryCard from './SummaryCard';

export default function BasketAccordion({
	basket: {
		distributorName,
		distributorId,
		businessUnitName,
		diffToMinimum,
		minimumValueForSale,
		products,
		businessUnitId: BUId,
	},
	selectedPos,
	totalPrice,
}: BasketAccordionProps) {
	return (
		<AccordionRoot type="multiple">
			<AccordionItem key={String(distributorId)} value={String(distributorId)} data-testid="accordion">
				<RadixAccordion.Header>
					<AccordionTrigger data-testid={`cart-accordion-item-trigger-${distributorId}`}>
						<TriggerContent>
							<TagContent>
								<BusinessUnitTag businessUnitName={businessUnitName} />
							</TagContent>
							<DistributorTitleWrapper>
								<Typography color="--text-primary" variant="Paragraph/Semibold" style={{ fontSize: '20px' }}>
									{distributorName}
								</Typography>
							</DistributorTitleWrapper>
							<ProgressMinimumValue minimumValueForSale={minimumValueForSale} diffToMinimum={diffToMinimum} />
						</TriggerContent>
						<div className="div_rotate">
							<Icon
								size="24px"
								name="expand_more"
								alt="Ícone de representatividade de expansão. Símbolo de seta apontando para baixo"
							/>
						</div>
					</AccordionTrigger>
				</RadixAccordion.Header>
				<Divider />
				<AccordionContent>
					<Grid gap="32px">
						{products.map((product, index) => (
							<SummaryCard
								key={index}
								product={product}
								distributorId={distributorId}
								buId={BUId}
								selectedPos={selectedPos}
								distributorName={distributorName}
								businessUnitName={businessUnitName}
								totalPrice={totalPrice}
							/>
						))}
					</Grid>
				</AccordionContent>
			</AccordionItem>
		</AccordionRoot>
	);
}
