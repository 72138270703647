import { PrivacyBlockQuote } from 'containers/Drawers/PrivacyPolicyMenuDrawer/styles';
import { Block } from 'pages/policy/Complete/styles';
import { Typography } from 'front-commons/ds';

export const policyContents = [
	{
		value: 'ph',
		label: 'Parceiro Hypera',
		description: (
			<Block>
				<Typography variant="Paragraph/Regular">
					A HYPERA agradece seu interesse em nossos produtos e serviços. Para nós, sua privacidade é importante e por
					isso nos preocupamos com o tratamento certo e íntegro de seus dados pessoais.
				</Typography>
				<Typography variant="Paragraph/Regular">
					Processamos seus dados pessoais de acordo com a forma com que você interage com a HYPERA (online, offline, por
					telefone e etc.) e, sempre que o fazemos, observamos todos os princípios da Lei Federal n° 13.709 , de 14 de
					agosto de 2018 (“Lei Geral de Proteção de Dados” ou “LGPD”), como o respeito à finalidade, adequação,
					necessidade, livre acesso, qualidade, transparência, segurança, prevenção, não discriminação e
					responsabilização e prestação de contas e as melhores práticas internacionais de proteção de dados pessoais.
				</Typography>
				<Typography variant="Paragraph/Regular">
					Para qualquer efeito, o presente documento denominado “Aviso de Privacidade” expõe a forma com que tratamos
					dados pessoais, explicando como os seus dados pessoais são coletados e utilizados pela HYPERA, bem como o
					procedimento para que possa solicitar o exercício de direitos assegurados pela LGPD.
				</Typography>
				<Typography variant="Paragraph/Regular">
					O presente aviso se aplica a indivíduos (pessoas naturais) que interagem com os serviços da HYPERA (“você”).
					Nossos serviços não são direcionados a pessoas com idade inferior a 18 (dezoito) anos.
				</Typography>
			</Block>
		),
		items: [
			{
				label: 'Disposições gerais sobre o tratamento de dados pessoais',
				content: (
					<Block>
						<Typography variant="Paragraph/Regular">
							No momento de você visitar sites ou aplicativos da HYPERA, é possível realizar a navegação em diversas
							seções sem que seja solicitado qualquer tipo de informação ao Usuário. Contudo, para ter acesso a certos
							recursos dos Sites ou aplicativos, o Usuário deverá informar alguns dados pessoais necessários.
						</Typography>
						<Typography variant="Paragraph/Regular">
							O acesso e uso dos sites e aplicativos depende, em diversos casos, da coleta e tratamento de dados
							pessoais, sendo que tal processamento se dará conforme o disposto neste Aviso Externo de Privacidade. As
							informações coletadas serão excluídas quando deixarem de ser úteis para atender as finalidades previstas
							neste Aviso de Privacidade, exceto se necessárias para atender a outras finalidades supervenientes,
							cumprir obrigação legal ou regulatória, apresentar defesa judicial, transferir a terceiro ou atender a uso
							exclusivo e anonimizado pela HYPERA.
						</Typography>
						<Typography variant="Paragraph/Regular">
							Da mesma forma, quando você entra em contato telefônico com nossa URA ou os Serviços de Atenção ao Cliente
							(SAC), o sistema ou atendentes poderão solicitar informações pessoais para validar sua identidade. Em todo
							caso, essas informações serão tratadas com a devida confidencialidade. Nós trabalhamos arduamente para
							garantir sua privacidade.
						</Typography>
						<Typography variant="Paragraph/Regular">
							Nossos sites, aplicativos, URA e SAC não tratam, nem mesmo têm a intenção de tratar, dados pessoais de
							pessoas com idade inferior a 18 (dezoito) anos. Se você é menor de idade e deseja fazer contato conosco,
							por favor solicite a ajuda de seus pais ou responsável legal. No caso em que durante o atendimento sejam
							tratados dados de menores de idade, se aplicarão as disposições da legislação brasileira em vigor,
							garantindo em todo momento o interesse máximo da criança ou adolescente.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Fontes de dados pessoais',
				content: (
					<Block>
						<Typography variant="Paragraph/Regular">
							Nós coletamos seus dados pessoais através das seguintes fontes:
						</Typography>
						<Typography variant="Paragraph/Semibold">Informações fornecidas diretamente por você:</Typography>
						<ul>
							<li>
								<Typography variant="Paragraph/Regular">E-mail, login e senha;</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">Nome.</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">Ocupação/ramo de atividade;</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">Data de nascimento.</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">Sexo.</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">Detalhes de redes sociais.</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">RG e CPF/CNPJ.</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">Endereço/telefone.</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Qualquer informação que descreva seus dados demográficos ou suas características comportamentais, como
									idade ou faixa etária, localização geográfica (por exemplo CEP), produtos favoritos, hobbies,
									interesses e informações familiares (por exemplo, quantidade de filhos) ou sobre seu estilo de vida.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Informações que nos permitam entender melhor o seu perfil de consumo, como transações realizadas em
									nossos sites de E-commerce.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Informações que você compartilha voluntariamente com a HYPERA sobre sua experiência de uso de nossos
									produtos e serviços, como reclamações, pesquisas de satisfação e solicitações de melhoria.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Outros dados que você venha a fornecer de outro modo voluntariamente à Hypera.
								</Typography>
							</li>
						</ul>

						<PrivacyBlockQuote variant="Paragraph/Semibold">
							Eventualmente, podemos solicitar o fornecimento de informações ou documentos complementares visando a
							confirmação da veracidade dos dados que nos forneceu.
						</PrivacyBlockQuote>

						<Typography variant="Paragraph/Semibold">
							Os dados pessoais listados acima pode ser tratados pela Hypera para as seguintes finalidades:
						</Typography>

						<ul>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir que você entre em contato conosco por meio de nossos canais de contato.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir acesso às funcionalidades que demandem ingresso na área logada de nossos sites e aplicativos.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir que se inscreva ou solicite inscrição em programas, concursos, promoções e enquetes.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir comunicação para solicitações de atendimento e realização de pesquisas.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir o envio de comunicações sobre nossos produtos, serviços, programas e atividades em geral,
									através de SMS, e-mail, telefone, redes sociais, dentre outros.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir o envio de amostra de produtos exclusivamente classificados como alimentos.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir entender o seu comportamento e perfil de consumo, a partir, por exemplo, das transações
									realizadas em nossos sites de E-commerce.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Melhorar e personalizar a sua experiência em nossos sites e aplicativos através da análise de
									preferência e hábitos.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Assegurar que o conteúdo de nossos sites e aplicativos seja otimizado para o usuário e para seu
									computador ou aparelho.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">Enviar ao usuário publicidade e conteúdo dirigidos.</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir que participe de funções interativas, quando decidir fazê-lo.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir que ofereçamos a você a possibilidade de participar de nossos programas de fidelidade
									contendo descontos e benefícios exclusivos.
								</Typography>
							</li>
						</ul>

						<Typography variant="Paragraph/Semibold">
							Os dados pessoais fornecidos em caso de concursos, promoções e enquetes podem ser disponibilizados em
							nossos sites e aplicativos em caso de contemplação de prêmios.
						</Typography>

						<Typography variant="Paragraph/Regular">
							<strong>Importante:</strong> Normalmente, nós não solicitamos dados pessoais sensíveis nem recomendamos
							que você nos envie tais dados. Na eventualidade de necessitarmos de tais informações, além de realizar a
							coleta em observância à legislação, envidaremos esforços para que você seja devidamente informado.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Informações coletadas automaticamente a partir de nossos sites e aplicativos:',
				content: (
					<Block>
						<Typography variant="Paragraph/Regular">
							Além dos dados citados anteriormente, poderão ser coletadas informações de forma automática, como URLs,
							endereços IP (com data e hora), páginas acessadas, pesquisas efetuadas, navegadores utilizados, tempo de
							acesso, número de "cliques" ou "downloads" e resultado de enquetes.
						</Typography>

						<Typography variant="Paragraph/Semibold">
							Esses dados podem ser tratados para as seguintes finalidades:
						</Typography>

						<ul>
							<li>
								<Typography variant="Paragraph/Regular">
									Cumprir obrigações legais de guarda de registro de acesso.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir a melhora técnica contínua de nossos sites, corrigindo erros, adaptando o conteúdo aos
									dispositivos mais utilizados e prezando pela performance desse portal.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Permitir a melhora constante dos nossos produtos e serviços.
								</Typography>
							</li>
						</ul>

						<Typography variant="Paragraph/Semibold">Outras formas de coleta de dados pessoais:</Typography>

						<ul>
							<li>
								<Typography variant="Paragraph/Regular" />
								Correio eletrônico e sistemas de troca de mensagens instantâneas como WhatsApp e outros semelhantes:
								Serviços utilizados para manter comunicações eletrônicas entre você e a HYPERA.
							</li>
							<li>
								<Typography variant="Paragraph/Regular" />
								Registros offline: Registros preenchidos offline, distribuídos durante eventos e outras interações com a
								HYPERA.
							</li>
						</ul>
					</Block>
				),
			},
			{
				label: 'Uso de Cookies, arquivos de registros (logs) e similares',
				content: (
					<Block>
						<Typography>
							Os cookies são pequenos arquivos de texto que podem ser colocados no seu computador ou dispositivo
							portátil por sites ou serviços visitados na web. São utilizados para garantir o bom funcionamento de sites
							e demais serviços online.
						</Typography>
						<Typography>
							Na HYPERA, os cookies são utilizados para melhorar o desempenho do nosso portal e entender como você
							interage com os nossos serviços.
						</Typography>
						<Typography>
							Os nossos sites também contam com outras tecnologias de rastreamento similares aos cookies, tais como:
							pixels, que possibilitam a coleta de informações como endereços IP, arquivos de registro e sinalizadores
							da web, dentre outras. Esses dados também são utilizados para nos ajudar a adaptar os nossos sites e
							demais serviços as suas necessidades pessoais.
						</Typography>
						<Typography>
							A maioria dos navegadores permite que você gerencie os cookies inseridos em seus dispositivos, entretanto
							ao desabilitar certos tipos de cookies, você pode encontrar dificuldades para acessar todos os recursos de
							nossos sites.
						</Typography>
						<Typography>
							Lembre-se que a preferência para a coleta de cookies deve ser ajustada em cada dispositivo utilizado para
							acessar os nossos sites.
						</Typography>
						<Typography>
							É importante destacar que a HYPERA não se responsabiliza pelo uso de cookies feito por terceiros. Fique
							atento, pois os cookies colocados por terceiros podem eventualmente fazer o monitoramento de suas
							atividades online mesmo depois de sair de nosso site.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Sobre a retenção e término do tratamento de seus dados pessoais',
				content: (
					<Block>
						<Typography>
							De acordo com a legislação vigente, a HYPERA utiliza seus dados pessoais apenas por tempo necessário para
							satisfazer as finalidades descritas nesse aviso. É possível que alguns dados pessoais venham a ser
							mantidos após o atendimento dessas finalidades, caso sejam relevantes para o cumprimento de requerimentos
							legais, para possibilitar que a HYPERA exerça seus direitos ou para outras atividades que, de forma
							justificada, sejam do interesse da HYPERA e/ou outras empresas com as quem esses dados foram
							compartilhados, sempre de acordo com a legislação aplicável.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Sobre o compartilhamento e transferência de seus dados pessoais',
				content: (
					<Block>
						<Typography variant="Paragraph/Semibold">
							Seus dados pessoais somente são compartilhados quando necessário, como nas situações descritas a seguir:
						</Typography>

						<ul>
							<li>
								<Typography variant="Paragraph/Regular">
									Quando se tratar de empresa integrante do grupo HYPERA PHARMA e empresas afiliadas.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Quando necessário para fornecimento de produtos e serviços da HYPERA, inclusive em nossos sites e
									aplicativos. Nesse caso, podemos compartilhar dados pessoais com empresas parceiras.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Quando necessário para alguma operação societária, adotadas as salvaguardas para garantir o tratamento
									seguro e confidencial dos dados compartilhados.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Quando necessário para cumprimento de obrigação legal ou mediante expresso requerimento de autoridades
									públicas competentes.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Quando necessário para defender os interesses da HYPERA em procedimentos administrativos, arbitrais ou
									judiciais. Nesse caso, podemos compartilhar dados pessoais com escritórios de advocacia e outros
									representantes legais.
								</Typography>
							</li>
						</ul>

						<Typography>
							Os sites e aplicativos da HYPERA podem disponibilizar acesso a "links" para outros websites administrados
							por terceiros. A HYPERA, entretanto, não se responsabiliza pelo conteúdo desses sites, bem como pela forma
							de tratamento e segurança das informações inseridas nesses portais. Em caso de redirecionamento,
							recomendamos a leitura dos termos de uso e política de privacidade desses domínios.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Sobre a divulgação, armazenamento, e segurança de seus dados pessoais',
				content: (
					<Block>
						<Typography>
							A HYPERA adota medidas adequadas e razoáveis para garantir que os dados pessoais sob nossa
							responsabilidade sejam mantidos de forma confidencial e segura, excluídas as informações compartilhadas
							por você em áreas públicas de nossos sites, como em nossas páginas oficiais de redes sociais.
						</Typography>
						<Typography>
							Armazenamos seus dados pessoais em ambientes operacionais que contam com medidas de segurança razoáveis,
							tanto técnicas quanto administrativas, para prevenir incidentes de segurança, que incluem mas não se
							limitam a qualquer acesso não autorizado, divulgação, perda, alteração ou destruição de dados pessoais.
							Seguimos protocolos razoáveis para proteger os seus dados pessoais.
						</Typography>
						<Typography>
							Ainda, a HYPERA emprega sistemas tecnológicos e medidas de segurança da informação compatíveis com os
							melhores recursos de segurança oferecidos pelo mercado, tais como: antivírus e firewalls.
						</Typography>
						<Typography>
							Vale dizer que os nossos colaboradores ou agentes autorizados podem realizar o tratamento de seus dados
							pessoais, tendo acesso limitado às informações pessoais necessárias para o desenvolvimento das suas
							atividades, que guardam relação com as finalidades descritas nesse aviso, sempre respeitando os princípios
							e regras estabelecidos na legislação aplicável.
						</Typography>
						<Typography variant="Paragraph/Semibold">
							É importante que você também exerça sua parte na proteção de seus dados pessoais. Por exemplo, quando
							criar uma conta online, lembre-se:
						</Typography>

						<ul>
							<li>
								<Typography variant="Paragraph/Regular">
									Que sua senha é pessoal e intransferível. Nunca a revele a ninguém.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									De utilizar senhas de combinações difíceis, evitando o comprometimento da confidencialidade dessa
									informação.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Quando usar computador compartilhado ou público, nunca escolha a opção de lembrar seu nome de login,
									endereço de e-mail ou senha. Ainda, ao término da utilização, certifique-se que você saiu da sua conta
									(realizou o “log out”).
								</Typography>
							</li>
						</ul>

						<Typography>
							No caso que você identifique ou tome conhecimento de alguma atividade que comprometa a segurança de seus
							dados pessoais, por favor entre em contato conosco imediatamente através do e-mail:{' '}
							<a href="mailto:">dpo@hypera.com.br</a>
						</Typography>
					</Block>
				),
			},
			{
				label: 'Direitos referentes aos seus dados pessoais',
				content: (
					<Block>
						<Typography>A LGPD introduziu direitos referentes aos seus dados pessoais.</Typography>
						<Typography>
							Assim, além de nos preocuparmos com a segurança dessas informações, também garantimos que você tenha
							acesso e conhecimento sobre todos os direitos relativos aos seus dados pessoais e a forma como os exercer.
							De acordo com a LGPD, seus direitos são:
						</Typography>
						<Typography>
							Direito de solicitar confirmação sobre a existência de tratamento dos seus dados pessoais.
						</Typography>
						<ul>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de solicitar acesso aos seus dados pessoais tratados pela HYPERA.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de solicitar a correção dos seus dados pessoais, desde que estejam incorretos, inexatos ou
									desatualizados.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de solicitar a eliminação, bloqueio ou anonimização de seus dados pessoais, caso entenda que o
									tratamento tenha sido realizado de forma excessiva, desnecessária ou em desconformidade com a LGPD.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de solicitar oposição ao tratamento de seus dados pessoais, caso entenda que o tratamento
									tenha sido realizado em desconformidade com a LGPD.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de solicitar a eliminação de dados pessoais tratados com base em seu consentimento.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de revogar eventual consentimento fornecido para tratamento de seus dados pessoais.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de solicitar a portabilidade de seus dados pessoais, nos termos da regulamentação da
									Autoridade Nacional de Proteção de Dados.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de solicitar informação sobre o uso compartilhado de seus dados pessoais.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de solicitar informação sobre as possíveis consequências de não fornecer consentimento para
									tratamento de seus dados pessoais.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de solicitar a revisão de decisões tomadas de forma automática (sem a participação humana),
									desde que afetem os seus interesses.
								</Typography>
							</li>
							<li>
								<Typography variant="Paragraph/Regular">
									Direito de peticionar à Autoridade Nacional de Proteção de Dados, caso não possa solucionar eventuais
									questões diretamente com a HYPERA.
								</Typography>
							</li>
						</ul>

						<Typography>
							Para realizar solicitações de exercício dos seus direitos, entre em contato com nosso departamento de
							privacidade e proteção de dados pessoais: <a href="mailto:dpo@hypera.com.br">dpo@hypera.com.br</a>
						</Typography>
						<Typography>
							O processamento dessas solicitações depende do preenchimento dos critérios mínimos acima informados, além
							da validação de sua identidade e a viabilidade técnica e jurídica de atendimento dessas requisições, de
							acordo com os limites da legislação aplicável.
						</Typography>
					</Block>
				),
			},
		],
	},
	{
		value: 'boni',
		label: 'Boni',
		items: [
			{
				label: 'Objetivo',
				content: (
					<Block>
						<Typography>
							Este Aviso de Privacidade (“Aviso”) foi desenvolvido de acordo com a Lei Geral de Proteção de Dados (Lei
							Federal nº 13.709/2018 – “LGPD”) e tem como objetivo apresentar informações sobre o tratamento de dados
							pessoais no âmbito do Portal Parceiro Hypera (“Portal” ou “Parceiro Hypera”) e do Programa de Fidelidade
							Boni (“Programa Boni” ou “Programa”).
						</Typography>
						<Typography>
							A Portal Parceiro Hypera é desenvolvido e gerenciado pela Hypera S.A., pessoa jurídica inscrita no CNPJ/MF
							sob o nº 02.932.074/0006-04, com sede na Avenida Magalhães de Castro, nº 4.800, 24º andar, cj. 241, Cidade
							Jardim, São Paulo/SP, CEP 05502-001 (“Hypera” ou “Nós”).
						</Typography>
						<Typography>
							Em caso de dúvidas relacionadas aos termos desse Aviso, entre em contato com o Encarregado pelo Tratamento
							de Dados da Hypera:
							<br />
							Nome: João Paulo Mello de Macedo Costa
							<br />
							Contato: <a href="mailto:dpo@hypera.com.br">dpo@hypera.com.br</a>
						</Typography>
					</Block>
				),
			},
			{
				label: 'O que é o Parceiro Hypera?',
				content: (
					<Block>
						<Typography>
							O Parceiro Hypera é uma plataforma online de e-commerce que permite que farmácias cadastradas adquiram
							produtos Hypera a partir dos principais distribuidores do mercado, com condições especiais.
						</Typography>
						<Typography>Para saber mais sobre o funcionamento do Portal, acesse os Termos de Uso. </Typography>
					</Block>
				),
			},
			{
				label: 'Quais dados pessoais coletamos e para quais finalidades utilizamos?',
				content: (
					<Block>
						<Typography>
							Para viabilizar o correto funcionamento do Portal, realizamos o tratamento de dados pessoais dos usuários
							vinculados às farmácias cadastradas. No quadro abaixo apresentamos quais dados pessoais são tratados para
							uso do Portal e as respectivas finalidades:
						</Typography>

						<Typography variant="Paragraph/Semibold">Dados coletados:</Typography>
						<Typography>Dados para cadastro do usuário no Portal:</Typography>
						<ul>
							<li>
								<Typography>Nome;</Typography>
							</li>
							<li>
								<Typography>CPF;</Typography>
							</li>
							<li>
								<Typography>E-mail;</Typography>
							</li>
							<li>
								<Typography>Telefone celular;</Typography>
							</li>
							<li>
								<Typography>CNPJ e Razão Social da(s) farmácia(s) à(s) qual(is) o usuário está vinculado.</Typography>
							</li>
						</ul>

						<Typography>Finalidades do tratamento</Typography>
						<ul>
							<li>
								<Typography>
									Permitir que o usuário realize a compra de produtos do portfólio da Hypera para as farmácias às quais
									está vinculado;
								</Typography>
							</li>
							<li>
								<Typography>Validar a identidade do responsável pela farmácia cadastrada;</Typography>
							</li>
							<li>
								<Typography>
									Identificar e autenticar os usuários adequadamente, viabilizando o acesso aos recursos e
									funcionalidades do Portal;
								</Typography>
							</li>
							<li>
								<Typography>Atender solicitações, chamados e dúvidas;</Typography>
							</li>
							<li>
								<Typography>
									Permitir a participação em programas de fidelidade da Hypera para acúmulo de pontos e resgate de
									benefícios;
								</Typography>
							</li>
							<li>
								<Typography>
									Enviar comunicações via WhatsApp sobre compras realizadas, promoções, novidades e outras iniciativas
									relevantes da Hypera;
								</Typography>
							</li>
							<li>
								<Typography>Prevenir a ocorrência de fraudes e/ou demais atitudes ilícitas;</Typography>
							</li>
							<li>
								<Typography>
									Cumprir com obrigações legais ou regulatórias e exercer direitos em demandas judiciais,
									administrativas ou arbitrais.
								</Typography>
							</li>
						</ul>

						<Typography>Informações coletadas automaticamente a partir do uso do Portal, tais como:</Typography>
						<ul>
							<li>
								<Typography>Endereço IP; </Typography>
							</li>
							<li>
								<Typography>ID de dispositivos móveis; </Typography>
							</li>
							<li>
								<Typography>Navegadores utilizados;</Typography>
							</li>
							<li>
								<Typography>Data e hora de acesso ao Portal;</Typography>
							</li>
							<li>
								<Typography>Tempo de acesso;</Typography>
							</li>
							<li>
								<Typography>Número de “cliques”.</Typography>
							</li>
						</ul>

						<Typography>Finalidades do tratamento</Typography>
						<ul>
							<li>
								<Typography>Prevenir a ocorrência de fraudes e/ou demais atitudes ilícitas;</Typography>
							</li>
							<li>
								<Typography>
									Permitir a melhoria técnica contínua do Portal, corrigindo erros e adaptando o conteúdo aos
									dispositivos mais utilizados pelo usuário;
								</Typography>
							</li>
							<li>
								<Typography>Cumprir com obrigações legais e regulatórias de guarda de registros de acesso.</Typography>
							</li>
						</ul>

						<Typography>
							Caso a farmácia participe do Programa Boni, também serão tratados dados para cadastro no Programa (nome,
							CPF, e-mail, data de nascimento e telefone) e informações sobre a performance no Programa, tais como metas
							individuais, indicadores de desempenho e pontuações. Para mais informações sobre o Programa Boni, acesse o
							Regulamento disponível em [<a href="https://bonifidelidade.com.br">www.bonifidelidade.com.br</a>].
						</Typography>

						<Typography>Finalidades do tratamento</Typography>
						<ul>
							<li>
								<Typography>
									Identificar e autenticar o participante, viabilizando o acesso aos benefícios do Programa;
								</Typography>
							</li>
							<li>
								<Typography>Permitir o acúmulo de pontos no Programa;</Typography>
							</li>
							<li>
								<Typography>
									Realizar o acompanhamento de metas, desempenho, pontuações e pedidos de resgates;
								</Typography>
							</li>
							<li>
								<Typography>Permitir o resgate de prêmios;</Typography>
							</li>
							<li>
								<Typography>Atender solicitações, chamados e dúvidas;</Typography>
							</li>
							<li>
								<Typography>Prevenir a ocorrência de fraudes e/ou demais atitudes ilícitas;</Typography>
							</li>
							<li>
								<Typography>
									Enviar comunicações diversas, inclusive comunicados sobre o Programa e anúncios e promoções sobre
									produtos e iniciativas relevantes da Hypera.
								</Typography>
							</li>
						</ul>

						<Typography>
							Para além dessas situações, algumas informações também poderão ser utilizadas de forma anonimizada (ou
							seja, de maneira que não leve à sua identificação), com a finalidade de produzir relatórios estatísticos
							sobre o funcionamento do Portal e sobre o desempenho dos produtos do portfólio da Hypera.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Com quem compartilhamos os seus dados pessoais?',
				content: (
					<Block>
						<Typography>
							Para atender as finalidades acima, podemos compartilhar os dados pessoais coletados no Portal com os
							seguintes destinatários, observando as justificativas legais e os limites aplicáveis:
						</Typography>

						<ul>
							<li>Distribuidores responsáveis pelo faturamento e entrega dos pedidos realizados;</li>
							<li>Fornecedores e prestadores de serviços que auxiliam na gestão e funcionamento do Portal;</li>
							<li>
								Prestador de serviço responsável pelo processo de validação da identidade dos usuários cadastrados;
							</li>
							<li>
								No caso de participação no Programa Boni, com parceiros responsáveis pela gestão de pontos e resgate de
								prêmios;
							</li>
							<li>
								Demais empresas do grupo econômico da Hypera, respeitados os propósitos apresentados nesse Aviso; e
							</li>
							<li>
								Autoridades competentes para cumprimento de obrigações legais, regulatórias ou para atendimento de
								requisições oficiais.
							</li>
						</ul>

						<Typography variant="Paragraph/Regular">
							<strong>Importante:</strong> Para realizar compras por meio do Portal, é necessário que a farmácia também
							tenha cadastro junto aos distribuidores, pois eles são os responsáveis pelo faturamento e entrega dos
							produtos. A Hypera não se responsabiliza pelas atividades de tratamento de dados pessoais conduzidas
							diretamente pelos distribuidores.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Como ocorre o tratamento de dados em plataformas de terceiros?',
				content: (
					<Block>
						<Typography>
							Este Aviso de Privacidade não se aplica a plataformas e aplicações de terceiros que possam ser oferecidos
							ou acessados por meio do Portal Parceiro Hypera, os quais serão regidos por seus respectivos avisos de
							privacidade. Nós não possuímos qualquer responsabilidade ou ingerência sobre a forma como esses terceiros
							realizam o tratamento de seus dados pessoais.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Como utilizamos cookies?',
				content: (
					<Block>
						<div>
							<Typography variant="Paragraph/Semibold">Você sabe o que são Cookies?</Typography>
							<Typography>
								Cookies são arquivos que permitem a coleta automática de informações quando você visita o Portal
								Parceiro Hypera. Através de cookies, obtemos dados sobre a sua experiência em nosso Portal.{' '}
							</Typography>
						</div>

						<div>
							<Typography variant="Paragraph/Semibold">Por que utilizamos cookies?</Typography>
							<Typography>
								As informações coletadas por meio de cookies possibilitam conhecermos melhor os nossos usuários e,
								consequentemente, orientar nossas estratégias de negócio, permitindo, por exemplo, melhorar a
								experiência em nosso Portal e garantir a segurança de nossos usuários.{' '}
							</Typography>
						</div>
						<div>
							<Typography variant="Paragraph/Semibold">
								Quais tipos de cookies são utilizados em nosso Portal?
							</Typography>
							<Typography>
								Apesar de existirem diversos tipos de cookies, no Portal Parceiro Hypera utilizamos apenas aqueles
								considerados como essenciais para funcionamento do Portal, conforme a seguir:{' '}
							</Typography>
						</div>

						<ul>
							<li>
								<Typography>
									<strong>Cookies analíticos:</strong> permitem entender como os usuários interagem com o Portal; e
								</Typography>
							</li>
							<br />
							<li>
								<Typography>
									<strong>Cookies de segurança:</strong> bloqueiam o uso de aplicações e/ou ferramentas automatizadas
									utilizadas para o cometimento de fraudes no Portal.
								</Typography>
							</li>
						</ul>

						<Typography variant="Paragraph/Semibold">
							Por quanto tempo os cookies são armazenados? Não serão coletados
						</Typography>

						<Typography>
							Lembramos que as informações acessadas através dos cookies mantidos em nosso Portal não são compartilhadas
							com terceiros.
						</Typography>

						<div>
							<Typography variant="Paragraph/Semibold">Posso bloquear/rejeitar o uso de cookies?</Typography>

							<Typography>
								Sim, é possível ajustar as preferências para a coleta de cookies a partir das configurações do navegador
								de internet utilizado para acessar o Portal. No entanto, caso realize o bloqueio de cookies, algumas
								funcionalidades do Portal podem ser prejudicadas. Para gerenciar o uso de cookies, você poderá acessar
								as configurações do seu navegador. Os links a seguir trazem maiores informações sobre esse tema:
							</Typography>
						</div>

						<ul>
							<li>
								<Typography>
									<a href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox">Firefox</a>
								</Typography>
							</li>
							<li>
								<Typography>
									<a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pt-BR">
										Chrome
									</a>
								</Typography>
							</li>
							<li>
								<Typography>
									<a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">Safari</a>
								</Typography>
							</li>
							<li>
								<Typography>
									<a href="https://support.microsoft.com/pt-br/topic/como-excluir-arquivos-de-cookie-no-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc">
										Internet Explorer
									</a>
								</Typography>
							</li>
						</ul>

						<Typography variant="Paragraph/Semibold">
							Como posso esclarecer eventuais dúvidas sobre o uso de cookies?
						</Typography>

						<Typography>
							Em caso de dúvidas complementares sobre o uso de cookies, você pode entrar em contato conosco através do
							canal disponível nesse Aviso: <a href="mailto:dpo@hypera.com">dpo@hypera.com</a>.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Quais as medidas de segurança que adotamos e por quanto tempo os dados pessoais são armazenados?',
				content: (
					<Block>
						<Typography>
							Os dados pessoais tratados durante o uso do Portal serão mantidos de forma segura, confidencial e de
							acordo com as regras de segurança da informação da Hypera, garantido o acesso apenas às pessoas que de
							fato necessitem manipulá-los.
						</Typography>

						<Typography>
							Os dados pessoais utilizados no Portal Parceiro Hypera serão mantidos pelo período necessário para
							execução das finalidades descritas nesse Aviso, observados, inclusive, os prazos legais e regulatórios de
							guarda das informações e a eventual necessidade de utilização em processos judiciais, administrativos ou
							arbitrais.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Quais os direitos assegurados pela Lei Geral de Proteção de Dados?',
				content: (
					<Block>
						<Typography>
							A Lei Geral de Proteção de Dados prevê direitos relacionados ao uso de dados pessoais. São eles:
						</Typography>

						<ul>
							<li>
								<Typography>
									<strong>Confirmação e acesso aos dados:</strong> O usuário pode solicitar informações sobre os dados
									tratados no âmbito do Portal e sobre os terceiros que acessam tais dados, bem como solicitar à Hypera
									o acesso aos seus dados pessoais utilizados.
								</Typography>
							</li>

							<li>
								<Typography>
									<strong>Correção ou atualização:</strong> O usuário pode solicitar a correção de informações que
									estejam desatualizadas, incorretas ou incompletas.
								</Typography>
							</li>
							<li>
								<Typography>
									<strong>Eliminação, anonimização ou bloqueio:</strong> O usuário pode solicitar a exclusão,
									anonimização ou bloqueio de dados pessoais que não sejam mais necessários para o funcionamento do
									Portal.
								</Typography>
							</li>
							<li>
								<Typography>
									<strong>Revogação do consentimento:</strong> Nos casos em que o tratamento ocorrer mediante a obtenção
									do consentimento (autorização), o usuário pode solicitar revogação dessa autorização e/ou a eliminação
									de dados pessoais tratados com base nessa autorização.
								</Typography>
							</li>
							<li>
								<Typography>
									<strong>Portabilidade:</strong> Uma vez regulamentado pela Autoridade Nacional de Proteção de Dados, o
									usuário poderá solicitar a portabilidade de seus dados pessoais.
								</Typography>
							</li>
							<li>
								<Typography>
									<strong>Oposição:</strong> O usuário pode se opor à utilização de seus dados pessoais, desde que
									verificado inconformidade com a LGPD e observados os limites e imposições legalmente previstos.
								</Typography>
							</li>
							<li>
								<Typography>
									<strong>Informações sobre o compartilhamento:</strong> Sem prejuízo das informações fornecidas neste
									Aviso, o usuário pode solicitar esclarecimentos adicionais sobre o compartilhamento de seus dados
									pessoais.
								</Typography>
							</li>
							<li>
								<Typography>
									<strong>Revisão de decisões automatizadas:</strong> O usuário pode solicitar a revisão de decisões
									autônomas (sem participação humana) e/ou o fornecimento dos critérios e procedimentos adotados para a
									tomada dessas decisões.
								</Typography>
							</li>
							<li>
								<Typography>
									<strong>Solicitação junto à autoridade competente:</strong> Caso não seja possível exercer os direitos
									apresentados nesse tópico junto à Hypera, o usuário poderá encaminhar solicitação à Autoridade
									Nacional de Proteção de Dados (ANPD).
								</Typography>
							</li>
						</ul>

						<Typography>
							O exercício dos direitos relacionados no quadro acima depende de prévia solicitação do usuário e da
							inexistência de qualquer restrição técnica e/ou jurídica.
						</Typography>
						<Typography>
							Caso queira solicitar o exercício dos direitos relacionados ao uso de seus dados pessoais, entre em
							contato com nosso Encarregado por meio do canal: <a href="mailto:dpo@hypera.com.br">dpo@hypera.com.br</a>.
						</Typography>
					</Block>
				),
			},
			{
				label: 'Atualizações',
				content: (
					<Block>
						<Typography>
							Este Aviso poderá sofrer atualizações e ajustes a qualquer tempo. Caso haja atualização relevante,
							informaremos no Portal.
						</Typography>
						<Typography variant="Paragraph/Regular">
							<strong>Data da última atualização:</strong> 01/11/2023
						</Typography>
					</Block>
				),
			},
		],
	},
];
