import { notify } from 'front-commons/ds';
import { initialTitles, registeredDrawers } from './helpers';
import { DrawerReducer } from './interfaces';

const initialState: DrawerReducer = {
	drawers: [],
	dynamicSubtitle: [],
	dynamicTitle: initialTitles,
};

export default function drawerReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_CLOSE_DRAWER': {
			notify.remove();

			const lastIndex = state.drawers.findLastIndex((drawer) => drawer.id === payload.drawer.id);

			const drawers = state.drawers.filter((_, index) => index !== lastIndex)

			return {
				...state,
				drawers,
			};
		}

		case 'SET_OPEN_DRAWER': {
			notify.remove();

			const drawers = [...state.drawers];

			// @ts-ignore
			const drawer = registeredDrawers[payload.drawer.id as any];

			drawers.push({ ...drawer, id: payload.drawer.id, open: true, ...payload.drawer.props })

			return {
				...state,
				drawers,
			};
		}

		case 'SET_CLOSE_ALL_DRAWERS': {
			notify.remove();

			return initialState;
		}

		case 'SET_PREPARE_CLOSE_DRAWER': {
			notify.remove();

			const lastIndex = state.drawers.findLastIndex((drawer) => drawer.id === payload.drawer.id);

			return {
				...state,
				drawers: state.drawers.map((drawer, index) => index === lastIndex ? ({ ...drawer, className: 'closing' }) : drawer)
			};
		}

		case 'SET_DYNAMIC_TITLE': {
			return {
				...state,
				dynamicTitle: state.dynamicTitle.some(({ drawerId }) => drawerId === payload.drawer.id)
					? state.dynamicTitle.map((dynamicTitle) => {
						if (dynamicTitle.drawerId !== payload.drawer.id) return dynamicTitle;
						return { ...dynamicTitle, title: payload.drawer.title };
					})
					: [
						...state.dynamicTitle,
						{
							drawerId: payload.drawer.id,
							title: payload.drawer.text,
						},
					],
			};
		}

		case 'SET_DYNAMIC_SUBTITLE': {
			return {
				...state,
				dynamicSubtitle: state.dynamicSubtitle.some(({ drawerId }) => drawerId === payload.drawer.id)
					? state.dynamicSubtitle.map((dynamicSubtitle) => {
						if (dynamicSubtitle.drawerId !== payload.drawer.id) return dynamicSubtitle;

						return { ...dynamicSubtitle, text: payload.drawer.text };
					})
					: [
						...state.dynamicSubtitle,
						{
							drawerId: payload.drawer.id,
							text: payload.drawer.text,
						},
					],
			};
		}

		case 'SET_UPDATE_DRAWER_PROP': {
			return {
				...state,
				drawers: state.drawers.map((drawer) => {
					if (payload.drawer.id !== drawer.id) return drawer;

					return {
						...drawer,
						...payload.drawer.props,
					};
				}),
			};
		}

		default:
			return state;
	}
}
