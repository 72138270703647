import * as Sentry from '@sentry/react';
import { getUserFromLocalStorage, sendCustomIntegrations } from './helpers';
import { generateAssetsHash } from 'shared/hashes';

export default function sentryInit() {
  if (!import.meta.env.VITE_STAGING_ENV) return;

  if (!window.sessionStorage.getItem('@PHC:userTabSession')) {
    window.sessionStorage.setItem('@PHC:userTabSession', generateAssetsHash())
  }

  const { id, name, email } = getUserFromLocalStorage()?.data || {}

  Sentry.setUser(null);

  if (id) {
    Sentry.setUser({ email, id, username: name });
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN || '',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['debug', 'log', 'error'] }),
      Sentry.httpClientIntegration(),
      Sentry.httpContextIntegration(),
      Sentry.moduleMetadataIntegration(),
      sendCustomIntegrations()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    sendDefaultPii: true,
  });
}