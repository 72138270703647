import { createAPIConnection } from 'services';
import { getStore } from 'stores/helpers';
import {
	GetSearchPreviewRequest,
	GetSearchPreviewResponse,
	GetReleaseProductsParams,
	GetSearchedProductsParams,
	GetSearchedProductsResponse,
	GetCategoryProductsParams,
	GetPromotionInfoResponse,
	GetPromotionProductsParams,
	GetPromotionProductsResponse,
	GetCategoryProductsResponse,
	GetPromotionReleasesResponse,
	ProductsPaginationResponse,
	PromotionParams,
	GetBUProductsParams,
	GetPromotionBUResponse,
	GetSuggestionProductsParams,
	SuggestionProductsResponse,
	AddSuggestionProductParams,
	ProductsDetailsResponse,
	ProductsDetailsRequest,
	GetMenuFlagsResponse,
	AddSuggestionProductsResponse,
	GetSearchedProductsDrawerPromotionResponse,
} from './interfaces';
import { requestHandler } from '../helpers';

const api = createAPIConnection('/products', {
	onRequest(config) {
		const provider = getStore().customerReducer?.provider;

		if (provider === 'ZECA_COPILOTO') Object.assign(config?.headers || {}, { 'Partner-origin': 'zecacopiloto' });
	},
});

export const getSearchPreview = async (data: GetSearchPreviewRequest) => {
	return requestHandler<GetSearchPreviewResponse>(api.post('/preview', data));
};

export const getReleaseProducts = async (data: GetReleaseProductsParams) => {
	return requestHandler<ProductsPaginationResponse>(api.post('/release', data));
};

export const getReleaseProductsPage = async (data: GetReleaseProductsParams) => {
	return requestHandler<GetPromotionReleasesResponse>(api.post('/release', data));
};

export const getBUProductsPage = async (data: GetBUProductsParams) => {
	return requestHandler<GetPromotionBUResponse>(api.post('/business-unity/search', data));
};

export const getSearchedProducts = async (posId: string, data: GetSearchedProductsParams) => {
	const dataCopy = { ...data };
	if (!dataCopy?.facets) delete dataCopy.facets;

	return requestHandler<GetSearchedProductsResponse>(api.post(`/search/${posId}`, dataCopy));
};

export const getPromotionInfo = (posId: string, promotionId: string) =>
	requestHandler<GetPromotionInfoResponse>(api.get(`/pos/${posId}/promotion/${promotionId}`));

export const getCategorieProducts = async (data: GetCategoryProductsParams) => {
	const dataCopy = { ...data };
	if (!dataCopy?.facets) delete dataCopy.facets;

	return requestHandler<GetCategoryProductsResponse>(api.post('/category/search', dataCopy));
};

export const getPromotionProducts = async (data: GetPromotionProductsParams) => {
	const dataCopy = { ...data };
	if (!dataCopy?.facets) delete dataCopy.facets;

	return requestHandler<GetPromotionProductsResponse>(api.post('/promotions', dataCopy));
};

export const getPromotionsByProduct = async (posId: string, productId: string) => {
	return requestHandler<{ promotions: PromotionParams[] }>(api.get(`/pos/${posId}/product/${productId}/promotions`));
};

export const getSuggestionProduct = async (data: GetSuggestionProductsParams) => {
	return requestHandler<SuggestionProductsResponse>(api.post(`/suggestions`, data));
};

export const addSuggestionProduct = async (data: AddSuggestionProductParams) => {
	return requestHandler<AddSuggestionProductsResponse>(api.post(`/suggestions/add-products`, data));
};

export const getMenuFlags = async (posId: string) => {
	return requestHandler<GetMenuFlagsResponse>(api.get(`/pos/${posId}/validate`));
};

export const getSearchedProductsDrawerPromotion = async (posId: string, promotionId: string, text: string) => {
	return requestHandler<GetSearchedProductsDrawerPromotionResponse>(
		api.post(`/pos/${posId}/promotion/${promotionId}/search`, { text }),
	);
};

export const getProductsDetails = async (posId: string, products: ProductsDetailsRequest) => {
	return requestHandler<ProductsDetailsResponse>(api.post(`/pos/${posId}`, products));
};
