import { FidelityDataParams, FidelityReducer } from './interfaces';

const initialState: FidelityReducer = {
	data: {} as FidelityDataParams,
	tokenFidelity: '',
	isOwner: false,
	status: undefined,
	loading: false,
	loadingToken: false,
	retryFetchData: undefined,
};

export default function fidelityReducer(state = initialState, { type, payload }: any) {
	switch (type) {
		case 'SET_LOADING_FIDELITY': {
			return { ...state, loading: payload.state };
		}
		case 'SET_LOADING_FIDELITY_TOKEN': {
			return { ...state, loadingToken: payload.state };
		}
		case 'SET_IS_OWNER': {
			return { ...state, isOwner: payload.state };
		}
		case 'SET_FIDELITY_TOKEN': {
			return { ...state, tokenFidelity: payload.tokenFidelity };
		}
		case 'SET_FIDELITY_DATA': {
			return { ...state, data: payload.data };
		}
		case 'SET_STATUS': {
			return { ...state, status: payload.status };
		}
		case 'RETRY_FETCH_DATA': {
			return { ...state, retryFetchData: payload.date };
		}
		case 'CLEAR_FIDELITY': {
			return initialState;
		}
		default:
			return state;
	}
}
