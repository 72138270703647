import { DraggableLocation } from 'react-beautiful-dnd';

export const handleDropAction = (source: DraggableLocation, destination: DraggableLocation | null | undefined) => {
	if (destination?.droppableId !== source.droppableId) {
		if (destination?.droppableId === 'AVAILABLE_COL') return 'REMOVE_CARD';
		return 'ADD_CARD';
	}

	if (destination.index !== source.index && destination?.droppableId === 'SELECTED_COL') return 'CHANGE_ORDER_CARD';

	return null;
};
