import { Flex, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const Container = styled(Flex)``;

export const BUContainer = styled(Flex)`
	position: sticky;
	top: 0;

	border-bottom: 1px solid var(--border-primary);
`;

export const Content = styled(Flex)`
	overflow-y: auto;
`;

export const CTAContainer = styled(Flex)`
	position: sticky;
	bottom: 0;

	box-shadow: 2px 2px 8px #33333340;

	justify-content: flex-end;

	width: 100%;
	background-color: var(--surface-white);
`;

export const SummaryContainer = styled(Flex)`
	padding: ${getThemeSpace('16')};
	margin-top: ${getThemeSpace('8')};

	border: 1px solid var(--border-primary);
	border-radius: ${getThemeSpace('4')};
`;
