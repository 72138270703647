import { useSelector, useDispatch } from 'react-redux';
import { Redux } from 'stores/interfaces';
import * as middleware from './middleware';
import { PosReducer } from 'stores/pos/interfaces';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import usePos from 'stores/pos';

export default function useCatalog() {
	const dispatch = useDispatch();
	const catalogStore = useSelector((stores: Redux) => stores.catalogReducer);
	const location = useLocation();
	const { posStore } = usePos();

	const isSuggestionsProductNotifierExpired = useMemo(() => {
		const atualPosNotification = catalogStore.suggestionsProduct?.find(
			({ posId }) => posId === posStore.selectedPos.id,
		);

		if (!atualPosNotification?.notifierExpiration) return true;

		const msToExpire = dayjs(atualPosNotification.notifierExpiration).diff(dayjs().format(), 'ms');

		return msToExpire <= 0;
	}, [posStore.selectedPos.id, location.pathname, catalogStore.suggestionsProduct]);

	return {
		catalogStore,
		isSuggestionsProductNotifierExpired,
		setSuggestionsProductNotifierExpiration: () => dispatch(middleware.handleSetSuggestionsProductNotifierExpiration()),
		clearSuggestionsProductNotifierExpiration: () =>
			dispatch(middleware.handleClearSuggestionsProductNotifierExpiration()),
		handleSetCatalogMenuItems: (pos: PosReducer['selectedPos']) => dispatch(middleware.handleSetCatalogMenuItems(pos)),
	};
}
