import { currencyRound } from 'shared/currency';
import { RangeMessageParams, Values } from './interfaces';

export const getCurrentRangeIndex = (data: Values[], currentQuantity: number) =>
	data.findIndex(({ from, to: until }) => currentQuantity >= from && currentQuantity <= until!)!;

export const rangeMessage = ({
	currentRangeReference: currentQuantity,
	data,
	hasRangeProduct,
	progressiveDiscountProductMix,
}: RangeMessageParams) => {
	const isCompleted = data.values.at(-1)!.from <= currentQuantity;
	const progressiveDiscountRangeProduct = hasRangeProduct && progressiveDiscountProductMix;

	const renderIncompleteMessage = (currency: boolean, value: number) => {
		if (currency) {
			return Number(value).toCurrency();
		}

		if (value === 1) {
			return `${value} produto`;
		}

		return `${value} produtos`;
	};

	if (isCompleted && currentQuantity)
		return `Você atingiu o desconto máximo de ${currencyRound(data.values.at(-1)?.discount!).toLocaleString('pt-br')}%`;

	const isCurrency = data.type === 'currency';
	const currentRangeIndex = getCurrentRangeIndex(data.values, currentQuantity);
	const { from, discount } = data.values[!currentQuantity ? 0 : currentRangeIndex + 1] || {};
	const target = from === 0 ? 1 : from;

	return `
			${
				progressiveDiscountRangeProduct
					? `Complete o seu mix com ${renderIncompleteMessage(isCurrency, target)} do combo e ganhe`
					: `A partir de ${isCurrency ? Number(target).toCurrency() : `${target} produtos`} você ganha`
			}
			${` ${currencyRound(discount).toLocaleString('pt-br')}% de desconto`}`;
};
