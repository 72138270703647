import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Flex, Typography } from 'front-commons/ds';
import { useState } from 'react';
import { Portal, Content, Trigger, WrapperPrice } from './styles';
import TotalComboTrigger from './TotalComboTrigger';

export default function TotalComboPrice({
	discount,
	tax,
	liquid,
	brute,
}: {
	discount: number;
	tax: number;
	liquid: number;
	brute: number;
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<DropdownMenu.Root onOpenChange={() => setExpanded((prevState) => !prevState)}>
			<Trigger>
				<TotalComboTrigger totalPrice={liquid} expanded={expanded} />
			</Trigger>

			<Portal>
				<Content side="top" align="center" sideOffset={0}>
					<WrapperPrice direction="column" gap="16px">
						<Flex justifyContent="space-between">
							<Typography style={{ fontSize: '18px' }}>
								Subtotal (Preço <strong>De</strong>)
							</Typography>
							<Typography style={{ fontSize: '18px' }} variant="Paragraph/Semibold" as="span">
								{Number(brute).toCurrency({ removeSpace: true })}
							</Typography>
						</Flex>
						<Flex justifyContent="space-between">
							<Typography style={{ fontSize: '18px' }}>Descontos</Typography>
							<Typography style={{ fontSize: '18px' }} variant="Paragraph/Semibold" as="span">
								{Number(discount).toCurrency({ operator: '-', removeSpace: true })}
							</Typography>
						</Flex>
						<Flex justifyContent="space-between">
							<Typography style={{ fontSize: '18px' }}>Impostos</Typography>
							<Typography style={{ fontSize: '18px' }} variant="Paragraph/Semibold" as="span">
								{Number(tax).toCurrency({ operator: '+', removeSpace: true })}
							</Typography>
						</Flex>
					</WrapperPrice>
				</Content>
			</Portal>
		</DropdownMenu.Root>
	);
}
