import { FeaturesReducer } from './interfaces';

export const setFeatures = (key: keyof FeaturesReducer, value: unknown) => ({
	type: 'SET_FEATURES',
	payload: { key, value },
});

export const setResetFeatures = () => ({
	type: 'SET_RESET_FEATURES',
});
