import { Flex, Grid, Typography } from 'front-commons/ds';
import React from 'react';

interface DistributorsConflictsProps {
	buName: string;
	conflicts?: Record<string, string>;
}

export default function DistributorsConflicts({ buName, conflicts = {} }: DistributorsConflictsProps) {
	const handleWithValue = (value: string) => {
		if (value === 'PED') return 'Faz parte do painel exclusivo';
		return `Sem atendimento de ${buName}`;
	};

	return (
		<Flex border="1px solid" borderColor="--border-primary" borderWidth="1px 1px 0 1px">
			<Grid columns="280px 280px" gap="0px">
				{Object.entries(conflicts).map(([label, value], index) => (
					<React.Fragment key={index}>
						<Flex padding="10px" border="1px solid" borderColor="--border-primary" borderWidth="0 0 1px 0">
							<Typography textEllipsis={1} title={label}>
								{label}
							</Typography>
						</Flex>
						<Flex padding="10px" border="1px solid" borderColor="--border-primary" borderWidth="0 0 1px 1px">
							<Typography>{handleWithValue(value)}</Typography>
						</Flex>
					</React.Fragment>
				))}
			</Grid>
		</Flex>
	);
}
