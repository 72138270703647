import { CustomerDataResponse } from 'services/customer/interfaces';
import { CustomerProvider } from './interfaces';

export const setCustomer = (customer: CustomerDataResponse) => ({
	type: 'SET_CUSTOMER',
	payload: { customer },
});

export const setUpdatePartially = (customer: Partial<CustomerDataResponse>) => ({
	type: 'SET_PARTIALLY_UPDATE',
	payload: { customer },
});

export const resetUserIsLoggingOut = () => ({
	type: 'RESET_USER_IS_LOGGING_OUT',
});

export const setLogout = (userIsLoggingOut?: boolean) => ({
	type: 'SET_LOGOUT',
	payload: {
		userIsLoggingOut,
	},
});

export const setToken = (token: string | null) => ({
	type: 'SET_TOKEN',
	payload: { token },
});

export const setProvider = (provider: CustomerProvider) => ({
	type: 'SET_PROVIDER',
	payload: { provider },
});

export const setLoading = (loading: boolean) => ({
	type: 'SET_LOADING',
	payload: { loading },
});

export const set2FAExpiration = (timestamp: number) => ({
	type: 'SET_2FA_EXPIRATION',
	payload: { timestamp },
});

export const manageFirstAccess = (action: 'ADD' | 'REMOVE' | 'CLEAR', item?: string) => ({
	type: 'MANAGE_FIRST_ACCESS',
	payload: { action, item },
});
