import { Flex, Alert } from 'front-commons/ds';
import DistributorCard from 'containers/Cards/DistributorCard';
import { DummyCardsProps } from './interfaces';

export default function DummyCards({ type, length = 5, selectedLength = 0, marginTop = '-16px' }: DummyCardsProps) {
	if (type === 'BLURRED') {
		return (
			<Flex position="relative" maxWidth="403px">
				<Flex position="absolute" zIndex={10} margin="48px 24px">
					<Alert type="info" title="Distribuidor exclusivo" toastMode preserveMobileRadius>
						Utilize o seu distribuidor exclusivo para visualizar as ofertas na loja.
					</Alert>
				</Flex>
				<Flex
					position="relative"
					direction="column"
					gap="16px"
					transition="all 300ms ease-in-out"
					margin={`${marginTop} 0 0 0`}
					width="100%"
					filter="blur(4px)"
				>
					<Flex
						width="100%"
						zIndex={1}
						height="100%"
						opacity="0.9"
						position="absolute"
						backgroundImage="linear-gradient(transparent, var(--surface-white))"
					/>
					{Array.from({ length: 3 }).map((_, index) => (
						<DistributorCard
							key={index}
							active={{
								id: 'dummy-ped',
								disabled: true,
								pharmacyId: 'dummy-pharmacy',
								minimumValue: 10,
								dragProvided: {} as any,
								dragSnapshot: {} as any,
								commercialCenter: 'Sit Amet - AT',
								summarizedCorporateName: 'Lorem Ipsum Dolor',
							}}
						/>
					))}
				</Flex>
			</Flex>
		);
	}

	return (
		<>
			{Array.from({ length }).map((_, index) => {
				const position = index + 1;
				return <DistributorCard key={position} dummy={{ position, isVisible: selectedLength < position }} />;
			})}
		</>
	);
}
