import { Button, Flex, Input, Typography } from 'front-commons/ds';
import { useCallback, useState } from 'react';
import { ContentProps } from '../../interfaces';
import PosList from './PosList';
import { useNavigate } from 'react-router-dom';
import usePosSearch from 'shared/pos/usePosSearch';

export default function Content({
	isSimulated,
	storedPosList,
	selectedPosId,
	hasMoreThanFourPos,
	disablePosSelecting,
	handleChangePos,
	mainCustomerPage,
}: ContentProps) {
	const [posList, setPosList] = useState<typeof storedPosList | undefined>(undefined);

	const navigate = useNavigate();
	const hasOnlyOnePos = storedPosList.length === 1 && !isSimulated;

	const handleSetPosList = useCallback((response: any) => {
		if (!response) return setPosList([]);

		const posResponse = isSimulated ? response : response?.content;

		return setPosList(posResponse as typeof posList);
	}, []);

	const { inputProps, search, loading } = usePosSearch({
		onSuccess: handleSetPosList,
		onSearchClean() {
			setPosList(undefined);
		},
		commonUseFetchParams: {
			paginationParams: { size: 4 },
		},
	});

	const handleSeeMore = useCallback(() => {
		let mainPage = mainCustomerPage();
		if (isSimulated && search) mainPage += `?s=${CNPJ(search).unmask}`;

		navigate(mainPage);
	}, [search]);

	return (
		<Flex
			direction="column"
			gap="24px"
			padding={{ small: '16px', medium: '16px 24px' }}
			width={{ small: '100%', medium: '360px' }}
		>
			<Input
				name="posFilter"
				disabled={hasOnlyOnePos}
				placeholder={isSimulated ? 'Busca pelo CNPJ da farmácia' : 'Nome ou CNPJ da farmácia'}
				{...inputProps}
			/>

			<PosList
				loading={loading}
				posList={search && posList?.length ? posList : storedPosList}
				disabled={disablePosSelecting}
				posNotFound={posList?.length === 0}
				hasOnlyOnePos={hasOnlyOnePos}
				selectedPosId={selectedPosId}
				handleChangePos={handleChangePos}
			/>

			{(isSimulated || hasMoreThanFourPos) && (
				<Button variant="none" onClick={handleSeeMore}>
					<Typography variant="ParagraphSmall/Semibold" color="--text-link">
						Ver mais
					</Typography>
				</Button>
			)}
		</Flex>
	);
}
