import { Typography } from 'front-commons/ds';
import Icon from 'front-commons/ds/components/Icon/Rotator';
import { BoxLeftTrigger, TriggerContainer } from './styles';

export default function TotalComboTrigger({ totalPrice, expanded }: { totalPrice: number; expanded: boolean }) {
	return (
		<TriggerContainer style={{ width: '100%', display: 'flex' }}>
			<BoxLeftTrigger expanded={expanded}>
				<Typography style={{ fontSize: '18px' }}>
					Total (Preço <strong>Por</strong>)
				</Typography>
				<Icon rotate={expanded} name="expand_less" size="16px" />
			</BoxLeftTrigger>

			<Typography variant="Paragraph/Semibold" style={{ fontSize: '18px' }}>
				{Number(totalPrice).toCurrency({ removeSpace: true })}
			</Typography>
		</TriggerContainer>
	);
}
