import { Counter, Flex, Typography } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import CurrentDistributor from './CurrentDistributor';
import { SimpleProductProps } from './interfaces';
import { Thumbnail } from './styles';

export default function SimpleProduct({ data }: SimpleProductProps) {
	const { imageURL, description, currentValue, ean13, quantity: currentQuantity, currentDistributorName } = data;

	return (
		<Flex direction="column" gap="8px">
			<Typography variant="Paragraph/Semibold">Produto no seu carrinho</Typography>

			<CurrentDistributor name={currentDistributorName} />

			<Flex direction="column" borderRadius="4px" border={`1px solid ${theme.colors['--border-disable']}`} gap="0px">
				<Flex
					padding="12px 16px"
					direction={{ small: 'column', medium: 'row' }}
					justifyContent="space-between"
					alignItems="center"
					gap={{ small: '24px', medium: '0px' }}
				>
					<Flex gap="16px" width="100%" maxWidth={{ small: undefined, medium: '248px' }}>
						<Thumbnail>
							<img
								src={`${import.meta.env.VITE_STATIC_STORAGE_BASE}${imageURL}`}
								alt={`Imagem do produto ${description}`}
							/>
						</Thumbnail>

						<Flex direction="column" gap="4px" maxWidth={{ small: undefined, medium: '160px' }} height="100%">
							<Typography variant="ParagraphSmall/Regular" color="--text-primary">
								EAN: {ean13}
							</Typography>
							<Typography variant="ParagraphSmall/Regular">{description.capitalize()}</Typography>
						</Flex>
					</Flex>

					<Flex alignItems="center" gap="8px" direction={{ small: 'column', medium: 'row' }}>
						<Counter hasPermission width="140px" disabled quantity={currentQuantity} />

						<Typography>
							<strong>{currentValue.toCurrency()}</strong> + imposto
						</Typography>
					</Flex>
				</Flex>

				<Flex backgroundColor="--surface-primary" justifyContent="flex-end" padding="6px 8px">
					<Typography variant="ParagraphSmall/Regular">
						Subtotal dos produtos avulsos (1 itens): <strong>{currentValue.toCurrency()}</strong>
					</Typography>
				</Flex>
			</Flex>
		</Flex>
	);
}
