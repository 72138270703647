import { HandleChangePosPromotionDistributorParams, HandleRemovePosSinglePromotionParams, HandleSetAddPosPromotionsParams, HandleSetLoadingParams } from "./interfaces";

export const setClearPosPromotions = () => ({
	type: 'CLEAR_POS_PROMOTIONS',
});

export const setAddPosPromotions = (payload: HandleSetAddPosPromotionsParams) => ({
	type: 'ADD_POS_PROMOTIONS',
	payload,
});

export const setChangePosPromotionDistributor = (payload: HandleChangePosPromotionDistributorParams) => ({
	type: 'CHANGE_POS_PROMOTIONS_DISTRIBUTOR',
	payload,
});

export const removePosSinglePromotion = (payload: HandleRemovePosSinglePromotionParams) => ({
	type: 'REMOVE_POS_SINGLE_PROMOTION',
	payload,
});

export const setOrderImportLoading = (payload: HandleSetLoadingParams) => ({
	type: 'SET_ORDER_IMPORT_LOADING',
	payload,
});
