import * as Accordion from '@radix-ui/react-accordion';
import { Flex, Icon, Typography } from 'front-commons/ds';
import ValuesSummary from 'containers/Summaries/ValuesSummary';
import { TotalBasketPriceProps } from './interfaces';
import { Root, Trigger, ValuesContainer } from './styles';

export default function TotalBasketPrice({
	product: { finalTotalPrice, totalPrice, discount, totalTaxSubstitution },
}: TotalBasketPriceProps) {
	return (
		<Flex direction="column" gap="0px" backgroundColor="--surface-white">
			<Root type="multiple">
				<Accordion.Item value="summary">
					<Accordion.Header asChild>
						<Trigger asChild>
							<Flex
								justifyContent="space-between"
								alignItems="center"
								style={{ cursor: 'pointer' }}
								margin="0 0 20px 0"
							>
								<Typography variant="Caption/Regular" style={{ fontWeight: 600 }}>
									Resumo de pedido
								</Typography>
								<Icon name="expand_less" />
							</Flex>
						</Trigger>
					</Accordion.Header>

					<Accordion.Content>
						<ValuesContainer>
							<ValuesSummary
								brute={totalPrice}
								tax={totalTaxSubstitution}
								discount={discount}
								hideValue={{ total: true }}
							/>
						</ValuesContainer>
					</Accordion.Content>
				</Accordion.Item>
			</Root>

			<Flex justifyContent="space-between">
				<Typography>Total</Typography>
				<Typography variant="Paragraph/Semibold">{Number(finalTotalPrice).toCurrency()}</Typography>
			</Flex>
		</Flex>
	);
}
